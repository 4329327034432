import React, { useContext, useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";

import { useQuery, useQueryClient } from "react-query";
import Modal from "react-modal";
import { toast } from "react-toastify";
import QRCode from "qrcode";
import { formatRelativeDate } from "../../helper/helper";
import getToken from "../Token";
import getUsername from "../Account_Infomation/Username";
import { GlobalContext } from "../GlobalContext";
import Cards from "../Cards";
import First from "../../assets/first.svg";
import Second from "../../assets/second.svg";
import Third from "../../assets/third.svg";
function HomeFilterComponent() {
  const accesstoken = getToken();
  const curUser = getUsername();
  const queryClient = useQueryClient();
  var link = `${process.env.REACT_APP_LINK}`;

  const {
    selectedHomeFilterTopicOptions,
    setSelectedHomeFilterTopicOptions,
    selectedConnectionOptions,
    setSelectedConnectionOptions,
    selectedButtonFilters,
    notsocket,
    feedsocket,
  } = useContext(GlobalContext);

  // Link share - qr code Func
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [sharebet, setSharebet] = useState("");
  const [linkModalIsOpen, setLinkModalIsOpen] = useState(false);

  const setLinkModalIsOpenToTrue = () => {
    setLinkModalIsOpen(true);
  };
  const setLinkModalIsOpenToFalse = () => {
    setLinkModalIsOpen(false);
  };

  const handleShareCardClick = (tileref) => {
    QRCode.toDataURL("https://puntville.com/share/" + tileref)
      .then((url) => {
        setQrCodeUrl(url);
      })
      .catch((err) => console.error(err));
  };

  const handleDownloadQrCode = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = qrCodeUrl;
    downloadLink.download = "qr-code.png";
    downloadLink.click();
  };

  const FetchFilteredProductsData = async (pageNo) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LINK}puntville-home-filter-list/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
          },
          body: JSON.stringify({
            user: curUser,
            topics:
              selectedHomeFilterTopicOptions
                ?.map((item) => item.topicname)
                .toString() ||
              selectedButtonFilters?.map((item) => item).toString(),
            connections: selectedConnectionOptions
              ?.map((item) => item.value)
              .toString(),
          }),
        }
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log("Error", error);
    }
  };
  const [puntvilleBetsData2, setPuntvilleBetsData2] = useState("");
  // fetch filtered orders
  const {
    isLoading: isFliteredBetsLoading,
    data: fliteredBetsData,
    isError: isFilteredBetsError,
    error: filteredBetsErr,
    isFetching: isFilteredBetsDataFetching,
    isPreviousData: isFilteredPrevBetsData,
    refetch: refetchFilteredData,
  } = useQuery(["filteredBetsData"], FetchFilteredProductsData);

  feedsocket.onmessage = (event) => {
    if (JSON.parse(event.data)["data"] !== "connected websocket") {
      setPuntvilleBetsData2(
        JSON.parse(event.data)["data"]["0"]["PuntvilleBetRef"]
      );
    }
  };
  useEffect(() => {
    if (puntvilleBetsData2 !== "") {
      refetchFilteredData();
    }
  }, [puntvilleBetsData2]);

  useEffect(() => {
    if (
      selectedConnectionOptions?.length > 0 ||
      selectedHomeFilterTopicOptions?.length > 0 ||
      selectedButtonFilters?.length > 0
    ) {
      refetchFilteredData();
    }
  }, [
    selectedConnectionOptions,
    selectedHomeFilterTopicOptions,
    selectedButtonFilters,
  ]);

  if (isFliteredBetsLoading) {
    return <p> Loading..</p>;
  }

  if (fliteredBetsData?.length === 0) {
    return <p>No data available..</p>;
  }

  return (
    <div>
      <div className=" flex flex-col gap-1 mx-auto mb-5">
        {isFilteredBetsDataFetching && <p>Loading...</p>}

        <div className=" grid  md:grid-cols-2 mx-auto gap-5 ">
          {fliteredBetsData?.length > 0 &&
            fliteredBetsData.map((message) => (
              <div
                key={message.id}
                className="relative my-2 mx-1 p-4 py-8 max-w-md rounded-lg shadow-md border border-red-100 justify-items-center"
              >
                <div className="absolute text-xs top-1 left-2 px-4    ">
                  <p> Expires {formatRelativeDate(message)} </p>
                </div>

                <div className="absolute text-xs top-1 right-2 px-4 flex gap-1 items-center ">
                  {"  "}
                  Connection{" "}
                  {message.connection === "first" ? (
                    <img src={Third} alt="" className="w-3 h-3" />
                  ) : message.connection === "second" ? (
                    <img src={Second} alt="" className="w-3 h-3" />
                  ) : (
                    <img src={First} alt="" className="w-3 h-3" />
                  )}
                </div>
                <Cards message={message} />
                <div
                  className="text-center text-lg
                 m-1"
                >
                  {" "}
                  You think otherwise?
                </div>
                <div className=" grid grid-cols-2 ">
                  <div className="flex-shrink-0">
                    <button
                      className="flex-nowrap flex-shrink  button w-full mr-4 p-2  shadow-sm focus:outline-none cursor-pointer cardBtn text-xs  "
                      data-id={message.id}
                      data-author={message.author}
                      data-topic={message.topic}
                      data-body={message.body}
                      data-created_at={message.created_at}
                      data-active_period={message.active_period}
                      data-tileref={message.PuntvilleBetRef}
                      data-bet={message.bet}
                      onClick={() => {
                        if (global.username === message.author) {
                          toast("You can't take your own bets! ");
                        } else {
                          toast(
                            "Congrats! This bet is locked now. You can find it in the 'Manage' section "
                          );
                          const savedcontent = {
                            id: message.id,
                            user: global.username,
                            topic: message.topic,
                            body: message.body,
                            author: message.author,
                            PuntvilleBetRef: message.PuntvilleBetRef,
                            created_at: message.created_at,
                            bet: message.bet,
                            active_period: message.active_period,
                          };
                          <ToastContainer autoClose={2500} />;
                          fetch(link + "savedpuntville-create/", {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: "Bearer " + accesstoken,
                            },
                            body: JSON.stringify(savedcontent),
                          })
                            .then((response) => response.json())
                            .then(async (json) => {
                              toast(
                                JSON.stringify(
                                  json["Notification-text"]
                                ).replace(/"|'/g, "")
                              );
                              await queryClient.invalidateQueries(
                                "puntvilleBets"
                              );
                              await queryClient.invalidateQueries(
                                "puntvilleUserCredits"
                              );
                              await queryClient.invalidateQueries("openBets");
                              await queryClient.invalidateQueries("lockedBets");
                              notsocket.send(
                                JSON.stringify({
                                  user: curUser,
                                })
                              );

                              feedsocket.send(
                                JSON.stringify({
                                  user: getUsername(),
                                })
                              );
                            });
                        }
                      }}
                    >
                      Take This Bet
                    </button>
                  </div>
                  <div className="ml-4">
                    <button
                      className=" button w-full mr-4 p-2  shadow-sm focus:outline-none cursor-pointer cardBtn text-xs  "
                      onClick={() => {
                        setLinkModalIsOpenToTrue();
                        handleShareCardClick(message.PuntvilleBetRef);
                      }}
                    >
                      Share Bet
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* share link modal */}
      <Modal
        isOpen={linkModalIsOpen}
        className="  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setLinkModalIsOpen(false)}
      >
        <button
          onClick={setLinkModalIsOpenToFalse}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-blue-400 rounded-md text-sm font-semibold flex-shrink"
        >
          x
        </button>
        <div className="bg-white  p-6 rounded-md flex flex-col items-center">
          <div className="relative">
            <img src={qrCodeUrl} alt="QR code" className="w-72 h-72" />
            <button
              title="Download QR"
              className="absolute bottom-5 -right-3 shadow-md bg-blue-300 rounded-b-md rounded-l-md"
              onClick={handleDownloadQrCode}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="h-8 w-8"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
              </svg>
            </button>
          </div>
          <div className="w-full flex justify-center">
            <input
              type="text"
              className="border border-blue-300 rounded-l-md text-sm p-2 md:w-80 text-gray-500"
              value={`https://puntville.com/share/${sharebet}`}
              readOnly
            />
            <button
              className="bg-white px-2 border border-blue-300 rounded-r-md flex active:bg-blue-400 transition duration-200 ease-in items-center space-x-1 text-gray-700 "
              onClick={() => {
                navigator.clipboard.writeText(
                  "https://puntville.com/share/" + sharebet
                );
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
                className="w-4 h-4"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z" />
              </svg>
              <span className="text-xs">Copy</span>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default HomeFilterComponent;
