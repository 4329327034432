import React, { useRef, useState, useContext, useEffect } from "react";
import { GlobalContext } from "./GlobalContext";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import getToken from "./Token";

function DestinationTab({ setIsFilterOpen }) {
  const wrapperRef = useRef(null);

  const navigate = useNavigate();
  const {
    selectedCategoryFilterOptions,
    setSelectedCategoryFilterOptions,
    selectedTopicFilterOptions,
    setSelectedTopicFilterOptions,
  } = useContext(GlobalContext);
  const [options, setOptions] = useState([]);
  const [customOptions, setCustomOptions] = useState([
    { id: 1, value: "Open", data: "Open" },
    { id: 2, value: "Locked", data: "Locked" },
    { id: 3, value: "Archived", data: "Archived" },
    { id: 4, value: "Disputed", data: "Disputed" },
    { id: 5, value: "Created by you", data: "Created by you" },
    { id: 6, value: "Taken by you", data: "Taken by you" },
  ]);

  const [selectedOptions, setSelectedOptions] = useState(
    selectedCategoryFilterOptions
  );
  const [selectedCustomOptions, setSelectedCustomOptions] = useState(
    selectedTopicFilterOptions
  );

  const { setUsermessagefeed } = useContext(GlobalContext);

  const link = `https://omnistack.org${process.env.REACT_APP_LINK}`;
  useEffect(() => {
    //let [options1, setOptions1] = useState([]);//save data
    let accesstoken = getToken();
    // console.log(accesstoken);
    //source label
    fetch(link + "topic-list/", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accesstoken,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setOptions(result.slice(1));
      });
  }, []);

  const handleOptionChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    // console.log("Selectedoption", selectedOptions);
  };

  const handleCustomOptionChange = (selectedcustomOptions) => {
    setSelectedCustomOptions(selectedcustomOptions);
    // console.log("Selectedcustomoption", selectedcustomOptions);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log("combined...", combinedPosts);
    setSelectedCategoryFilterOptions(selectedOptions);
    setSelectedTopicFilterOptions(selectedCustomOptions);

    // console.log("filteredData", filteredData);
    setUsermessagefeed("filter");
    navigate("/my-posts/filter");
  };

  const handleReset = () => {
    setSelectedCategoryFilterOptions([]);
    setSelectedTopicFilterOptions([]);
    setSelectedCustomOptions([]);
    setSelectedOptions([]);
    setUsermessagefeed("Locked Posts");
    navigate("/my-posts/locked");
  };

  return (
    <div ref={wrapperRef}>
      <div className="z-10 absolute right-0 bg-gray-100 rounded-lg">
        <div className="px-4 leading-tight mb-4 border-t border-gray-400 rounded-t-lg pt-2 text-xs justify-between items-center flex-col">
          <h2 className=" text-sm text-center text-black pb-1 w-40">
            Area of Interest
          </h2>

          <form onSubmit={handleSubmit} className="flex flex-col  space-y-3 ">
            <div className="">
              <p className="font-fredoka text-xs ml-1">Option(s)</p>
              <Select
                className="w-56"
                value={selectedCustomOptions}
                onChange={handleCustomOptionChange}
                options={customOptions}
                placeholder={"select"}
                isMulti
                closeMenuOnSelect={false}
                autosize={false}
                getOptionValue={(option) => option.value} //value is what it is
                getOptionLabel={(option) => option.data} //label is what you see
              />
            </div>
            <div className="">
              <p className="font-fredoka text-xs ml-1">Topics(s)</p>
              <Select
                className="w-56"
                value={selectedOptions}
                onChange={handleOptionChange}
                options={options}
                placeholder={"select"}
                isMulti
                closeMenuOnSelect={false}
                autosize={false}
                getOptionValue={(option) => option.topicname} //value is what it is
                getOptionLabel={(option) => option.topicname} //label is what you see
              />
            </div>
            <div className="flex items-center justify-around gap-4">
              <p
                onClick={handleReset}
                className=" h-7 shadow-md text-center focus:outline-none cursor-pointer cardBtn text-lg  w-full "
              >
                Reset
              </p>{" "}
              <button
                type="submit"
                className=" h-7 shadow-md  focus:outline-none cursor-pointer cardBtn text-lg  w-full "
              >
                Filter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default DestinationTab;
