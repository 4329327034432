import React from "react";

import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import MessageBoard from "../Pages/MessageBoard";
import DateTimePicker from "react-datetime-picker";

import { Auth } from "aws-amplify";
import { useEffect } from "react";
import getToken from "../Token";
import getUsername from "../Account_Infomation/Username";
import useFetch from "../useFetch";
import useSubject from "../Subject";

import { stringify } from "postcss";
import IconComponent from "../Utility/IconComponent.js";

const ErrorMessagePopup = (props) => {
  var link = `${process.env.REACT_APP_LINK}`;
  //var link = "http://localhost:8000/api/";

  const alphabet = "abcdefghijklmnopqrstuvwxyz";

  const [posting, setPosting] = useState(false);
  const [justposted, setJustposted] = useState(props.postedmessage);
  const accesstoken = getToken();
  const username = getUsername();
  //const [isPending, setIsPending] = useState(false);

  const handleSumbit = (e) => {
    e.preventDefault(); //prevents refreshing page

    props.onClick();
  };

  return (
    <div className="bg-white">
      <div className=" px-8  mx-2 my-3  justify-items-center md:w-28 ">
        {props.message}
      </div>

      <form id="messagesubject" onSubmit={handleSumbit} className="">
        <div className="grid grid-cols-2  text-center ">
          <div className="col-span-2 flex-1 pl-2 text-center"></div>
        </div>
      </form>
    </div>
  );
};

export default ErrorMessagePopup;
