import { Link, Outlet } from "react-router-dom";
import { useState, useEffect, Confirm, useContext, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import DestinationTab from "../DestinationTab";

import { Tooltip as ReactTooltip } from "react-tooltip";
import { GlobalContext } from "../GlobalContext";
import Endpoints from "../../api/endpoints";
import { Text } from "react-native";
import { useQuery } from "react-query";
import FilterIcon from "../../assets/icons/filter.png";
const UserMessages = (props) => {
  //Global-context
  const { usermessagefeed, setUsermessagefeed } = useContext(GlobalContext);

  // Filter /modal
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleFilterClick = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  const wrapperRef = useRef(null);

  //react-toolkit text(s)
  const [opentext, setOpenText] = useState(
    <Text style={{ fontSize: 12 }}>
      All the bets that you created that are still open {"\n"}for others to
      take.{" "}
    </Text>
  );
  const [lockedtext, setLockedText] = useState(
    <Text style={{ fontSize: 12 }}>
      All the live bets that you are locked into. You {"\n"}can claim win,
      concede defeat or raise dispute {"\n"}here.{" "}
    </Text>
  );
  const [archivedtext, setArchivedText] = useState(
    <Text style={{ fontSize: 12 }}>All the historical bets.</Text>
  );
  var link = `${process.env.REACT_APP_LINK}`;

  const handleOpenclick = () => {
    setOpenText("");
    setUsermessagefeed("Open Posts");
  };

  const handleLockedclick = () => {
    setLockedText("");
    setUsermessagefeed("Locked Posts");
  };

  const handleArchivedclick = () => {
    setArchivedText("");
    setUsermessagefeed("Archived Posts");
  };

  useEffect(() => {
    if (window.location.pathname === "/my-posts/open") {
      setUsermessagefeed("Open Posts");
    } else if (window.location.pathname === "/my-posts/locked") {
      setUsermessagefeed("Locked Posts");
    } else if (window.location.pathname === "/my-posts/archived") {
      setUsermessagefeed("Archived Posts");
    }
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef.current.contains(event.target)) {
      setIsFilterOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className=" p-2">
      <ToastContainer autoClose={2500} />
      {
        <div className="pt-12 ">
          <ReactTooltip
            style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
            anchorId="open"
            place="bottom"
            variant="warning"
            content={opentext}
          />
          <ReactTooltip
            style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
            anchorId="locked"
            place="bottom"
            variant="warning"
            content={lockedtext}
          />
          <ReactTooltip
            style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
            anchorId="archived"
            place="bottom"
            variant="warning"
            content={archivedtext}
          />
          <div className="flex flex-col static">
            <div className="flex items-center justify-between md:justify-center md:space-x-20 xl:space-x-32 px-5  md:px-40 mt-12 md:mt-20 focus:outline-none ">
              <div id="open" className=" text-center ">
                <Link to="/my-posts/open">
                  <button
                    className={
                      usermessagefeed == "Open Posts"
                        ? "cardBtn px-4 py-1 shadow-sm text-xs text-[#000F99] "
                        : "border-[0.5px] border-[#000F99]  px-4 py-1 shadow-sm rounded-full text-xs text-[#000F99] "
                    }
                    onClick={handleOpenclick}
                  >
                    Open
                  </button>
                </Link>
              </div>

              <div id="locked" className=" text-center">
                <Link to="/my-posts/locked">
                  <button
                    className={
                      usermessagefeed == "Locked Posts"
                        ? "cardBtn px-4 py-1 shadow-sm text-xs text-[#000F99] "
                        : "border-[0.5px] border-[#000F99]  px-4 py-1 shadow-sm rounded-full text-xs text-[#000F99] "
                    }
                    onClick={() => {
                      handleLockedclick();
                    }}
                  >
                    Locked
                  </button>
                </Link>
              </div>
              <div id="archived" className=" text-center">
                <Link to="/my-posts/archived">
                  <button
                    className={
                      usermessagefeed === "Archived Posts"
                        ? "cardBtn px-4 py-1 shadow-sm text-xs text-[#000F99] "
                        : "border-[0.5px] border-[#000F99]  px-4 py-1 shadow-sm rounded-full text-xs text-[#000F99] "
                    }
                    onClick={handleArchivedclick}
                  >
                    Archived
                  </button>
                </Link>
              </div>
              <div ref={wrapperRef} className=" relative mr-5">
                <img
                  src={FilterIcon}
                  className={`${
                    isFilterOpen || usermessagefeed === "filter"
                      ? "bg-[#34dd5a]"
                      : ""
                  } h-8 w-8 z-50 rounded-full p-1 border border-red-100 shadow-md cursor-pointer
`}
                  alt=""
                  onClick={() => setIsFilterOpen(!isFilterOpen)}
                />
                {isFilterOpen && (
                  <DestinationTab setIsFilterOpen={setIsFilterOpen} />
                )}
              </div>
            </div>
          </div>
        </div>
      }
      <Outlet />
    </div>
  );
};

export default UserMessages;
