import getToken from "../components/Token";
import getUsername from "../components/Account_Infomation/Username";

function checkConnection(value) {
  // console.log("running connection");
  if (value === "first degree") {
    return "1st";
  } else if (value === "second degree") {
    return "2nd";
  } else if (value === "third degree") {
    return "3rd+";
  }
}

// Home page Data
const GetPuntvilleBets = async () => {
  const accesskeytoken = getToken();
  const curUser = getUsername();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_LINK}puntville-list/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesskeytoken,
        },
        body: JSON.stringify({
          user: curUser,
          PuntvilleBetRef: "1",
        }),
      }
    );

    const json = await response.json();
    if (json) {
      const modifieddata = json?.map((item) => ({
        ...item,
        connection: checkConnection(item.connection),
      }));
      return modifieddata.reverse();
    }
  } catch (error) {
    if (error instanceof SyntaxError) {
      // Unexpected token < in JSON
      console.log("There was a SyntaxError", error);
    } else {
      console.log("Oops Something went wrong!", error);
    }
  }
};

// OpenBets
const GetOpenBets = async () => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  try {
    const response = await fetch(
      `${process.env.REACT_APP_LINK}puntville-list/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesskeytoken,
        },
        body: JSON.stringify({
          user: curUser,
          PuntvilleBetRef: "0",
        }),
      }
    );

    const json = await response.json();
    if (json) {
      const modifieddata = json
        .filter((item) => item.author === curUser)
        .map((item) => ({
          ...item,
          postType: "open",
        }));
      return modifieddata.reverse();
    }
  } catch (error) {
    if (error instanceof SyntaxError) {
      // Unexpected token < in JSON
      console.log("There was a SyntaxError", error);
    } else {
      console.log("Oops Something went wrong!", error);
    }
  }
};

// LockedBets
const GetLockedBets = async () => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  try {
    const response = await fetch(
      `${process.env.REACT_APP_LINK}savedpuntville-list/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesskeytoken,
        },
        body: JSON.stringify({ user: curUser }),
      }
    );

    const json = await response.json();
    if (json) {
      const modifieddata = json.map((item) => ({
        ...item,
        postType: "locked",
      }));
      return modifieddata.reverse();
    }
  } catch (error) {
    if (error instanceof SyntaxError) {
      // Unexpected token < in JSON
      console.log("There was a SyntaxError", error);
    } else {
      console.log("Oops Something went wrong!", error);
    }
  }
};

// ArchivedBets
const GetArchivedBets = async (pageNo = 1) => {
  const accesskeytoken = getToken();
  const curUser = getUsername();
  try {
    const response = await fetch(
      `${process.env.REACT_APP_LINK}puntville-archive-list/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesskeytoken,
        },
        body: JSON.stringify({ user: curUser, page: pageNo + 1 }),
      }
    );

    const json = await response.json();
    if (json) {
      const modifieddata = json?.map((item) => ({
        ...item,
        postType: "archive",
      }));
      return modifieddata;
    }
  } catch (error) {
    if (error instanceof SyntaxError) {
      // Unexpected token < in JSON
      console.log("There was a SyntaxError", error);
    } else {
      console.log("Oops Something went wrong!", error);
    }
  }
};

//Create bet
const createBet = async (content) => {
  const accesskeytoken = getToken();
  try {
    const response = await fetch(
      `${process.env.REACT_APP_LINK}puntville-create/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesskeytoken,
        },
        body: JSON.stringify(content),
      }
    );

    const json = await response.json();
    if (json) {
      return json;
    }
  } catch (error) {
    if (error instanceof SyntaxError) {
      // Unexpected token < in JSON
      console.log("There was a SyntaxError", error);
    } else {
      console.log("Oops Something went wrong!", error);
    }
  }
};

const Endpoints = {
  GetPuntvilleBets,
  GetOpenBets,
  GetLockedBets,
  GetArchivedBets,
  createBet,
};

export default Endpoints;
