import React, { useState } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";

import "../styles/output.css";

const options1 = [
  {
    /* value: 'Mumbai', /*label: 'Mumbai'*/
  },
  { value: "Delhi", label: "Delhi" },
  { value: "Bengaluru", label: "Bengaluru" },
  { value: "Shenzhen", label: "Shenzhen" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Dubai", label: "Dubai" },
  { value: "Sharjah", label: "Sharjah" },
  { value: "Singapore", label: "Singapore" },
];

class Source_tab extends React.Component {
  state = {
    selectedOption1: null,
  };
  handleChange = (selectedOption1) => {
    this.setState({ selectedOption1 });
    console.log(`Sources selected:`, [selectedOption1]);
  };
  render() {
    const { selectedOption1 } = this.state;

    return (
      <div class="px-4 w-56 leading-tight mb-4 text-xs justify-between items-center">
        <div class=" text-lg text-black pb-1 ">Source</div>

        {
          <Select
            value={selectedOption1}
            onChange={this.handleChange}
            options={options1}
            placeholder={"Location(s)"}
            isMulti
            closeMenuOnSelect={false}
            autosize={false}
          />
        }
      </div>
    );
  }
}

export default Source_tab;
