import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import Epic_tab from "./Epic_tab.js";
import New_message from "./New_message.js";
import { Link } from "react-router-dom";

//import profile image from assets
import ProfileImg from "../assets/profile.svg";

// import getBalance from "./Account_Infomation/Balance.js";
import ErrorMessagePopup from "./Popups/ErrorMessage.js";
import { slide as Menu } from "react-burger-menu";
import Card_popout from "./Card_popout.js";
import bellicon from "./bellicon.svg";
import belldark from "./belldark.svg";
import "../styles/output.css";
import { compose } from "redux";
import Modal from "react-modal";
import getIsVerified from "./Account_Infomation/isVerified.js";
import PostMessagePopup from "./Popups/PostMessagePopup.js";
import add_red from "./add_red.svg";
import add_blue from "./add_blue.svg";
import folder_red from "./folder_red.svg";
import folder_blue from "./folder_blue.svg";
import search_red from "./search_red.svg";
import search_blue from "./search_blue.svg";
import home_red from "./home_red.svg";
import home_blue from "./home_blue.svg";
import puntville from "./puntville.svg";
import Destination_tab from "./Destination_tab.js";
import Card_popout2 from "./Card_popout2.js";
import Card_popout3 from "./Card_popout3.js";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { SafeAreaView, Text } from "react-native";
import { GlobalContext } from "./GlobalContext.js";
import getToken from "./Token.js";
import getUsername from "./Account_Infomation/Username.js";

import avatar1 from "../assets/avatar/PuntvilleAvatar (1).svg";
import avatar2 from "../assets/avatar/PuntvilleAvatar (2).svg";
import avatar3 from "../assets/avatar/PuntvilleAvatar (3).svg";
import avatar4 from "../assets/avatar/PuntvilleAvatar (4).svg";
import avatar5 from "../assets/avatar/PuntvilleAvatar (5).svg";
import avatar6 from "../assets/avatar/PuntvilleAvatar (6).svg";
import avatar8 from "../assets/avatar/PuntvilleAvatar (8).svg";
import avatar9 from "../assets/avatar/PuntvilleAvatar (9).svg";
import useravatar from "../assets/avatar/avatarPlaceholder.svg";
import CoinsIcon from "../assets/CoinsIcon.svg";
import { useQuery } from "react-query";

function Top_bar() {
  const {
    setShowFilteredData,
    userAvatar,
    setUserAvatar,
    chatRef,
    setChatRef,
  } = useContext(GlobalContext);
  const { setUsermessagefeed, archivedPages, setArchivedPages } =
    useContext(GlobalContext);
  const curUser = getUsername();
  const { notsocket } = useContext(GlobalContext);
  const { feedsocket } = useContext(GlobalContext);
  // coins data

  // console.log("feedvalue in top_bar is " + props.feedvalue);
  const [homeimage, setHomeImage] = useState(home_blue);
  const [addimage, setAddImage] = useState(add_blue);
  const [folderimage, setFolderImage] = useState(folder_blue);
  const [searchimage, setSearchImage] = useState(search_blue);
  const [bell_icon, setBell_icon] = useState(bellicon);
  const isUserVerified = getIsVerified();
  const [hometext, setHomeText] = useState(
    <Text style={{ fontSize: 12 }}>
      Main feed to show you all the new{"\n"}bets. Just click on the one you
      {"\n"}want to bet against!
    </Text>
  );
  const [searchtext, setSearchText] = useState(
    <Text style={{ fontSize: 12 }}>
      Search for bets based on your{"\n"}favourite topics. Sports? Politics?
      {"\n"}Anything else? We got them all.{" "}
    </Text>
  );
  const [addtext, setAddText] = useState(
    <Text style={{ fontSize: 12 }}>
      Want to bet on something?{"\n"}Quickly create one here. You can{"\n"}also
      share with friends after{"\n"}creating bets.
    </Text>
  );
  const [managetext, setManageText] = useState(
    <Text style={{ fontSize: 12 }}>
      Manage all your bets in one place{"\n"}You can see the open bets, the{" "}
      {"\n"}bets that are locked or the{"\n"}archived bets. You can also settle
      {"\n"}the locked bets here.{" "}
    </Text>
  );

  const [profiletext, setProfileText] = useState(
    <Text style={{ fontSize: 12 }}>
      All about your account in one{"\n"}place.
    </Text>
  );

  //const [savedactive, setSavedactive] =useState(false);
  //const [myactive, setMyactive] =useState(false);

  const handleMymessagesclick = () => {
    setUsermessagefeed("Open Posts");
    setFolderImage(folder_red);
    setHomeImage(home_blue);
    setSearchImage(search_blue);
    setAddImage(add_blue);
    setManageText("");
  };

  const handlemessageboardsclick = () => {
    setShowFilteredData(false);
    setHomeImage(home_red);
    setFolderImage(folder_blue);
    setSearchImage(search_blue);
    setAddImage(add_blue);
    setHomeText("");
    feedsocket.send(
      JSON.stringify({
        user: getUsername(),
      })
    );
  };

  notsocket.onmessage = (event) => {
    if (JSON.parse(event.data)["user"] == getUsername()) {
      setBell_icon(belldark);
    }
    if (JSON.parse(event.data)["user"] == getUsername()) {
      if (JSON.parse(event.data)["tileref"] !== null) {
        setChatRef((prev) => [...prev, JSON.parse(event.data)["tileref"]]);
      }
    }
  };

  const [balanceReq, setBalanceReq] = useState(false);
  function BalanceHandler() {
    // let UpdateBalance = getBalance();
    let UpdateBalance = 10;
    if (UpdateBalance <= 0) {
      setBalanceReq(true);
      return true;
    }

    setBalanceReq(false);
    return false;
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [balancemodalIsOpen, setBalanceModalIsOpen] = useState(false);

  const setModalIsOpenToTrue = () => {
    setAddText("");
    setHomeImage(home_blue);
    setFolderImage(folder_blue);
    setSearchImage(search_blue);
    setAddImage(add_red);
    if (BalanceHandler()) {
      setBalanceModalIsOpen(true);
      return;
    }

    if (isUserVerified) {
      console.log("d");
      setModalIsOpen(true);
    }
  };
  const setModalIsOpenToFalse = () => {
    setHomeImage(home_blue);
    setFolderImage(folder_blue);
    setSearchImage(search_blue);
    setAddImage(add_blue);
    setModalIsOpen(false);
    setBalanceModalIsOpen(false);
  };

  const getUserData = async () => {
    const accesskeytoken = getToken();
    const curUser = getUsername();
    const link = `${process.env.REACT_APP_LINK}`;
    try {
      const response = await fetch(link + "get-user-data/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesskeytoken,
        },
        body: JSON.stringify({ user: curUser }),
      });

      const json = await response.json();
      if (json) {
        return json;
      }
    } catch (error) {
      console.log("Something went wrong, please try again later", error);
    }
  };

  // fetch userData
  const {
    isLoading: isUserDataLoading,
    data: userData,
    isError: isUserError,
    error: userErr,
  } = useQuery("userDetails", getUserData, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const getCredits = async () => {
    const accesskeytoken = getToken();
    const curUser = getUsername();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LINK}` + "credits/",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + accesskeytoken,
          },
          body: JSON.stringify({
            user: curUser,
          }),
        }
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  };

  // fetch credits
  const {
    isLoading: isCreditssDataLoading,
    data: creditsData,
    isError: isCreditsError,
    error: creditsErr,
  } = useQuery("puntvilleUserCredits", getCredits, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (userData) {
      setArchivedPages(userData?.Archived_pages);
      setUserAvatar(userData?.logo);
    }
  }, [userData]);

  useEffect(() => {
    if (window.location.pathname === "/home") {
      setHomeImage(home_red);
    } else if (
      window.location.pathname === "/my-posts/open" ||
      window.location.pathname === "/my-posts/locked" ||
      window.location.pathname === "/my-posts/archived"
    ) {
      setFolderImage(folder_red);
    }
  }, []);

  return (
    <header>
      <div className="z-30 fixed top-0 w-full bg-[#ffeb9a] to-70% border border-green-100">
        {/* Navbar Top-row */}
        <div className="p-1 px-5 flex shadow-sm items-center justify-between">
          {/* Profile_Icon */}
          <div id="profile" className={"relative md:ml-6 "}>
            <Card_popout>
              <button
                onClick={() => {
                  setProfileText("");
                  setShowFilteredData(false);
                }}
                className={"  border:none  "}
              >
                <img
                  src={
                    userAvatar ||
                    "https://zaplynimages.s3.eu-west-2.amazonaws.com/puntville_placeholder.svg"
                  }
                  alt="userimage"
                  className="h-8 w-8 md:h-10 md:w-10 xl:h-10 xl:w-10  rounded-full cursor-pointer"
                />
              </button>
            </Card_popout>
          </div>
          {/* Puntville_Icon */}
          <div onClick={handlemessageboardsclick}>
            <Link to="/home">
              <img
                src={puntville}
                alt="puntville"
                className="w-40 md:w-48 cursor-pointer"
              />
            </Link>
          </div>
          {/* Credits_Icon */}
          <div id="credits" className="flex items-center gap-1 md:mr-9 ">
            <img src={CoinsIcon} alt="" className="w-6 h-6" />
            <span className=" text-md text-green-700  ">
              {creditsData?.Credits}
            </span>
          </div>
        </div>

        {/* Navbar Bottom-row */}
        <div className="flex  items-center justify-around md:justify-center md:gap-20 shadow-md pt-1">
          {/* Home_Icon */}
          <div id="home" className=" ">
            <Link to="/home">
              <p
                className="flex flex-col items-center   cursor-pointer text-center text-black  text-xxs md:text-xs  "
                onClick={handlemessageboardsclick}
              >
                <img src={homeimage} alt="myimage" className="h-6 w-6 " />
                <span className="pt-1">Home</span>
              </p>
            </Link>
          </div>
          {/* Add_Icon */}
          <div id="add" className="">
            <p
              className="flex flex-col items-center cursor-pointer text-center hover:text-black  text-xxs md:text-xs "
              onClick={setModalIsOpenToTrue}
            >
              <img src={addimage} alt="myimage" className="h-6 w-6" />
              <span className="pt-1">Add New</span>
            </p>

            <Modal
              isOpen={modalIsOpen}
              className=" max-w-xl z-50 mx-5 sm:mx-auto my-5 md:my-8 rounded-lg shadow-md text-center focus:outline-none"
              overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
              onRequestClose={() => setModalIsOpen(false)}
            >
              <button
                onClick={setModalIsOpenToFalse}
                className="flex justify-start  m-1 px-2 button shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-md  flex-shrink"
              >
                x
              </button>
              <PostMessagePopup
                onClick={setModalIsOpenToFalse}
                isRcordingAudio={false}
              />
            </Modal>

            {/* Balance Error Message */}

            <Modal
              isOpen={balancemodalIsOpen}
              className=" max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center"
              overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
              onRequestClose={() => setModalIsOpen(false)}
            >
              <button
                onClick={setModalIsOpenToFalse}
                className=" flex justify-start  m-1  px-2 button shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-md  flex-shrink"
              >
                x
              </button>
              <ErrorMessagePopup
                onClick={setModalIsOpenToFalse}
                message={"Cannot create any bets, balance is insufficient"}
              />
            </Modal>
          </div>
          {/* Manage_Icon */}
          <div id="manage" className="">
            <Link to="/my-posts/open">
              <p
                className=" cursor-pointer flex flex-col items-center text-black  text-xxs md:text-xs  "
                onClick={handleMymessagesclick}
              >
                <img src={folderimage} alt="myimage" className="h-6 w-6" />
                <span className="pt-1 ">Manage</span>
              </p>
            </Link>
          </div>
          {/* Notification Icon */}
          <div id="notification" className="relative  ">
            <Card_popout3>
              <button
                className="flex flex-col items-center text-black"
                onClick={() => {
                  setBell_icon(bellicon);
                  var accesskeytoken = getToken();
                  fetch(
                    `${process.env.REACT_APP_LINK}` + "read-notifications/",
                    {
                      method: "POST",
                      body: JSON.stringify({
                        user: getUsername(),
                      }),
                      headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        Authorization: "Bearer " + accesskeytoken,
                      },
                    }
                  )
                    .then((response) => response.json())
                    .then((json) => console.log(""));
                }}
              >
                <img src={bell_icon} className="h-8 w-8 " alt="" />
                <span className="text-xxs md:text-xs pb-1">Notification</span>
              </button>
            </Card_popout3>
          </div>
        </div>

        <ReactTooltip
          style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
          anchorId="home"
          place="bottom"
          variant="warning"
          content={hometext}
        />
        <ReactTooltip
          style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
          anchorId="search"
          place="bottom"
          variant="warning"
          content={searchtext}
        />

        <ReactTooltip
          style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
          anchorId="manage"
          place="bottom"
          variant="warning"
          content={managetext}
        />
        <ReactTooltip
          style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
          anchorId="profile"
          place="bottom"
          variant="warning"
          content={profiletext}
        />
        <ReactTooltip
          style={{ backgroundColor: "#D7E3F0", borderRadius: 10 }}
          anchorId="add"
          place="bottom"
          variant="warning"
          content={addtext}
        />
      </div>
    </header>
  );
}

export default Top_bar;
