import React from "react";
import ReactDOM from "react-dom";

import "../styles/output.css";

class New_message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };
    this.toggleBox = this.toggleBox.bind(this);
  }

  toggleBox() {
    const { opened } = this.state;
    this.setState({
      opened: !opened,
    });
  }

  render() {
    var { style, children } = this.props;
    const { opened } = this.state;

    return (
      <div>
        <button class="focus:outline-none" onClick={this.toggleBox}>
          {children}
        </button>
        {opened && (
          <div>
            <div className="absolute h-auto justify-center text-center align-content w-screen max-w-md flexbox">
              <div className="flexbox bg-gray-400  -sm px-4 py-6 mx-4 my-4">
                <div className=" mx-auto overflow-auto  -sm">
                  <input
                    placeholder="Request Type: "
                    class="mt-2 mb-6  w-full outline-none text-sm shadow-md  -sm p-2"
                  />
                  <br />
                  <input
                    placeholder="Date: "
                    class="mt-2 mb-6  w-full outline-none text-sm shadow-md  -sm p-2"
                  />
                  <br />
                  <input
                    placeholder="From (Source): "
                    class="mt-2 mb-6  w-full outline-none text-sm shadow-md  -sm p-2"
                  />
                  <br />
                  <input
                    placeholder="To (Destination): "
                    class="mb-6 w-full outline-none shadow-md text-sm bg-gray-100 appearance-none  -sm p-2"
                  />
                  <br />
                  <input
                    placeholder="Weight (in kg): "
                    class="mb-6 w-full outline-none shadow-md text-sm border-gray-500 appearance-none  -sm p-2"
                  />
                  <br />
                  <input
                    placeholder="Dimensions (best fit box size in cm): "
                    class="mb-6 w-full outline-none shadow-md  text-sm border-gray-500 appearance-none  -sm p-2"
                  />
                  <br />
                  <input
                    placeholder="Item Type: "
                    class="mb-6 w-full outline-none shadow-md  text-sm border-gray-500 appearance-none  -sm p-2"
                  />
                  <br />
                  <input
                    placeholder="Description: "
                    class="mb-6 w-full outline-none text-sm shadow-md border-gray-500 appearance-none  -sm p-2"
                  />
                </div>
                <div className="flex justify-center mt-4">
                  <div className="button cursor-pointer text-center hover:text-white flex items-center justify-center shadow-md  hover:bg-red-800 focus:outline-none  -sm h-8 w-20 bg-green-400 mr-2">
                    Post it!
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default New_message;
