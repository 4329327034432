import React from "react";
import ReactDOM from "react-dom";

import "../styles/output.css";
import New_message from "./New_message.js";

function Epic_tab() {
  return (
    <div className=" ">
      <div class=" px-4 pb-6 items-center">
        <div className="button cursor-pointer">
          <button className="focus:outline-none shadow-md hover:bg-red-800 hover:text-white bg-green-400 h-8 w-48 flex items-center justify-center  text-center text-sm  -sm ">
            Find a Sherpa
          </button>
          <div className="text-center text-black opacity-5 mb-4 text-xs">
            Send/receive something
          </div>
        </div>
        <div className="button cursor-pointer">
          <button className="focus:outline-none  shadow-md hover:bg-red-800 hover:text-white bg-green-400 h-8 w-48 flex items-center justify-center text-center text-black text-sm  -sm ">
            Be a Sherpa
          </button>
          <div className="text-center text-black opacity-5 text-xs">
            Carry something
          </div>
        </div>
      </div>
    </div>
  );
}
export default Epic_tab;
