export default function LoadingModal() {
  return (
    <div
      className="fixed inset-0 z-100 bg-black bg-opacity-25  flex justify-center items-center"
      id="wrapper"
    >
      <div className="w-[600px] flex items-center justify-center gap-2">
        <div className="w-4 h-4 rounded-full animate-pulse bg-blue-400"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-blue-400"></div>
        <div className="w-4 h-4 rounded-full animate-pulse bg-blue-400"></div>
      </div>
    </div>
  );
}
