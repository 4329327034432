import React from "react";

import getToken from "../../../Token";
import getUsername from "../../../Account_Infomation/Username";
import IconComponent from "../../../Utility/IconComponent";
import beticon from "../../../../assets/beticon.svg";

const ArchivedCards = ({ message }) => {
  var link = `${process.env.REACT_APP_LINK}`;
  //var link = 'http://localhost:8000/api/'
  let accesstoken = getToken();
  const curUser = getUsername();

  return (
    <div id="column layout" className=" relative pt-1">
      <div className="absolute text-xs -top-4   text-gray-600   ">
        <p>Expired At: {message.active_period.slice(0, 10)}</p>
      </div>
      <p className="absolute text-xs -top-4 right-2 flex-row text-gray-600 ">
        {message.PuntvilleBetRef}
      </p>
      <div className="w-full  ">
        <div className="">
          <div
            id="containerbox"
            className="grid grid-cols-2 w-full content-evenly justify-items-stretch text-center "
          >
            {" "}
            <div id="Icon" className="text-center w-full flex text-xs ">
              <div id="logo " className=" mx-auto">
                <IconComponent subject={message.topic} />
              </div>
            </div>
            <div
              id="Details"
              className="text-center flex flex-col p-2 text-xs "
            >
              <div id="right box" className="flex-shrink flex flex-col      ">
                <div
                  id="top col for name"
                  className=" flex-shrink py-2 text-lg border-b border-gray-400"
                >
                  {" "}
                  {message.author}{" "}
                </div>
                <div
                  id="middle col for price "
                  className=" flex items-end justify-center text-gray-700 pt-2  text-2xl  "
                >
                  <span className="text-xs pb-1 ">bets &nbsp;</span>{" "}
                  <img src={beticon} alt="" className="w-4 h-8" />
                  <span className="text-2xl">{message.body}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center bg-gradient-to-b from-[#feffad] to-[#ffeb9a] rounded-md shadow-md  p-1 mb-2">
            <div
              id="col for per month"
              className="text-green-700 text-2xl   break-normal "
            >
              {message.bet}
            </div>
          </div>
          <div className="flex items-center justify-center">
            {message.winner !== "bet expired" ? (
              <p className={"text-lg "}>Winner: {message.winner}</p>
            ) : (
              <p className={"text-lg capitalize"}> {message.winner}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchivedCards;
