import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import getEmail from "../Account_Infomation/RegEmail";
import getReferralCode from "../Account_Infomation/referralCode";
import getUsername from "../Account_Infomation/Username";
import React, { useState, useContext, useEffect } from "react";
import Modal from "react-modal";
import ChangePassword from "./ChangePassword";

import getToken from "../Token";
import { GlobalContext } from "../GlobalContext";
import { ToastContainer, toast } from "react-toastify";

const Your_Account = (props) => {
  const customStyles = {
    overlay: {
      background: "rgba(52, 52, 52, 0.8)",
      overflow: "scroll",
      align: "center",
    },

    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFFFFF",
      marginTop: "15%",
    },
  };

  const { userAvatar, setUserAvatar, userAvatarArray } =
    useContext(GlobalContext);
  const [selectedAvatar, setSelectedAvatar] = useState(userAvatar);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  // avatar modal
  const [avatarModalIsOpen, setAvatarModalIsOpen] = useState(false);
  const setAvatarModalIsOpenToTrue = () => {
    setAvatarModalIsOpen(true);
  };

  const setAvatarModalIsOpenToFalse = () => {
    setAvatarModalIsOpen(false);
  };
  const userReferral = getReferralCode();
  const userEmail = getEmail();
  const username = getUsername();

  const handleAvatarChange = async () => {
    const accesskeytoken = getToken();
    const link = `${process.env.REACT_APP_LINK}`;
    const response = await fetch(link + "set-user-data/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesskeytoken,
      },
      body: JSON.stringify({
        user: username,
        logo: selectedAvatar,
      }),
    });

    const json = await response.json();

    if (json) {
      toast(json["Notification-text"]);
    }
  };

  Auth.currentAuthenticatedUser()
    .then((user) => {
      return Auth.changePassword(user, "oldPassword", "newPassword");
    })
    .then((data) => console.log())
    .catch((err) => console.log());
  // Auth.currentAuthenticatedUser()
  //   .then((user) => {
  //     return Auth.changePassword(user, "oldPassword", "newPassword");
  //   })
  //   .then((data) => console.log(data))
  //   .catch((err) => console.log(err));

  return (
    <div className="font-fredoka py-16 rounded-lg flex flex-col items-center  w-full pt-32 h-screen">
      <ToastContainer autoClose={1000} />
      <div className="title-container block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2">
        <h1>Your Account Details</h1>
      </div>

      <div className="max-w-md p-8 sm:flex sm:space-x-6 text-gray-900 ">
        <div className="flex flex-col items-center justify-center  w-full sm:h-32 mb-6 sm:mb-0">
          <img
            src={
              userAvatar ||
              "https://zaplynimages.s3.eu-west-2.amazonaws.com/puntville_placeholder.svg"
            }
            alt=""
            className="object-cover object-center w-32  rounded-full bg-gray-500"
          />
          <p
            className="text-center cursor-pointer bg-gradient-to-t from-blue-600 to-blue-500  text-transparent bg-clip-text"
            onClick={setAvatarModalIsOpenToTrue}
          >
            {" "}
            Edit
          </p>
        </div>
        <div className="flex flex-col space-y-4">
          <div>
            <h2 className="text-2xl font-semibold">{username}</h2>
            {/* <span className="text-sm text-gray-400">Premium Userr</span> */}
          </div>
          <div className="space-y-1">
            <span className="flex items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                aria-label="Email address"
                className="w-4 h-4"
              >
                <path
                  fill="currentColor"
                  d="M274.6,25.623a32.006,32.006,0,0,0-37.2,0L16,183.766V496H496V183.766ZM464,402.693,339.97,322.96,464,226.492ZM256,51.662,454.429,193.4,311.434,304.615,256,268.979l-55.434,35.636L57.571,193.4ZM48,226.492,172.03,322.96,48,402.693ZM464,464H48V440.735L256,307.021,464,440.735Z"
                ></path>
              </svg>
              <span className="text-gray-500">{userEmail}</span>
            </span>
            <span className="flex items-center space-x-2">
              <span className="text-gray-500">Referral Code: </span>
              <span>{userReferral}</span>
            </span>
          </div>
        </div>
      </div>
      <div className=" flex items-center justify-center gap-5 w-full">
        <Link to="/home">
          <button className="flex justify-center gap-1 py-1 px-4 cardBtn text-xs font-semibold shadow-md  my-5">
            Back
          </button>
        </Link>
        <button
          className="py-1 px-4 cardBtn text-xs font-semibold  shadow-md "
          onClick={setModalIsOpenToTrue}
        >
          Change Password
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <button
          onClick={setModalIsOpenToFalse}
          className=" px-2 button shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-md  flex-shrink"
        >
          x
        </button>
        <ChangePassword onClick={setModalIsOpenToFalse} />
      </Modal>

      {/* avatar modal */}
      <Modal
        isOpen={avatarModalIsOpen}
        className=" max-w-md z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setAvatarModalIsOpen(false)}
      >
        <button
          onClick={setAvatarModalIsOpenToFalse}
          className="flex justify-start mb-1  px-2 pb-1 button rounded-lg shadow-lg focus:outline-none cursor-pointer hover:bg-red-800 hover:text-white bg-yellow-400 text-sm font-semibold flex-shrink"
        >
          x
        </button>
        <div className="bg-white p-5 rounded-xl">
          <div className="grid grid-cols-4 gap-2 mb-2 justify-items-center">
            {userAvatarArray.map((item) => (
              <img
                key={item.id}
                onClick={() => setSelectedAvatar(item.url)}
                src={item.url}
                alt=""
                className={`w-16 h-16 rounded-full cursor-pointer p-1 ${
                  selectedAvatar === item.url ? "opacity-30" : ""
                }`}
              />
            ))}
          </div>

          <div className="flex items-center justify-center space-x-4">
            <button
              onClick={() => {
                setAvatarModalIsOpen(false);
                setSelectedAvatar(userAvatar);
              }}
              className=" rounded-full bg-gradient-to-t from-blue-200 to-blue-100 w-24 font-semibold text-md text-gray-700 my-1 shadow-md "
            >
              cancel
            </button>
            <button
              onClick={() => {
                handleAvatarChange();
                setUserAvatar(selectedAvatar);
                setAvatarModalIsOpenToFalse();
              }}
              className=" rounded-full bg-gradient-to-t from-blue-200 to-blue-100 w-24 font-semibold text-md text-gray-700 my-1 shadow-md "
            >
              save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Your_Account;
