import React, { useEffect } from "react";
import { useState, useCallback } from "react";
import getToken from "../Token";
import getUsername from "../Account_Infomation/Username";
import { Link } from "react-router-dom";
import MessagePopup from "../MessagePopup.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Support_Page() {
  var link = `${process.env.REACT_APP_LINK}`;
  const contactForm = document.querySelector("content-wrapper");

  const [posting, setPosting] = useState(false);
  const accesstoken = getToken();
  const username = getUsername();
  //const [isPending, setIsPending] = useState(false);

  const [body, setBody] = useState("");

  const [message_name, setmessage_name] = useState("");

  const [message_email, setmessage_email] = useState("");
  const [message_mobile, setmessage_mobile] = useState("");

  const [message_subject, setmessage_subject] = useState("");

  const [message, setmessage] = useState("");

  const handleSumbit = (e) => {
    e.preventDefault(); //prevents refreshing page
    const content = {
      message_name,
      message_email,
      message_mobile,
      message_subject,
      message,
    };

    // console.log(content);
    //setIsPending(true);

    //global.messagelist.push(content);
    //document.getElementById("messagesubject").reset();
  };

  return (
    <div className="font-fredoka">
      <body>
        <div class="content-wrapper" className="flex justify-center pt-32">
          <div class="questions-wrapper  py-10">
            <div class="questions">
              <h2 class="block uppercase tracking-wide text-gray-700 text-xl  mb-2">
                How can we help you?
              </h2>
              <p>If you have any questions or need assistance, please</p>
              <p>
                tell us by filling out the form below and we will get back to
                you!
              </p>
            </div>
          </div>
        </div>
        {/* <section> */}
        <div class="contact-box flex justify-center m-3 pb-10">
          <form class="w-full max-w-lg" onSubmit={handleSumbit}>
            <div class="flex flex-wrap -mx-3 mb-6">
              {/* Name input */}

              <div class="w-full p-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-sm  mb-2"
                  for="grid-first-name"
                >
                  Full Name
                </label>
                <input
                  class="focus:outline-none placeholder-gray-600 shadow-sm text-sm p-2 cursor-pointer w-full rounded-lg bg-gradient-to-t from-blue-200 to-blue-100"
                  type="text"
                  value={message_name}
                  onChange={(e) => setmessage_name(e.target.value)}
                  name="message-name"
                  placeholder="Name"
                />
              </div>

              {/* Email Input */}

              <div class="w-full p-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
                  for="grid-password"
                >
                  E-mail
                </label>
                <input
                  class="focus:outline-none placeholder-gray-600 shadow-sm text-sm p-2 cursor-pointer w-full rounded-lg bg-gradient-to-t from-blue-200 to-blue-100"
                  type="email"
                  value={message_email}
                  onChange={(e) => setmessage_email(e.target.value)}
                  name="message-email"
                  placeholder="Email"
                />
              </div>

              {/* Mobile Input */}

              <div class="w-full p-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
                  for="grid-password"
                >
                  Mobile
                </label>
                <input
                  class="focus:outline-none placeholder-gray-600 shadow-sm text-sm p-2 cursor-pointer w-full rounded-lg bg-gradient-to-t from-blue-200 to-blue-100"
                  type="mobile"
                  value={message_mobile}
                  onChange={(e) => setmessage_mobile(e.target.value)}
                  name="message-mobile"
                  placeholder="Mobile"
                />
              </div>

              {/* Subject Input */}

              <div class="w-full p-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
                  for="grid-last-name"
                >
                  Subject
                </label>
                <input
                  class="focus:outline-none placeholder-gray-600 shadow-sm text-sm p-2 cursor-pointer w-full rounded-lg bg-gradient-to-t from-blue-200 to-blue-100"
                  type="text"
                  value={message_subject}
                  onChange={(e) => setmessage_subject(e.target.value)}
                  name="message-subject"
                  placeholder="Subject"
                />
              </div>
            </div>

            {/* Message Input */}

            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
                  for="grid-password"
                >
                  Message
                </label>
                <textarea
                  class="focus:outline-none placeholder-gray-600 shadow-sm text-sm p-2 cursor-pointer w-full rounded-lg bg-gradient-to-t from-blue-200 to-blue-100 h-48 resize-none"
                  id="message"
                  name="message"
                  value={message}
                  onChange={(e) => setmessage(e.target.value)}
                  placeholder="Message"
                ></textarea>
              </div>
            </div>
            <ToastContainer autoClose={2500} />
            <div class="flex justify-center">
              <div class="flex items-center px-10">
                <button
                  className="col-span-2 focus:outline-none text-center"
                  onClick={() => {
                    fetch(link + "contact-email/", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + accesstoken,
                      },
                      body: JSON.stringify({
                        message_name: username,
                        message_email: message_email,
                        message_mobile: message_mobile,
                        message_subject: message_subject,
                        message: message,
                      }),
                    })
                      .then((response) => response.json())
                      .then((json) => {
                        if (
                          message_email !== "" &&
                          message_mobile !== "" &&
                          message_subject !== "" &&
                          message !== ""
                        ) {
                          toast(
                            JSON.stringify(json["Notifications-text"]).replace(
                              /"|'/g,
                              ""
                            )
                          );
                        } else {
                          toast(
                            "Please fill all fields to create support request"
                          );
                        }
                      });

                    setmessage_name("");
                    setmessage_email("");
                    setmessage_mobile("");
                    setmessage_subject("");
                    setmessage("");
                  }}
                >
                  <div className="focus:border-0 flex-3 ">
                    <div className=" md:w-40 sm:w-30 button  p-2  mt-1 button h-12 text-center shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-xl  ">
                      Submit
                    </div>
                  </div>
                </button>
              </div>
              {/* <div class="flex items-center px-10">
                  <Link to="puntville.com">
                    <button className=" md:w-40 sm:w-30 button cursor-pointer p-2  mt-1 button h-12 text-center shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-xl  ">
                      Back
                    </button>
                  </Link>
                </div> */}
              <div class=""></div>
            </div>
          </form>
        </div>
        {/* </section> */}
      </body>
    </div>
  );
}
