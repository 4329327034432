import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";
import OpenCards from "./open/OpenCards";
import LockedCards from "./locked/LockedCards";
import ArchivedCards from "./archived/ArchivedCards";
import getToken from "../../Token";
import getUsername from "../../Account_Infomation/Username";
import { useQuery } from "react-query";

function FilterData() {
  const accesstoken = getToken();
  const curUser = getUsername();

  const {
    selectedCategoryFilterOptions,
    setSelectedCategoryFilterOptions,
    selectedTopicFilterOptions,
    setSelectedTopicFilterOptions,
  } = useContext(GlobalContext);

  const [page, setPage] = useState(1);

  const FetchFilteredData = async (pageNo) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LINK}` + "puntville-filter-list/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
          },
          body: JSON.stringify({
            user: curUser,
            topics: selectedCategoryFilterOptions
              ?.map((item) => item.topicname)
              .toString(),
            options: selectedTopicFilterOptions
              ?.map((item) => item.value)
              .toString(),
          }),
        }
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log("Error", error);
    }
  };

  // fetch filtered orders
  const {
    isLoading: isFliteredBetsLoading,
    data: fliteredBetsData,
    isError: isFilteredBetsError,
    error: filteredBetsErr,
    isFetching: isFilteredBetsDataFetching,
    isPreviousData: isFilteredPrevBetsData,
    refetch: refetchFilteredBetsData,
  } = useQuery(["filteredBetsData", { page }], () => FetchFilteredData(page), {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (
      selectedCategoryFilterOptions?.length > 0 ||
      selectedTopicFilterOptions?.length > 0
    ) {
      refetchFilteredBetsData();
    }
  }, [selectedCategoryFilterOptions, selectedTopicFilterOptions]);

  return (
    <div className="flex justify-center mt-5 items-center max-w-4xl mx-auto">
      <div className="grid md:grid-cols-2 ">
        {fliteredBetsData?.length > 0 ? (
          fliteredBetsData
            .slice(0)
            .reverse()
            .map((message, index) => (
              <div>
                <div className=" px-4 py-6 mx-2 my-5  border max-w-md border-red-100 rounded-lg shadow-md justify-items-center">
                  {/* open-posts */}
                  {message.Tile_status === "Open" && (
                    <OpenCards message={message} />
                  )}

                  {/* Locked Posts */}

                  {message.Tile_status === "Saved" && (
                    <LockedCards message={message} />
                  )}

                  {/* archived-posts */}

                  {message.Tile_status === "Archived" && (
                    <ArchivedCards message={message} />
                  )}
                </div>
              </div>
            ))
        ) : (
          <p>Oops no data available! </p>
        )}
      </div>
    </div>
  );
}

export default FilterData;
