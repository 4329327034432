import dayjs from "dayjs";

const DIVISIONS = [
  { amount: 59, name: "seconds" },
  { amount: 59, name: "minutes" },
  { amount: 23, name: "hours" },
  { amount: Number.POSITIVE_INFINITY, name: "days" },
];
const RELATIVE_DATE_FORMATTER = new Intl.RelativeTimeFormat(undefined, {
  numeric: "auto",
});

export function formatRelativeDate(message, fromDate = dayjs(Date.now())) {
  var expire = dayjs(message.active_period);

  let duration = (expire - fromDate) / 1000;

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return RELATIVE_DATE_FORMATTER.format(
        Math.round(duration),
        division.name
      );
    }
    duration /= division.amount;
  }
}
