import React from "react";

function MessagePopup(props) {
  return props.trigger ? (
    <div className="popup">
      <div className="popup-innet">
        <button className="close-btn">close</button>
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}

export default MessagePopup;
