import getToken from "../Token";
import { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
// import getBalance from "../Account_Infomation/Balance";
import Cards from "../Cards";
import { GlobalContext } from "../GlobalContext.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QRCode from "qrcode";
import Endpoints from "../../api/endpoints";
import { formatRelativeDate } from "../../helper/helper";
import { Auth } from "aws-amplify";
import getUsername from "../Account_Infomation/Username";
import { useQuery, useQueryClient } from "react-query";
import HomeFilterComponent from "./HomeFilterComponent";
import DestinationTab from "../DestinationTab";
import FilterIcon from "../../assets/icons/filter.png";
import Destination_tab from "../Destination_tab";

import First from "../../assets/first.svg";
import Second from "../../assets/second.svg";
import Third from "../../assets/third.svg";
const MessageBoard = () => {
  const queryClient = useQueryClient();
  var link = `${process.env.REACT_APP_LINK}`;
  const accesstoken = getToken();
  const { notsocket } = useContext(GlobalContext);
  const { feedsocket, selectedButtonFilters, setSelectedButtonFilters } =
    useContext(GlobalContext);
  const username = getUsername();
  const { filteredData } = useContext(GlobalContext);
  const {
    showFilteredData,
    setShowFilteredData,
    selectedHomeFilterTopicOptions,
    setSelectedHomeFilterTopicOptions,
    selectedConnectionOptions,
  } = useContext(GlobalContext);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  // Link share - qr code Func
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [sharebet, setSharebet] = useState("");
  const [linkModalIsOpen, setLinkModalIsOpen] = useState(false);

  const setLinkModalIsOpenToTrue = () => {
    setLinkModalIsOpen(true);
  };
  const setLinkModalIsOpenToFalse = () => {
    setLinkModalIsOpen(false);
  };

  const handleShareCardClick = (tileref) => {
    QRCode.toDataURL("https://puntville.com/share/" + tileref)
      .then((url) => {
        setQrCodeUrl(url);
      })
      .catch((err) => console.error(err));
  };

  const handleDownloadQrCode = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = qrCodeUrl;
    downloadLink.download = "qr-code.png";
    downloadLink.click();
  };

  //Function for filterdata using buttons

  function handleButtonFilter(filter) {
    setSelectedHomeFilterTopicOptions([]);
    const newSelected = [...selectedButtonFilters];

    if (newSelected.includes(filter)) {
      newSelected.splice(newSelected.indexOf(filter), 1);
    } else {
      newSelected.push(filter);
    }
    setSelectedButtonFilters(newSelected);
  }
  const [puntvilleBetsData2, setPuntvilleBetsData2] = useState("");
  // fetch openbets
  const {
    isLoading: isPuntvilleBetsDataLoading,
    data: puntvilleBetsData,
    isError: isPuntvilleBetsError,
    error: puntvilleBetsErr,
    refetch: refetchPuntvilleData,
  } = useQuery(["puntvillebets"], Endpoints.GetPuntvilleBets, {
    refetchInterval: 15000, // Fetch data every 15 seconds (adjust the interval as needed)
  });

  feedsocket.onmessage = (event) => {
    if (
      JSON.parse(event.data)["data"].length > 0 &&
      JSON.parse(event.data)["data"] !== "connected websocket"
    ) {
      setPuntvilleBetsData2(
        JSON.parse(event.data)["data"]["0"]["PuntvilleBetRef"]
      );
    }
  };
  useEffect(() => {
    if (puntvilleBetsData2 !== "") {
      refetchPuntvilleData();
    }
  }, [puntvilleBetsData2]);

  useEffect(() => {
    // console.log("runing use effect...");
    // const interval = setInterval(getPuntvilleData, 15000);

    function loadUser() {
      return Auth.currentAuthenticatedUser({ bypassCache: true });
    }
    async function onLoad() {
      try {
        const user = await loadUser();
        //console.log(JSON.stringify(user))
        //saves particular information
        global.username = user.username; //global variable username
        // props.currentusername(user.username); //set username for other components in feed_elements
      } catch (e) {
        alert(e);
      }
    }
    onLoad();
    feedsocket.onopen = () => {
      console.log("feed WebSocket Client Connected");
      feedsocket.send(
        JSON.stringify({
          user: getUsername(),
        })
      );
    };
    // return () => clearInterval(interval);
  }, []);

  // if (isPuntvilleBetsDataLoading) {
  //   return <p className="pt-40 text-center">Loading... </p>;
  // }

  useEffect(() => {
    // console.log("running");
    if (selectedButtonFilters.length > 0) {
      setShowFilteredData(true);
    } else if (
      selectedButtonFilters?.length === 0 &&
      selectedHomeFilterTopicOptions?.length === 0 &&
      selectedConnectionOptions?.length === 0
    ) {
      setShowFilteredData(false);
    }
  }, [selectedButtonFilters]);

  if (isPuntvilleBetsDataLoading) {
    return <p>Loading ...</p>;
  }

  return (
    <section>
      <ToastContainer autoClose={2500} />
      {/*test div */}
      <div className="flex flex-col sm:mt-0 justify-center items-center pt-28 sm:pt-36 pb-40 max-w-2xl lg:max-w-4xl mx-4 md:mx-auto">
        {/* filter tabs */}
        {puntvilleBetsData && (
          <div className="flex items-center justify-between w-full max-w-2xl lg:max-w-4xl  mx-5 md:mx-auto mb-5">
            <div className="flex gap-3 scrollbar-hide overflow-x-auto over pr-5">
              {[
                { item: "Stocks and Markets", value: "Stocks and Markets" },
                { item: "Politics", value: "Politics" },
                { item: "Cricket", value: "Cricket" },
                { item: "Football", value: "Football" },
              ].map((item, index) => (
                <div key={index} className="flex-shrink-0">
                  <button
                    onClick={() => handleButtonFilter(item.value)}
                    className={` py-1 px-2  rounded-full text-xs text-[#000F99]  shadow-md ${
                      selectedButtonFilters.includes(item.value)
                        ? "cardBtn"
                        : "border-[0.5px] border-[#000F99]"
                    } `}
                  >
                    {item.item}
                  </button>
                </div>
              ))}
            </div>
            <div className=" relative ">
              <img
                src={FilterIcon}
                className={`${
                  selectedHomeFilterTopicOptions.length > 0 || isFilterOpen
                    ? "bg-[#34dd5a]"
                    : ""
                } h-8 w-8 z-50 rounded-full p-1 border border-red-100 shadow-md cursor-pointer
`}
                alt=""
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              />
              {isFilterOpen && (
                <Destination_tab setIsFilterOpen={setIsFilterOpen} />
              )}
            </div>
          </div>
        )}

        {/* main section */}
        <div className=" w-full ">
          {showFilteredData ? (
            <>
              <HomeFilterComponent />
            </>
          ) : (
            <div className="grid md:grid-cols-2 ">
              {puntvilleBetsData?.map((message) => (
                <div
                  key={message.id}
                  className="my-2 mx-1 p-4 py-8 max-w-md rounded-lg shadow-md border-t border-gray-50 justify-items-center relative"
                >
                  <div className="absolute text-xs top-1 left-2 px-4    ">
                    <p> Expires {formatRelativeDate(message)} </p>
                  </div>

                  <div className="absolute text-xs top-1 right-2 px-4 flex gap-1 items-center ">
                    {"  "}
                    Connection{" "}
                    {message.connection === "1st" ? (
                      <img src={Third} alt="" className="w-3 h-3" />
                    ) : message.connection === "2nd" ? (
                      <img src={Second} alt="" className="w-3 h-3" />
                    ) : (
                      <img src={First} alt="" className="w-3 h-3" />
                    )}
                  </div>
                  <Cards message={message} />
                  <div className="text-center text-lg m-1">
                    {" "}
                    You think otherwise?
                  </div>
                  <div className=" grid grid-cols-2 ">
                    <div className="flex-shrink-0">
                      <button
                        className="flex-nowrap flex-shrink  button w-full mr-4 p-2  shadow-sm focus:outline-none cursor-pointer cardBtn text-xs  "
                        data-id={message.id}
                        data-author={message.author}
                        data-topic={message.topic}
                        data-body={message.body}
                        data-created_at={message.created_at}
                        data-active_period={message.active_period}
                        data-tileref={message.PuntvilleBetRef}
                        data-bet={message.bet}
                        onClick={() => {
                          if (global.username === message.author) {
                            toast("You can't take your own bets! ");
                          } else {
                            const savedcontent = {
                              id: message.id,
                              user: global.username,
                              topic: message.topic,
                              body: message.body,
                              author: message.author,
                              PuntvilleBetRef: message.PuntvilleBetRef,
                              created_at: message.created_at,
                              bet: message.bet,
                              active_period: message.active_period,
                            };
                            <ToastContainer autoClose={2500} />;
                            fetch(link + "savedpuntville-create/", {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + accesstoken,
                              },
                              body: JSON.stringify(savedcontent),
                            })
                              .then((response) => response.json())
                              .then(async (json) => {
                                toast(
                                  JSON.stringify(
                                    json["Notification-text"]
                                  ).replace(/"|'/g, "")
                                );
                                if (
                                  JSON.stringify(
                                    json["Notification-text"]
                                  ).replace(/"|'/g, "") !==
                                  "Credits are not enough to take this bet."
                                ) {
                                  toast(
                                    "Congrats! This bet is locked now. You can find it in the 'Manage' section "
                                  );
                                }

                                await queryClient.invalidateQueries(
                                  "puntvilleUserCredits"
                                );
                                await queryClient.invalidateQueries("openBets");
                                await queryClient.invalidateQueries(
                                  "lockedBets"
                                );
                                notsocket.send(
                                  JSON.stringify({
                                    user: username,
                                  })
                                );
                                feedsocket.send(
                                  JSON.stringify({
                                    user: getUsername(),
                                  })
                                );
                              });
                          }
                        }}
                      >
                        Take This Bet
                      </button>
                    </div>
                    <div className="ml-4">
                      <button
                        className=" button w-full mr-4 p-2  shadow-sm focus:outline-none cursor-pointer cardBtn text-xs  "
                        onClick={() => {
                          setLinkModalIsOpenToTrue();
                          setSharebet(message.PuntvilleBetRef);
                          handleShareCardClick(message.PuntvilleBetRef);
                        }}
                      >
                        Share Bet
                      </button>
                    </div>

                    {/* share link modal */}
                    <Modal
                      isOpen={linkModalIsOpen}
                      className="font-fredoka  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md shadow-lg text-center"
                      overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
                      onRequestClose={() => setLinkModalIsOpen(false)}
                    >
                      <button
                        onClick={setLinkModalIsOpenToFalse}
                        className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-blue-400 rounded-md text-sm font-semibold flex-shrink"
                      >
                        x
                      </button>
                      <div className="bg-white  p-6 rounded-md flex flex-col items-center">
                        <div className="relative">
                          <img
                            src={qrCodeUrl}
                            alt="QR code"
                            className="w-72 h-72"
                          />
                          <button
                            title="Download QR"
                            className="absolute bottom-5 -right-3 shadow-md bg-blue-300 rounded-b-md rounded-l-md"
                            onClick={handleDownloadQrCode}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              className="h-8 w-8"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
                            </svg>
                          </button>
                        </div>
                        <div className="w-full flex justify-center">
                          <input
                            type="text"
                            className="border border-blue-300 rounded-l-md text-sm p-2 md:w-80 text-gray-500"
                            value={`https://puntville.com/share/${sharebet}`}
                            readOnly
                          />
                          <button
                            className="bg-white px-2 border border-blue-300 rounded-r-md flex active:bg-blue-400 transition duration-200 ease-in items-center space-x-1 text-gray-700 "
                            onClick={() => {
                              navigator.clipboard.writeText(
                                "https://puntville.com/share/" + sharebet
                              );
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="20"
                              height="20"
                              className="w-4 h-4"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z" />
                            </svg>
                            <span className="text-xs">Copy</span>
                          </button>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default MessageBoard;
