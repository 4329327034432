import React, { useState, useEffect } from "react";

import getToken from "./Token.js";

import getUsername from "./Account_Infomation/Username.js";

const Notification_tab = () => {
  var link = `${process.env.REACT_APP_LINK}`;
  const accesstoken = getToken();
  const [notdata, setNotdata] = useState([]);
  const FetchNotification = async () => {
    const response = await fetch(link + "fetch-notifications/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify({
        user: getUsername(),
      }),
    });
    const json = await response.json();
    if (json) {
      if (json["Notification-text"] === "Failed.") {
        console.log(
          "failed to fetch notifications or there are no notifications for this user"
        );
      } else {
        setNotdata(json);
      }
    }
  };
  useEffect(() => {
    FetchNotification();
  }, []);

  return (
    <div className="  font-bellota p-1">
      <div className=" justify-items-center ">
        <div
          id=""
          className=" flex flex-col item-center  gap-1 bg-white h-80 overflow-y-auto "
        >
          {notdata != null &&
            notdata
              .slice()
              .reverse()
              .map((mes, id) => (
                <div
                  key={id}
                  className=" leading-5 font-fredoka bg-gradient-to-t from-blue-50 to-blue-40 border border-blue-100  p-2 shadow-sm"
                >
                  {mes.id !== "0" && (
                    <div>
                      <span className="text-xs md:text-xs  tracking-tight md:tracking-normal  text-justify">
                        {mes.notification}
                      </span>
                    </div>
                  )}
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Notification_tab;
