import React from "react";

function ImageModal({ imageSrc }) {
  return (
    <div className="flex items-center justify-center w-full md:w-3/4 h-3/4">
      <img src={imageSrc} alt="img" className=" w-full md:w-3/4 " />
    </div>
  );
}

export default ImageModal;
