/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-west-2",
  aws_cognito_identity_pool_id:
    "eu-west-2:bdc7ec91-0c13-442a-aa44-bcc6c876a71f",
  aws_cognito_region: "eu-west-2",
  aws_user_pools_id: "eu-west-2_UXJYfjlKr",
  // aws_user_pools_web_client_id: "16d01ptic6ic3ecanqkbdsg8rd",
  aws_user_pools_web_client_id: "5k305rgnr1t5g9btchop69qfv8",
  oauth: {},
  aws_cognito_login_mechanism: ["EMAIL"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
};

export default awsmobile;
