import React, { useContext, useEffect } from "react";

import { useState, useRef } from "react";
import TextField from "@mui/material/TextField";
// import getBalance from "../Account_Infomation/Balance";
import Endpoints from "../../api/endpoints";
import { Auth } from "aws-amplify";
import Switch from "@mui/material/Switch";
import getToken from "../Token";
import getUsername from "../Account_Infomation/Username";

import useSubject from "../Subject";
import dayjs from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

import IconComponent from "../Utility/IconComponent.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Buffer } from "buffer";
import { GlobalContext } from "../GlobalContext";
import { useMutation, useQueryClient } from "react-query";
import beticon from "../../assets/beticon.svg";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import microPhoneIcon from "./microphone.svg";
import LoadingModal from "./LoadingModal";

window.Buffer = Buffer;

const PostMessagePopup = (props) => {
  const queryClient = useQueryClient();
  var link = `${process.env.REACT_APP_LINK}`;
  //var link = "http://localhost:8000/api/";

  const { notsocket } = useContext(GlobalContext);
  const { feedsocket } = useContext(GlobalContext);
  const alphabet = "abcdefghijklmnopqrstuvwxyz";

  const accesstoken = getToken();
  const username = getUsername();
  //const [isPending, setIsPending] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [bal2, setBal2] = useState("");
  const [topic, setTopic] = useState("Puntville"); //subject state for select
  const [topic2, setTopic2] = useState("");
  const { data: topiclist } = useSubject(link + "topic-list/", accesstoken);

  const [bet, setBet] = useState("");
  const [betError, setBetError] = useState("");

  const [body, setBody] = useState("");
  const [bodyError, setBodyError] = useState("");
  const [selectedValidity, setSelectedValidity] = useState("");
  const [PuntvilleBetRef, setPuntvilleBetRef] = useState(createTileRef());

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const [ActivePeriod, setActivePeriod] = React.useState(dayjs(Date.now()));

  const [author, setAuthor] = useState(username);

  const [balanceReq, setBalanceReq] = useState(false);
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const {
    transcript,
    finalTranscript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const microphoneRef = useRef(null);
  const [isSpeechFormatInValid, setIsSpeechFormatInValid] = useState(false);

  async function BalanceHandler() {
    // let UpdateBalance = getBalance();
    let UpdateBalance = 10;
    UpdateBalance -= 1;

    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:Balance": `${UpdateBalance}`,
    });
  }

  function createTileRef() {
    var part1 = username.substring(0, 3);
    var part2 = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    var part3 =
      alphabet[Math.floor(Math.random() * alphabet.length)] +
      alphabet[Math.floor(Math.random() * alphabet.length)] +
      alphabet[Math.floor(Math.random() * alphabet.length)];

    var tmp = part1 + part2 + part3;

    return tmp;
  }

  const handleValidityChange = (item) => {
    setSelectedValidity(item);
  };

  const wordToNumber = {
    zero: 0,
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
    ten: 10,
    // Add more word-to-number conversions as needed
  };

  const extractAmount = (text) => {
    // console.log("Extrac----", text);

    const ExtractedPrice = text.match(/\d+/);

    if (ExtractedPrice !== null) {
      return parseInt(ExtractedPrice[0], 10);
    } else {
      const words = text.toLowerCase().split(/\s+/);
      let amount = null;

      // console.log("words----", words);

      for (const word of words) {
        if (!isNaN(word)) {
          // If the word is already a number, use it as the amount
          amount = parseFloat(word);
          break;
        } else if (wordToNumber.hasOwnProperty(word)) {
          // If the word is in the wordToNumber mapping, use the corresponding number as the amount
          amount = wordToNumber[word];
          break;
        }
      }
      // console.log("amount----", amount);
      return amount;
    }
  };

  //need to make onclickfunction to update message board feed when submit in messageboard component
  const commands = [
    {
      command: "open *",
      callback: (website) => {
        window.open("http://" + website.split(" ").join(""));
      },
    },
    {
      command: "create *",
      callback: (color) => {
        var match = color.match(/\d+/);
        if (match != null) {
          setBody(parseInt(match[0], 10));
          console.log(color.split("that"));
          setBet(color.split("that")[1]);
        }
      },
    },
  ];

  const handleListing = () => {
    console.log("isListening=----start--", listening);
    setIsListening(true);
    // microphoneRef.current.classList.add("listening");
    SpeechRecognition.startListening({
      // continuous: true,
    });
  };
  const stopHandle = () => {
    console.log("stop=----");
    setIsListening(false);
    // microphoneRef.current.classList.remove("listening");
    SpeechRecognition.stopListening();
    // setBet(transcript)

    const price = extractAmount(transcript);

    // console.log("price---", price);
    // console.log("split", text.split("that")[1]);

    if (
      transcript &&
      Number.isInteger(parseInt(price)) &&
      transcript.split("that")[1] !== undefined &&
      transcript.split("that")[1] !== ""
    ) {
      setBody(parseInt(price));
      console.log(transcript.split("that"));
      setBet(transcript.split("that")[1]);
      setIsSpeechFormatInValid(true);
      getTopic();
    }
  };
  const handleReset = () => {
    stopHandle();
    resetTranscript();
  };

  const getTopic = async () => {
    setIsLoading(true);
    // console.log("clicked");
    let betText = transcript.split("that")[1];

    try {
      const accesstoken = getToken();
      const response = await fetch(link + "predict-category/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({
          bet: betText,
        }),
      });

      const json = await response.json();

      setTopic(json?.category);
    } catch (error) {
      console.log("Err", error);
    }
    setIsLoading(false);
  };

  const CapitalizeFirstLetter = (str) => {
    return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  };

  const createBetMutation = useMutation(Endpoints.createBet, {
    onSuccess: (data) => {
      if (data["Notification-text"]) {
        feedsocket.send(
          JSON.stringify({
            user: author,
          })
        );

        notsocket.send(
          JSON.stringify({
            user: author,
          })
        );

        toast(data["Notification-text"]);
      }
    },
    onError: (err) => {
      console.log("there was an error", err);
    },
    onSettled: () => {
      queryClient.invalidateQueries("openBets");
      queryClient.invalidateQueries(["puntvilleBets"]);
      queryClient.invalidateQueries("puntvilleUserCredits");
      props.onClick();
    },
  });

  const handleSumbit = () => {
    // e.preventDefault(); //prevents refreshing page in case of Form
    let betErr = false;
    let bodyErr = false;

    if (topic === "Puntville") {
      toast("Please Select a Category!");
      setSelectedValidity("");
      return;
    }
    //validate itemname field
    if (bet.trim() === "") {
      setBetError("Please Enter bet Details!");
      betErr = true;
    } else {
      setBetError("");
    }

    //validate description field
    if (body.toString().trim() === "") {
      setBodyError("Please Enter bet Price!");
      bodyErr = true;
    } else {
      setBodyError("");
    }

    if (betErr || bodyErr) {
      setSelectedValidity("");
      return;
    } else {
      BalanceHandler();

      // console.log("d");
      createTileRef();

      const currentDate = dayjs();
      var active_period = dayjs();
      if (selectedValidity === "first") {
        active_period = currentDate.add(30, "minute");
      } else if (selectedValidity === "second") {
        active_period = currentDate.add(1, "hour");
      } else if (selectedValidity === "third") {
        active_period = currentDate.add(1, "day");
      } else if (selectedValidity === "custom") {
        active_period = ActivePeriod.toISOString();
      }
      const content = {
        topic,
        body,
        bet,
        author,
        PuntvilleBetRef,
        active_period,
        isPrivate: checked,
      };

      if (topic !== "Puntville") {
        if (dayjs(active_period).isBefore(dayjs(Date.now()))) {
          toast(`Selected time cannot be in the past.`);
        } else {
          createBetMutation.mutate(content);
        }
      }
      setTopic2("");
    }
  };

  useEffect(() => {
    if (selectedValidity && selectedValidity !== "custom") {
      handleSumbit();
    }
  }, [selectedValidity]);

  useEffect(() => {
    if (!listening) {
      stopHandle();
    }
  }, [listening]);

  useEffect(() => {
    if (props.isRcordingAudio) {
      handleListing();
    }
  }, []);

  if (props.isRcordingAudio && !isSpeechFormatInValid) {
    return (
      <div className="font-fredoka bg-white rounded-md p-2">
        <div className="flex flex-col ">
          <div className="mircophone-container">
            {!SpeechRecognition.browserSupportsSpeechRecognition() && (
              <div className="mircophone-container">
                Browser does not Support Speech Recognition.
              </div>
            )}
          </div>

          <div className="flex flex-col items-center justify-start">
            {isListening ? (
              <div className="items-center">
                <div className="flex items-center justify-around">
                  <div>
                    <img
                      style={{ width: 100, height: 50 }}
                      src="https://media.giphy.com/media/znJKJWBSgc7qUgANyQ/giphy.gif"
                      alt=""
                    />
                  </div>
                  <p className={"p-0.5 text-green-700 text-2xl"}>
                    Listening...{" "}
                  </p>
                  <div>
                    <img
                      style={{ width: 100, height: 50 }}
                      src="https://media.giphy.com/media/znJKJWBSgc7qUgANyQ/giphy.gif"
                      alt=""
                    />
                  </div>
                </div>
                <p className={"text-blue-800"}>{transcript} </p>
              </div>
            ) : (
              <>
                {isSpeechFormatInValid ? (
                  <div>
                    <p className={"p-2"}>{transcript} </p>
                    <div className="flex items-center justify-center space-x-2">
                      <div className="w-3 h-3 rounded-full animate-pulse bg-green-400"></div>
                      <div className="w-3 h-3 rounded-full animate-pulse bg-green-400"></div>
                      <div className="w-3 h-3 rounded-full animate-pulse bg-green-400"></div>
                    </div>
                  </div>
                ) : (
                  <div className=" p-3 pb-12">
                    <p>-{transcript}</p>
                    <div className="flex mt-5">
                      <p className={"text-red-500"}>Invalid format : </p>
                      <p className={"text-gray-900"}>Please try again</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="flex flex-col items-center justify-end ">
            <p className={"p-2 text-center text-xs "}>
              Accepted format - I bet{" "}
              <span className={"text-xs"}>
                {"<"}
                BET AMOUNT{"> "}
              </span>
              coins that
              <span className={"text-xs"}>
                {" <"}YOUR BET
                {">"}.
              </span>
            </p>
            <p className={"p-2 text-xs"}>
              Example - I bet <span className={"text-sm"}>5 </span>
              coins that
              <span className={"text-sm"}> it will rain tomorrow</span>
            </p>
            <div className="flex-row items-center justify-center pt-8">
              <button onClick={isListening ? stopHandle : handleListing}>
                {isListening ? (
                  <div className="bg-red-500 rounded-full p-2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-6 h-6"
                    >
                      <path
                        d="M6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V6C5 5.44772 5.44772 5 6 5Z"
                        fill="rgba(255,255,255,1)"
                      ></path>
                    </svg>
                  </div>
                ) : (
                  <div className="bg-[#34dd5a] rounded-full p-2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-6 h-6"
                    >
                      <path
                        d="M11.9998 3C10.3429 3 8.99976 4.34315 8.99976 6V10C8.99976 11.6569 10.3429 13 11.9998 13C13.6566 13 14.9998 11.6569 14.9998 10V6C14.9998 4.34315 13.6566 3 11.9998 3ZM11.9998 1C14.7612 1 16.9998 3.23858 16.9998 6V10C16.9998 12.7614 14.7612 15 11.9998 15C9.23833 15 6.99976 12.7614 6.99976 10V6C6.99976 3.23858 9.23833 1 11.9998 1ZM3.05469 11H5.07065C5.55588 14.3923 8.47329 17 11.9998 17C15.5262 17 18.4436 14.3923 18.9289 11H20.9448C20.4837 15.1716 17.1714 18.4839 12.9998 18.9451V23H10.9998V18.9451C6.82814 18.4839 3.51584 15.1716 3.05469 11Z"
                        fill="rgba(255,255,255,1)"
                      ></path>
                    </svg>
                  </div>
                )}
              </button>
            </div>
            {!isListening && (
              <p className={"p-2 pb-10"}>Tap to record again.</p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative font-fredoka bg-white rounded-md p-2">
      {(isLoading || createBetMutation.isLoading) && <LoadingModal />}

      <div className=" p-3 flex items-center justify-around">
        <div className=" text-xs ">
          <IconComponent subject={topic} />
        </div>

        <div className=" flex flex-col items-center p-2">
          <p className="  py-2 text-lg px-6 border-b border-gray-400">
            {username}
          </p>
          <div className=" flex items-end justify-center text-gray-700  pt-2   ">
            <span className="text-xs pb-1 ">bets &nbsp;</span>{" "}
            <img src={beticon} alt="" className="w-4 h-8 mx-1" />
            <input
              className={` text-gray-700  focus:outline-none  border-b  border-red-100  ${
                bodyError ? " placeholder-red-700 " : ""
              } ${body ? " w-12 text-2xl px-2" : "w-20 text-xs py-2"} `}
              type="text"
              placeholder={bodyError ? `${bodyError}` : "Enter amount "}
              value={body}
              onClick={() => setBodyError("")}
              onChange={(e) => setBody(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="py-2 px-5">
        <select
          className=" w-full focus:outline-none font-fredoka border border-blue-100 bg-white text-bold  shadow-sm rounded-md  px-1 py-2 mt-2"
          value={topic}
          required
          defaultValue={topic}
          onChange={(e) => {
            if (e.target.value !== "Select Category") {
              setTopic(e.target.value);
            } else {
              setTopic2(e.target.value);
            }
          }}
        >
          {topiclist == null && <div>loading</div>}
          {topiclist != null &&
            topiclist.map((topic) => (
              <option value={topic.topicname}>{topic.topicname}</option>
            ))}
        </select>
      </div>

      <div className="p-2 ">
        <textarea
          id="tilepassword"
          className={`focus:outline-none pl-2 pt-2 border border-red-100  font-fredoka w-full bg-gradient-to-b from-[#feffad] to-[#ffeb9a]  rounded-md shadow-lg  p-1 mb-2 h-24 resize-none text-green-700 text-2xl
            ${betError ? "placeholder-red-700 text-xs" : ""} `}
          type="Text"
          onClick={() => setBetError("")}
          onChange={(e) => setBet(e.target.value)}
          value={CapitalizeFirstLetter(bet)}
          required
          placeholder={
            betError ? `${betError}` : "Input Text Here (max 150 Char)"
          }
          rows="1"
          maxLength={150}
        />
      </div>

      <div className="flex items-center px-5 ">
        <p className="text-lg ">Visibility - </p>
        <div className="flex flex-1 items-center justify-center">
          <p className={`${!checked ? "text-black" : "text-gray-400"}`}>
            Public{" "}
          </p>
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
            color="default"
            label="Bet Visibility"
          />
          <p className={`${checked ? "text-black" : "text-gray-400"}`}>
            Private{" "}
          </p>
        </div>
      </div>

      <div class="flex flex-col items-start gap-4 text-gray-700  font-fredoka  text-xs p-5 shadow-md rounded-md">
        <span className="text-lg">Valid until:</span>
        <div className="radio">
          <label className="flex ">
            <input
              type="radio"
              value="first"
              checked={selectedValidity === "first"}
              onChange={(e) => handleValidityChange(e.target.value)}
            />
            <span className="px-2">Expires in 30 Minutes.</span>
          </label>
        </div>
        <div className="radio">
          <label className="flex ">
            <input
              type="radio"
              value="second"
              checked={selectedValidity === "second"}
              onChange={(e) => handleValidityChange(e.target.value)}
            />
            <span className="px-2">Expires in 1 Hour.</span>
          </label>
        </div>
        <div className="radio">
          <label className="flex ">
            <input
              type="radio"
              value="third"
              checked={selectedValidity === "third"}
              onChange={(e) => handleValidityChange(e.target.value)}
            />
            <span className="px-2">Expires in a Day.</span>
          </label>
        </div>
        <div className="radio">
          <label className="flex ">
            <input
              type="radio"
              value="custom"
              checked={selectedValidity === "custom"}
              onClick={() => setIsDatePickerOpen(true)}
              onChange={(e) => handleValidityChange(e.target.value)}
            />
            <span className="px-2">Custom</span>

            <div class=" text-xs rounded-lg font-fredoka ">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  onAccept={handleSumbit}
                  onChange={setActivePeriod}
                  value={ActivePeriod}
                  minDateTime={dayjs(Date.now())}
                  onClose={() => {
                    // console.log("closed=-----------");
                    setIsDatePickerOpen(false);
                  }}
                  open={isDatePickerOpen}
                  renderInput={({
                    ref,
                    inputProps,
                    disabled,
                    onChange,
                    value,
                    ...other
                  }) => (
                    <div ref={ref} className="px-4">
                      <input
                        style={{ display: "" }}
                        value={inputProps?.value}
                        onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                        onChange={onChange}
                        disabled={disabled}
                        className="focus:outline-none border-b border-gray-200"
                        {...inputProps}
                      />
                    </div>
                  )}
                />
              </LocalizationProvider>
            </div>
          </label>
        </div>
      </div>

      {/* <form id="messagesubject" onSubmit={handleSumbit} className="">
        <div className="grid grid-cols-2  text-center ">
          <div className="col-span-2 flex-1 pl-2 text-center">
            <button
              type="submit"
              className="col-span-2 focus:outline-none text-center"
            >
              <div className="focus:border-0 flex-3 ">
                <div className=" w-20 sm:mr-2 mt-2 p-1 button cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm font-fredoka text-center flex items-center justify-center shadow-md  focus:outline-none">
                  Post
                </div>
              </div>
            </button>
          </div>
        </div>
      </form> */}
    </div>
  );
};

export default PostMessagePopup;
