import React from "react";
import "../styles/output.css";
import beticon from "../assets/beticon.svg";
import IconComponent from "./Utility/IconComponent";

const Cards = ({ message }) => {

  return (
    <div id="column layout" className=" ">
      <div className="w-full ">
        <div className="">
          <div
            id="containerbox"
            className="grid grid-cols-2 w-full content-evenly justify-items-stretch text-center "
          >
            {" "}
            <div id="Icon" className="text-center w-full flex text-xs mb-2 ">
              <div id="logo " className=" mx-auto">
                <IconComponent subject={message.topic} />
              </div>
            </div>
            <div
              id="Details"
              className="text-center flex flex-col p-2 text-xs "
            >
              <div id="right box" className="flex-shrink flex flex-col      ">
                <div
                  id="top col for name"
                  className=" flex-shrink py-2 text-lg border-b border-gray-400"
                >
                  {" "}
                  {message.author}{" "}
                </div>
                <div
                  id="middle col for price "
                  className=" flex items-end justify-center text-gray-700  pt-2   "
                >
                  <span className="text-xs pb-1 ">bets &nbsp;</span>{" "}
                  <img src={beticon} alt="" className="w-4 h-8" />
                  <span className="text-2xl">{message.body}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center bg-gradient-to-b from-[#feffad] to-[#ffeb9a]  rounded-md shadow-lg  p-1 mb-2">
            <div
              id="col for per month"
              className="text-green-700 text-2xl   break-normal "
            >
              {message.bet}
            </div>
          </div>
          {/* <p className="flex flex-grow items-center justify-center text-sm mb-2 rounded-lg">
            {message.user === curUser
              ? "You have bet against it!🤞🤞"
              : `${message.user} has bet against it!🤞🤞`}
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Cards;
