import React from "react";

function IconComponent(props) {
  //var link = "https://omnistack.org";
  var link = "https://zaplynimages.s3.eu-west-2.amazonaws.com/";
  return (
    <div>
      <img
        className=" -md "
        src={`${link}${props.subject}.png`}
        alt="displayimage"
        width={100}
        height={100}
      />
      <p className="">{props.subject}</p>
    </div>
  );
}

export default IconComponent;
