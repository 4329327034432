import { useState, useEffect, useContext } from "react";
import fernet from "fernet";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Buffer } from "buffer";
import getUsername from "../../Account_Infomation/Username";
import { GlobalContext } from "../../GlobalContext";
import getToken from "../../Token";
import { useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import ReactPaginate from "react-paginate";
window.Buffer = Buffer;
dayjs.extend(utc);
dayjs.extend(tz);

const Payment = (props) => {
  const queryClient = useQueryClient();
  var link = `${process.env.REACT_APP_LINK}`;
  //var link = "http://localhost:8000/api/";
  var accesstoken = getToken();

  const timeZone = dayjs.tz.guess();

  const [isPaymentTabOpen, setIsPaymentTabOpen] = useState(false);
  const [isTransactionsTabOpen, setIsTransactionsTabOpen] = useState(true);
  const [isPaymentMethodsTabOpen, setIsPaymentMethodsTabOpen] = useState(false);
  const [isRechargeTabOpen, setIsRechargeTabOpen] = useState(false);
  const { notsocket } = useContext(GlobalContext);
  const username = getUsername();
  const [data, setData] = useState([]);
  const [pdata, setPdata] = useState([]);
  const [de, setDe] = useState("");
  const [type, setType] = useState("");
  const [last4, setLast4] = useState("");
  const [exp_month, setExp_month] = useState("");
  const [exp_year, setExp_year] = useState("");
  const [code, setCode] = useState("");
  const [de2, setDe2] = useState("");
  const [am, setAm] = useState("");

  const handleCreateButton = () => {
    let accesstoken = getToken();

    //modal popup
    fetch(link + "save-card/", {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify({ user: props.currentusername }),
    })
      .then((response) => response.json())
      .then((json) => (window.location.href = json["url"]));
  };

  const removeItem = (index) => {
    setData([...data.slice(0, index - 1), ...data.slice(index)]);
  };

  const handleListPaymentButton = () => {
    setIsTransactionsTabOpen(true);
    setIsPaymentMethodsTabOpen(false);
    setIsRechargeTabOpen(false);
  };

  // transaction details

  const [page, setPage] = useState(0);
  const getTransactions = async (pageNo) => {
    try {
      const response = await fetch(link + "fetch-transactions/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({
          user: props.currentusername,
          page: pageNo + 1,
        }),
      });

      const json = await response.json();
      if (json) {
        return json;
      }
    } catch (error) {
      console.log("");
    }
  };

  const {
    isLoading: isTransactionsLoading,
    data: transactionsData,
    isError: isTransactionsError,
    error: transactionsErr,
    isFetching: isTransactionsFetching,
    isPreviousData: isTransactionsBets,
  } = useQuery(["transactionsData", { page }], () => getTransactions(page), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const handlePageChange = (e) => {
    setPage(e.selected);
  };

  const submitHandler3 = () => {
    setIsPaymentTabOpen(false);
    setIsRechargeTabOpen(false);
    let accesstoken = getToken();
    fetch(link + "payment-records/", {
      method: "POST",
      body: JSON.stringify({ user: props.currentusername, Credits: "0" }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + accesstoken,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);
        var count = Object.keys(json[0]).length;
        // console.log(count);
        setPdata([]);
        for (let i = 0; i < count; i++) {
          setPdata((pdata) => [
            ...pdata,
            {
              id: i,
              Date: json[0][i]["created_at"],
              amount: String(json[0][i]["amount"]),
              Status: String(json[0][i]["is_success"]),
            },
          ]);
        }
      });
  };

  // payment methods details
  const handlePaymentMethodsclick = () => {
    setIsPaymentMethodsTabOpen(true);
    setIsTransactionsTabOpen(false);
    setIsRechargeTabOpen(false);
  };

  const getPaymentMethods = async () => {
    try {
      const response = await fetch(link + "get-payment-methods/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({ user: props.currentusername }),
      });

      const json = await response.json();
      if (json) {
        return json;
      }
    } catch (error) {
      console.log("");
    }
  };

  const {
    isLoading: isPaymentMethodsLoading,
    data: paymentMethodsData,
    isError: isPaymentMethodsError,
    error: paymentMethodsErr,
  } = useQuery("paymentMethodsData", getPaymentMethods);

  const rechargeHandler = () => {
    // console.log("ff");
    setAm("");
  };

  const handleRemovePaymentMethod = (data) => {
    removeItem(data.id);
    let accesstoken = getToken();
    if (data.sort_code === "undefined") {
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
      });

      fetch(link + "delete-payment-method/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(props.currentusername),
          type: token.encode(data.type),
          last4: token.encode(data.last4),
          exp_month: token.encode(data.exp_month),
          exp_year: token.encode(data.exp_year),
          sort_code: token.encode("null"),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      })
        .then((response) => response.json())
        .then(async (json) => {
          toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
          notsocket.send(
            JSON.stringify({
              user: username,
            })
          );
          await queryClient.invalidateQueries("paymentMethodsData");
        });
    } else {
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
      });
      fetch(link + "delete-payment-method/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(props.currentusername),
          type: token.encode(data.type),
          last4: token.encode(data.last4),
          exp_month: token.encode("null"),
          exp_year: token.encode("null"),
          sort_code: token.encode(data.sort_code),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      })
        .then((response) => response.json())
        .then(async (json) => {
          toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
          notsocket.send(
            JSON.stringify({
              user: username,
            })
          );
          await queryClient.invalidateQueries("paymentMethodsData");
        });
    }
  };

  const handleSetToDefault = (data) => {
    let accesstoken = getToken();
    if (data.sort_code === "undefined") {
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
      });
      fetch(link + "set-default-payment/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(props.currentusername),
          type: token.encode(data.type),
          last4: token.encode(data.last4),
          exp_month: token.encode(data.exp_month),
          exp_year: token.encode(data.exp_year),
          sort_code: token.encode("null"),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      })
        .then((response) => response.json())
        .then(async (json) => {
          toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
          notsocket.send(
            JSON.stringify({
              user: username,
            })
          );
          await queryClient.invalidateQueries("paymentMethodsData");
        });

      setDe("Default");
      setType(data.type);
      setLast4(data.last4);
      setCode("null");
      setExp_month(data.exp_month);
      setExp_year(data.exp_year);
    } else {
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
      });
      fetch(link + "set-default-payment/", {
        method: "POST",
        body: JSON.stringify({
          user: token.encode(props.currentusername),
          type: token.encode(data.type),
          last4: token.encode(data.last4),
          exp_month: token.encode("null"),
          exp_year: token.encode("null"),
          sort_code: token.encode(data.sort_code),
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      })
        .then((response) => response.json())
        .then(async (json) => {
          toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
          notsocket.send(
            JSON.stringify({
              user: username,
            })
          );
          await queryClient.invalidateQueries("paymentMethodsData");
        });
      setDe("Default");
      setType(data.type);
      setLast4(data.last4);
      setCode(data.sort_code);
      setExp_month("null");
      setExp_year("null");
    }
  };

  // Recharge tab

  const handleRechargeTabClick = () => {
    setIsRechargeTabOpen(true);
    setIsPaymentMethodsTabOpen(false);
    setIsTransactionsTabOpen(false);
  };

  const handleRecharge = async () => {
    let accesstoken = getToken();
    // toast("Updating Coins...");
    const response = await fetch(link + "save-card-payment-intent/", {
      method: "POST",
      body: JSON.stringify({
        user: props.currentusername,
        amount: am,
        default: "1",
        last4: "null",
        exp_month: "null",
        exp_year: "null",
        sort_code: "null",
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + accesstoken,
      },
    });

    const json = await response.json();
    if (json) {
      toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
      notsocket.send(
        JSON.stringify({
          user: username,
        })
      );
      await queryClient.invalidateQueries("transactionsData");
    }
    rechargeHandler();
  };

  useEffect(() => {
    if (paymentMethodsData) {
      // console.log(json);
      var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
      var token = new fernet.Token({
        secret: secret,
        token: String(paymentMethodsData),
        ttl: 0,
      });
      // console.log(token, "ss");
      var result = token.decode();
      const resObj = JSON.parse(result);
      // console.log(json);
      var count = Object.keys(resObj).length;
      setData([]);
      for (let i = 1; i < count + 1; i++) {
        setData((data) => [
          ...data,
          {
            id: i,
            type: resObj[i]["type"],
            last4: String(resObj[i]["last4"]),
            exp_month: String(resObj[i]["exp_month"]),
            exp_year: String(resObj[i]["exp_year"]),
            sort_code: String(resObj[i]["sort_code"]),
            default: resObj[i]["default"],
          },
        ]);
      }
    }
  }, [paymentMethodsData]);

  return (
    <div className="pt-20 md:pt-28 pb-40 flex flex-col items-center  md:max-w-xl ml-2 mr-2 md:mx-auto ">
      <ToastContainer autoClose={2500} />
      <div className="grid grid-cols-3  place-content-center place-items-center w-full my-2 text-lg font-semibold uppercase tracking-wide text-gray-700 mb-2">
        <span></span>
        <p className="col-span-1 w-full text-center mt-2 ">Payments</p>
      </div>
      <div className="grid grid-cols-3 gap-1 place-content-center place-items-center mt-2 mb-2  w-full ">
        <button
          onClick={handleListPaymentButton}
          className={`col-span-1 w-full text-center  text-xs md:text-sm  px-2 p-1 focus:outline-none cursor-pointer 
        ${
          isTransactionsTabOpen
            ? "cardBtn px-4 py-1 shadow-sm text-xs text-[#000F99] "
            : "border-[0.5px] border-[#000F99]  px-4 py-1 shadow-sm rounded-full text-xs text-[#000F99] "
        }`}
        >
          Payment History
        </button>

        <button
          onClick={handlePaymentMethodsclick}
          className={` col-span-1 w-full text-center text-xs md:text-sm  px-2 p-1 focus:outline-none cursor-pointer 
          ${
            isPaymentMethodsTabOpen
              ? "cardBtn px-4 py-1 shadow-sm text-xs text-[#000F99] "
              : "border-[0.5px] border-[#000F99]  px-4 py-1 shadow-sm rounded-full text-xs text-[#000F99] "
          }`}
        >
          Payment Methods
        </button>

        <button
          onClick={handleRechargeTabClick}
          className={`col-span-1 w-full text-center text-xs md:text-sm  px-4 p-1 focus:outline-none cursor-pointer 
          ${
            isRechargeTabOpen
              ? "cardBtn px-4 py-1 shadow-sm text-xs text-[#000F99] "
              : "border-[0.5px] border-[#000F99]  px-4 py-1 shadow-sm rounded-full text-xs text-[#000F99] "
          }`}
        >
          Recharge
        </button>
      </div>

      {isTransactionsTabOpen && (
        <div id="column layout" className="flex ">
          {isTransactionsLoading && <p>Loading..</p>}
          {transactionsData != null && (
            //   /* <p>Payment History</p> */

            <div className="flex flex-col gap-5">
              <div className=" py-3 px-2 w-full ">
                <ReactPaginate
                  previousLabel={
                    <span className="flex items-center justify-center w-6 h-6 border rounded-md shadow-md  border-blue-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4"
                      >
                        <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
                      </svg>
                    </span>
                  }
                  nextLabel={
                    <span className="flex items-center justify-center w-6 h-6 border rounded-md shadow-md  border-blue-300">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="w-4"
                      >
                        <path d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z"></path>
                      </svg>
                    </span>
                  }
                  pageClassName=" flex items-center justify-center w-6 h-6 text-xs border rounded shadow-md  border-blue-300 md:hover:bg-blue-100 mx-1"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageRangeDisplayed={3}
                  pageCount={
                    transactionsData?.find((item) =>
                      item.hasOwnProperty("pages")
                    ).pages
                  }
                  marginPagesDisplayed={1}
                  onPageChange={handlePageChange}
                  containerClassName="flex items-center justify-center mt-2"
                  activeClassName="bg-blue-400 text-white"
                  forcePage={page}
                />
              </div>
              <div className="container px-2 py-2 mx-auto sm:p-4 ">
                <div className="overflow-x-auto">
                  <table className="min-w-full  text-xs   ">
                    <thead className=" ">
                      <tr className="text-center align-top ">
                        <th className="p-1 md:p-3  text-gray-600  ">
                          Created At
                        </th>
                        <th className="pl-0.5 py-1 pr-1 text-gray-600  md:p-3 ">
                          Transaction Id
                        </th>
                        <th className="p-1 md:p-3  text-gray-600  ">Amount</th>
                        <th className="p-1 md:p-3  text-gray-600  ">Mode</th>
                        <th className="p-1 md:p-3  text-gray-600  ">
                          Puntville BetRef
                        </th>
                        <th className="p-1 md:p-3  text-gray-600 ">Status</th>
                      </tr>
                    </thead>
                    {transactionsData[0].map((item) => (
                      <tbody key={item.created_at}>
                        <tr className="border-b border-opacity-20 border-gray-700 text-xxs md:text-xs ">
                          <td className="p-1 md:p-3">
                            <p>
                              {dayjs
                                .utc(item.created_at)
                                .tz(timeZone)
                                .format("YYYY-MM-DD HH:mm:ss A")}
                            </p>
                          </td>

                          <td className="p-1 md:p-3 text-center">
                            {item.transaction_id === null && (
                              <p className="text-center">-</p>
                            )}
                            {item.transaction_id && (
                              <p>{item.transaction_id}</p>
                            )}
                          </td>
                          <td className="p-1 md:p-3 text-center">
                            <p>
                              {item.currency === "null" && (
                                <span className="mr-0.25"></span>
                              )}
                              {item.currency === "GBP" && (
                                <span className="mr-0.25">£</span>
                              )}
                              {item.currency === "EUR" && (
                                <span className="mr-0.25">€</span>
                              )}
                              {item.currency === "USD" && (
                                <span className="mr-0.25">$</span>
                              )}{" "}
                              {item.amount.toFixed(1)}
                            </p>
                          </td>
                          <td className="p-1 md:p-5">
                            <p>{item.mode}</p>
                          </td>
                          <td className="p-1 md:p-5">
                            <p>{item.PuntvilleBetRef}</p>
                          </td>
                          <td className="p-2 md:p-3 text-right">
                            <p className="font-semibold  text-gray-200">
                              {item.is_success ? (
                                <span className="bg-green-400 rounded-full px-4 py-1 ">
                                  Success
                                </span>
                              ) : (
                                <span className="bg-red-400 rounded-full px-4 py-1 ">
                                  Failed
                                </span>
                              )}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {isPaymentMethodsTabOpen && (
        <div className=" flex flex-col items-center my-10 max-w-lg mx-auto">
          {isTransactionsLoading ? (
            <p>Loading..</p>
          ) : (
            <button
              onClick={handleCreateButton}
              className="flex items-center  w-40   p-2 mr-3 m-1 button h-8  -full shadow-sm focus:outline-none cursor-pointer cardBtn text-sm "
            >
              <p>Add Payment Method</p>
            </button>
          )}

          {data.map((data) => (
            <div
              key={data.id}
              className="flex flex-col items-center border-t border-gray-50  my-5 rounded-md shadow-lg py-5 px-10 w-full"
            >
              <p>
                {data.type} ***{data.last4}{" "}
                {data.exp_year === "undefined" ? "" : data.exp_year + "/"}
                {data.exp_month === "undefined" ? "" : data.exp_month}{" "}
                {data.sort_code === "undefined"
                  ? ""
                  : "Sort Code: " + data.sort_code + " "}{" "}
              </p>
              <div className="flex items-center justify-around gap-4 pt-2">
                <button
                  className=" px-4 py-1 hadow-sm focus:outline-none cursor-pointer cardBtn   "
                  onClick={() => handleRemovePaymentMethod(data)}
                >
                  Remove
                </button>
                <button
                  className="   px-4 py-1 shadow-sm focus:outline-none cursor-pointer cardBtn   "
                  onClick={() => handleSetToDefault(data)}
                >
                  {data.default === "Default" ? "Default" : "Set As Default"}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {isRechargeTabOpen && (
        <div>
          <div className="flex flex-col text-center mt-2 gap-5">
            <p
              style={{
                fontSize: 15,
                color: "gray",
                fontWeight: "semibold",
                fontFamily: "Fredoka",
              }}
            >
              1 GBP = 10 Coins (Amount will be deducted using your default
              payment method)
            </p>

            {/* 50 coins */}
            <div className="flex flex-col border-t border-gray-50 items-center justify-between p-5 rounded-md shadow-lg w-1/2 mx-auto">
              <p className="pb-4 text-lg">Buy 50 coins for £5 </p>
              <button
                onClick={() => {
                  setAm(5);
                  handleRecharge();
                }}
                className="cardBtn px-4 py-1"
              >
                Recharge Now
              </button>
            </div>

            {/* 100coins  */}
            <div className="flex flex-col items-center border-t border-gray-50 justify-between p-5 rounded-md shadow-lg w-1/2 mx-auto">
              <p className="pb-4 text-lg">Buy 100 coins for £10 </p>
              <button
                onClick={() => {
                  setAm(10);
                  handleRecharge();
                }}
                className="cardBtn px-4 py-1"
              >
                Recharge Now
              </button>
            </div>

            {/* 200 coins */}
            <div className="flex flex-col border-t border-gray-50 items-center justify-between p-5 rounded-md shadow-lg w-1/2 mx-auto">
              <p className="pb-4 text-lg">Buy 200 coins for £20 </p>
              <button
                onClick={() => {
                  setAm(20);
                  handleRecharge();
                }}
                className="cardBtn px-4 py-1"
              >
                Recharge Now
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;
