import React, { createContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import getUsername from "./Account_Infomation/Username";
// Initial state
// const initialState = [];
// transactions: []

// Create context
export const GlobalContext = createContext();

// Reducer
// export const AppReducer = (state, action) => {
//   switch(action.type) {
//     case 'DELETE_TRANSACTION':
//       return {
//         ...state,
//         transactions: state.Shortlist.filter(transaction => transaction.id !== action.payload)
//       }
//     case 'ADD_TRANSACTION':
//       return {
//         ...state,
//         transactions: [action.payload, ...state.transactions]
//       }
//     default:
//       return state;
//   }
// }

// Provider component

export const GlobalProvider = ({ children }) => {
  //Homepage filter
  const [fetchedMessages, setFetchedMessages] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [showFilteredData, setShowFilteredData] = useState(false);

  //Manage-section filter
  const [usermessagefeed, setUsermessagefeed] = useState("Open");
  const [openPosts, setOpenPosts] = useState(null);
  const [lockedPosts, setLockedPosts] = useState(null);
  const [archivedPosts, setArchivedPosts] = useState(null);
  const [postsFilteredData, setPostsFilteredData] = useState(null);
  const [selectedCategoryFilterOptions, setSelectedCategoryFilterOptions] =
    useState([]);
  const [selectedTopicFilterOptions, setSelectedTopicFilterOptions] = useState(
    []
  );

  const [selectedHomeFilterTopicOptions, setSelectedHomeFilterTopicOptions] =
    useState([]);
  const [selectedConnectionOptions, setSelectedConnectionOptions] = useState(
    []
  );

  const [selectedButtonFilters, setSelectedButtonFilters] = useState([]);

  const [chatRef, setChatRef] = useState([]);
  // userAvatar
  const [userAvatarArray, setUserAvatarArray] = useState([
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/puntville_avatar_1.svg",
      id: 1,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/puntville_avatar_2.svg",
      id: 2,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/puntville_avatar_3.svg",
      id: 3,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/puntville_avatar_4.svg",
      id: 4,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/puntville_avatar_5.svg",
      id: 5,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/puntville_avatar_6.svg",
      id: 6,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/puntville_avatar_8.svg",
      id: 8,
    },
    {
      url: "https://zaplynimages.s3.eu-west-2.amazonaws.com/puntville_avatar_9.svg",
      id: 9,
    },
  ]);
  const [userAvatar, setUserAvatar] = useState(
    "https://zaplynimages.s3.eu-west-2.amazonaws.com/puntville_placeholder.svg"
  );
  const [notsocket, setNotsocket] = useState([]);
  const [feedsocket, setFeedsocket] = useState([]);
  const [archivedPages, setArchivedPages] = useState(1);
  // const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions
  // function deleteTransaction(id) {
  //   dispatch({
  //     type: 'DELETE_TRANSACTION',
  //     payload: id
  //   });
  // }

  // function addTransaction(transaction) {
  //   dispatch({
  //     type: 'ADD_TRANSACTION',
  //     payload: transaction
  //   });
  // }
  useEffect(() => {
    var ws_scheme =
      window.location.protocol == "https:"
        ? "wss://omnistack.org"
        : "wss://omnistack.org";
    const sock = new W3CWebSocket(
      ws_scheme + "/ws2/fetch-notifications-puntville/"
    );

    sock.onopen = () => {
      // console.log("notifications WebSocket Client Connected");
      sock.send(
        JSON.stringify({
          user: getUsername(),
        })
      );
    };

    setNotsocket(sock);

    const feedsock = new W3CWebSocket(
      ws_scheme + "/ws2/fetch-puntville-feed/" + getUsername() + "/"
    );
    feedsock.onopen = () => {
      // console.log("feed WebSocket Client Connected");
      feedsock.send(
        JSON.stringify({
          user: getUsername(),
        })
      );
    };
    setFeedsocket(feedsock);
  }, []);
  return (
    <GlobalContext.Provider
      value={{
        // transactions: state.transactions,
        // deleteTransaction,
        // addTransaction
        // Shortlist,
        // Updatelist,
        fetchedMessages,
        userAvatarArray,
        userAvatar,
        setUserAvatar,
        notsocket,
        feedsocket,
        setFetchedMessages,
        filteredData,
        setFilteredData,
        showFilteredData,
        setShowFilteredData,
        chatRef,
        setChatRef,
        openPosts,
        setOpenPosts,
        lockedPosts,
        setLockedPosts,
        archivedPosts,
        setArchivedPosts,
        usermessagefeed,
        setUsermessagefeed,
        postsFilteredData,
        archivedPages,
        setArchivedPages,
        setPostsFilteredData,
        selectedCategoryFilterOptions,
        setSelectedCategoryFilterOptions,
        selectedTopicFilterOptions,
        setSelectedTopicFilterOptions,
        selectedHomeFilterTopicOptions,
        setSelectedHomeFilterTopicOptions,
        selectedConnectionOptions,
        setSelectedConnectionOptions,
        selectedButtonFilters,
        setSelectedButtonFilters,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
