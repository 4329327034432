import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import MessageBoard from "./MessageBoard";

import { Auth } from "aws-amplify";
import { useEffect } from "react";
import getToken from "../Token";
import getUsername from "../Account_Infomation/Username";
import useFetch from "../useFetch";
import useSubject from "../Subject";
import useRefreshmessage from "../Refreshmessage";

const Messages = (props) => {
  var link = `${process.env.REACT_APP_LINK}`;
  //var link = "http://localhost:8000/api/";

  const [posting, setPosting] = useState(false);
  const accesstoken = getToken();
  const username = getUsername();
  //const [isPending, setIsPending] = useState(false);

  const [subject, setSubject] = useState(null); //subject state for select
  const [tileusername, settileusername] = useState("");
  const [tilepassword, settilepassword] = useState("");

  //const {data: subjectlist} = useSubject(/*'https://omnistack.org/puntville/topic-list/'*/'http://localhost:8000/api/topic-list/', accesstoken).then(setSubject(Object.keys(subjectlist)[0]));
  const { data: subjectlist } = useSubject(link + "topic-list/", accesstoken);

  const [body, setBody] = useState("");

  const [author, setAuthor] = useState(username);

  const handleSumbit = (e) => {
    e.preventDefault(); //prevents refreshing page
    const content = { subject, body, author, tilepassword, tileusername };
    props.onClick(content); //to update feed
    //setIsPending(true);

    fetch(link + "message-create/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify(content),
    }).then(() => {
      //setIsPending(false);
      //history.push('/messages');
    });
    //global.messagelist.push(content);
    document.getElementById("messagesubject").reset();
    setBody("");
  };

  return (
    <div class="bg-gray-400 flex-row fixed bottom-0 w-full py-2 ">
      {/*}
        <Link to="">
        <button class = "ml-4 mt-4 button w-48 h-8  -sm shadow-md focus:outline-none cursor-pointer hover:bg-red-800 hover:text-white bg-green-400 text-sm  flex-shrink"> 
        Back</button>
        </Link>
        */}

      <div className="flex flex-row ">
        {/*<div className="flex-0.5 px-4"> 
                    {global.username}
                </div>
                */}
        <div className="flex-1 ">
          <form id="messagesubject" onSubmit={handleSumbit}>
            <div className="flex flex-col sm:flex-row text-center">
              <div className="flex-3 pl-2 text-center">
                {/*<label>Area of Interest: </label>*/}
                <select
                  class="  focus:outline-none"
                  required
                  //value={Object.keys(subject)[0]}
                  placeholder="Select"
                  onChange={(e) => setSubject(e.target.value)}
                >
                  {subjectlist == null && <div>loading</div>}
                  <option></option>
                  {subjectlist != null &&
                    subjectlist.map((topic) => (
                      <option value={topic.topicname}>{topic.topicname}</option>
                    ))}
                </select>
              </div>

              <div className="flex-3 px-4 flex-grow ">
                {/*<label className="align-top">Message body: </label>*/}
                <textarea
                  id="messagecontent"
                  className="focus:outline-none pl-2  w-full   mt-1"
                  required
                  placeholder="Price per month"
                  rows="2"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                ></textarea>
              </div>

              <button
                className=" focus:outline-none"
                onClick={() => {
                  setPosting(!posting);
                }}
              >
                <div className="focus:border-0 flex-3 ">
                  <div className="w-20 sm:w-20 sm:mr-2 button cursor-pointer text-center hover:text-white flex items-center justify-center shadow-md  hover:bg-red-800 focus:outline-none  -sm h-8 bg-green-400">
                    Post
                  </div>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Messages;
