import React, {
  createContext,
  useReducer,
  useState,
  useEffect,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import Select from "react-select";
import { Longlist } from "./Filters.js";
import {
  GlobalContext,
  GlobalProvider,
  Shortlist,
  Updatelist,
} from "./GlobalContext.js";
import "../styles/output.css";

import getToken from "./Token.js";
import useFetch from "./useFetch.js";
import { Link } from "react-router-dom";
import Messages from "./Pages/Messages.js";
import getUsername from "./Account_Infomation/Username.js";
// import getBalance from "./Account_Infomation/Balance.js";
import { Auth } from "aws-amplify";
import Modal from "react-modal";
import ErrorMessagePopup from "./Popups/ErrorMessage.js";
import { useQueryClient } from "react-query";

const Destination_tab = ({ setIsFilterOpen }) => {
  const queryClient = useQueryClient();
  const fetchedMessages = queryClient.getQueryData("puntvilleBets");
  const { setFilteredData, setSelectedButtonFilters } =
    useContext(GlobalContext);
  const {
    setShowFilteredData,
    selectedHomeFilterTopicOptions,
    setSelectedHomeFilterTopicOptions,
    selectedConnectionOptions,
    setSelectedConnectionOptions,
  } = useContext(GlobalContext);
  const [options, setOptions] = useState([]);
  const [connectionOptions, setConnectionOptions] = useState([
    { id: 1, connection: "1st", value: "first" },
    { id: 2, connection: "2nd", value: "second" },
    { id: 3, connection: "3rd+", value: "thirdplus" },
  ]);
  const [selectedOptions, setSelectedOptions] = useState(
    selectedHomeFilterTopicOptions
  );
  const [selectedConnectionOptionsLocal, setSelectedConnectionOptionsLocal] =
    useState(selectedConnectionOptions);

  const navigate = useNavigate();

  useEffect(() => {
    //let [options1, setOptions1] = useState([]);//save data
    let accesstoken = getToken();
    // console.log(accesstoken);
    //source label
    fetch(`${process.env.REACT_APP_LINK}topic-list/`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accesstoken,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        setOptions(result.slice(1));
        // console.log("fetched topic result ", result.slice(1));
      });
  }, []);

  const handleOptionChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    // console.log("Selectedoption", selectedOptions);
    if (selectedOptions.length === 0) {
      setShowFilteredData(false);
    }
  };

  const handleConnectionChange = (selectedConnectionOptions) => {
    setSelectedConnectionOptionsLocal(selectedConnectionOptions);
    // console.log("Selectedoption", selectedConnectionOptions);
    if (selectedConnectionOptions.length === 0) {
      setShowFilteredData(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Filter the data based on selected options and update the global state with the filtered data
    setSelectedHomeFilterTopicOptions(selectedOptions);
    setSelectedConnectionOptions(selectedConnectionOptionsLocal);
    setSelectedButtonFilters([]);

    // console.log("filteredData", filteredData);
    setShowFilteredData(true);
  };

  const handleReset = () => {
    setShowFilteredData(false);
    setSelectedHomeFilterTopicOptions([]);
    setSelectedOptions([]);
  };

  return (
    <div className="absolute z-10 right-0 bg-white rounded-lg ">
      <div className="px-4 leading-tight mb-4 border border-red-100 rounded-lg p-2 text-xs justify-between items-center flex-col">
        <h2 className=" text-lg text-black pb-1 w-40">Area of Interest</h2>

        <form onSubmit={handleSubmit} className="flex flex-col space-y-3">
          <div className="">
            <p className="font-fredoka text-xs ml-1">Topics(s)</p>
            <Select
              className="w-56"
              value={selectedOptions}
              onChange={handleOptionChange}
              options={options}
              placeholder={"select"}
              isMulti
              closeMenuOnSelect={false}
              autosize={false}
              getOptionValue={(option) => option.topicname} //value is what it is
              getOptionLabel={(option) => option.topicname} //label is what you see
            />
          </div>

          <div className="">
            <p className="font-fredoka ml-1 text-xs">Connection(s)</p>
            <Select
              className="w-40"
              value={selectedConnectionOptionsLocal}
              onChange={handleConnectionChange}
              options={connectionOptions}
              placeholder={"Select"}
              isMulti
              closeMenuOnSelect={false}
              autosize={false}
              getOptionValue={(option) => option.connection} //value is what it is
              getOptionLabel={(option) => option.connection} //label is what you see
            />
          </div>

          <div className="flex items-center gap-2">
            <p
              onClick={handleReset}
              className=" h-8 shadow-md text-center  focus:outline-none cursor-pointer cardBtn text-xl  w-full md:w-32"
            >
              Reset
            </p>
            <button
              type="submit"
              className=" h-8 shadow-md  focus:outline-none cursor-pointer cardBtn text-xl  w-full md:w-32"
            >
              Filter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Destination_tab;
