import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Cards from "../Cards";
import getToken from "../Token";
import getUsername from "../Account_Infomation/Username";
import { Text } from "react-native";
import add_red from "../add_red.svg";
import add_blue from "../add_blue.svg";
import folder_red from "../folder_red.svg";
import folder_blue from "../folder_blue.svg";
import search_red from "../search_red.svg";
import search_blue from "../search_blue.svg";
import home_red from "../home_red.svg";
import home_blue from "../home_blue.svg";
import puntville from "../puntville.svg";
import { Link } from "react-router-dom";

const Share = () => {
  const { slug } = useParams();
  const [data, setData] = useState({});
  const [homeimage, setHomeImage] = useState(home_blue);
  const [addimage, setAddImage] = useState(add_blue);
  const [folderimage, setFolderImage] = useState(folder_blue);
  const [searchimage, setSearchImage] = useState(search_blue);
  const [currentfeed, setCurrentfeed] = useState("");
  const [bettaken, setBettaken] = useState("no");
  const HandleClick = () => {
    window.location.href = "https://puntville.com";
  };
  const HandleClick2 = () => {
    let accesstoken = getToken();
    fetch(`${process.env.REACT_APP_LINK}` + "savedpuntville-create2/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify({
        user: getUsername(),

        PuntvilleBetRef: slug,
      }),
    });
  };
  var link = `${process.env.REACT_APP_LINK}`;
  useEffect(() => {
    fetch(link + "share-tile/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        PuntvilleBetRef: slug,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setBettaken(json["bet-taken"]);
        setData(json);
      });
  }, []);
  const customStyles = {
    overlay: {
      background: "rgba(52, 52, 52, 0.8)",
      overflow: "scroll",
    },

    content: {
      top: "40%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFFFFF",
      marginTop: "10%",
    },
  };

  return (
    <React.Fragment>
      <div class="z-40 font-fredoka pb-5 fixed w-full pb-2 shadow-sm border-b border-green-100 bg-gradient-to-r from-green-100 to-blue-100 ">
        <div>
          <button
            onClick={HandleClick}
            className={"  border:none items-center pt-6 pl-3"}
          >
            <img src={puntville} alt="my image" className="w-24 md:w-40" />
          </button>
          <div class="float-right w-2/3 pt-2 flex justify-evenly ">
            <p
              className={
                "pt-2 align-center cursor-pointer  text-black     text-xs "
              }
            >
              <Link to="/home">
                <img
                  src={homeimage}
                  alt="my image"
                  className="md:h-8 md:w-8 h-6 w-6"
                />
                Home
              </Link>
            </p>
            <p
              className="pt-2 align-center cursor-pointer  hover:text-black  text-xs "
              onClick={HandleClick}
            >
              <img
                src={addimage}
                alt="my image"
                className="md:h-8 md:w-8 h-6 w-6"
              />
              Add New
            </p>
            <p
              className={
                "pt-2 align-center cursor-pointer  text-black     text-xs "
              }
            >
              <Link to="/my-posts">
                <img
                  src={folderimage}
                  alt="my image"
                  className="md:h-8 md:w-8 h-6 w-6"
                />
                Manage
              </Link>
            </p>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div className="mt-4 p-4 justify-center text-center font-fredoka text-4xl text-gray-700">
        Welcome to Puntville!
      </div>
      <div
        id="column layout"
        className="flex flex-row font-fredoka justify-center items-center "
      >
        <div className="my-2 mx-1 p-4 border max-w-md border-red-100 rounded-lg shadow-md justify-items-center">
          {bettaken === "deleted" && (
            <div>
              <p className="text-xs mx-2 p-2">
                We cannot seem to locate this tile. It's probably because the
                author deleted it.
              </p>
              <button className="flex-nowrap flex-shrink  button  w-3/2 md:mx-28 mx-24 p-2  shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-lg  ">
                <Link to="/home">Find more bets here</Link>
              </button>
            </div>
          )}
          {bettaken !== "deleted" && <Cards message={data} />}
          <div className="justify-start text-md m-1"> You think otherwise?</div>
          <div className="  grid grid-cols-2  ">
            <div className="flex-shrink-0">
              {getUsername() === "stupidbug" && bettaken === "no" && (
                <button
                  className="flex-nowrap flex-shrink  button w-full mr-4 p-2  shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-lg  "
                  onClick={HandleClick}
                >
                  Get this bet!
                </button>
              )}
              {bettaken === "no" &&
                bettaken !== "deleted" &&
                getUsername() !== "stupidbug" && (
                  <button
                    className="flex-nowrap flex-shrink  button w-full mr-4 p-2  shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-lg  "
                    onClick={HandleClick2}
                  >
                    <Link to="/my-posts/locked">Get this bet!</Link>
                  </button>
                )}
              {bettaken === "yes" && (
                <div>
                  <p className="text-xs ml-1">Bet is already taken</p>
                  <button className="flex-nowrap flex-shrink  button w-3/2 mr-4 p-2  shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-lg  ">
                    <Link to="/home">You can find new bets here!</Link>
                  </button>
                </div>
              )}
              {bettaken === "expired" && (
                <div>
                  <p className="text-xs ml-1">Oh No! This bet expired!</p>
                  <button className="flex-nowrap flex-shrink  button w-3/2 mr-4 p-2  shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-lg  ">
                    <Link to="/home">You can find new bets here!</Link>
                  </button>
                </div>
              )}
            </div>
            {bettaken !== "deleted" && (
              <div className="mr-4 ml-4 text-sm">
                <p>
                  {" "}
                  Expires at: <Text>{data["active_period"]}</Text>
                </p>
              </div>
            )}
          </div>
          <div
            className={"  pt-4 pl-4 align-left pb-5 fixed w-48 pb-2  "}
          ></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Share;
