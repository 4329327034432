import React from "react";
import getToken from "./Token";

import getUsername from "./Account_Infomation/Username";
import "../styles/output.css";
import { Auth, Hub } from "aws-amplify";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import Destination_tab from "./Destination_tab";
import Feed_elements from "./Feed_elements";

class Card_popout2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      items: "",
      filterlist: "",
    };

    this.toggleBox = this.toggleBox.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  toggleBox() {
    const { opened } = this.state;
    this.componentDidMount();
    this.setState({
      opened: !opened,
    });
  }

  handlePaymentMethodsButtonClick = () => {
    this.props.clickpayments("payment");
  };

  handleSupportButtonClick = () => {
    this.props.clickpayments("supportpage");
  };

  handleAccountButtonClick = () => {
    this.props.clickpayments("accountpage");
  };

  handleCashoutButtonClick = () => {
    this.props.clickpayments("cashoutpage");
  };
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      const { opened } = this.state;
      this.setState({
        opened: false,
      });
    }
  }
  
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({
      accesstoken: getToken(),
    });
    const { accesstoken } = this.state;
    // console.log(accesstoken);
    // fetch("https://omnistack.org/api/sponge/credits/", {
    //   method: "POST",
    //   headers: {
    //     "Content-type": "application/json; charset=UTF-8",
    //     Authorization: "Bearer " + accesstoken,
    //   },
    //   body: JSON.stringify({
    //     user: getUsername(),
    //   }),
    // })
    //   .then((res) => res.json())
    //   .then((json) => {
    //     this.setState({
    //       items: json,
    //     });
    //   });
  }
  render() {
    var { style, children } = this.props;
    const { opened } = this.state;
    const { items } = this.state;
    return (
      <div ref={this.wrapperRef}>
        <button className="focus:outline-none" onClick={this.toggleBox}>
          {children}
        </button>

        {opened && (
          <div className="z-50 absolute bottom-o right-0 rounded-lg shadow-md bg-gray-200 whitespace-nowrap ">
            <Destination_tab
              filtervalue={(value) =>
                this.setState({ filterlist: this.state.filterlist })
              }
            />
            {/* <Feed_elements /> */}
          </div>
        )}
      </div>
    );
  }
}

export default withAuthenticator(Card_popout2, false);
