import React, { useState, useContext } from "react";

import getToken from "../../../Token";
import getUsername from "../../../Account_Infomation/Username";
import IconComponent from "../../../Utility/IconComponent";

import Modal from "react-modal";
import AddDetailMessagePopup from "../../../Popups/AddDetailMessagePopup";
import DialoguePopup from "../../../Popups/ConfirmationMessage";
import AddChatMessagePopup from "../../../Popups/AddChatMessagePopup";
import { GlobalContext } from "../../../GlobalContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import beticon from "../../../../assets/beticon.svg";

dayjs.extend(utc);
dayjs.extend(tz);

const LockedCards = ({ message }) => {
  var link = `${process.env.REACT_APP_LINK}`;
  //var link = 'http://localhost:8000/api/'
  const timeZone = dayjs.tz.guess();
  let accesstoken = getToken();
  const curUser = getUsername();
  const { notsocket, chatRef, setChatRef } = useContext(GlobalContext);
  //   Claim Modal
  const [claimModalIsOpen, setClaimModalIsOpen] = useState(false);
  const setClaimModalIsOpenToFalse = () => {
    setClaimModalIsOpen(false);
  };

  //   Concede Modal
  const [concedeModalIsOpen, setConcedeModalIsOpen] = useState(false);
  const setConcedeModalIsOpenToFalse = () => {
    setConcedeModalIsOpen(false);
  };

  // Evidence Modal
  const [evidenceModalIsOpen, setEvidenceModalIsOpen] = useState(false);
  const setEvidenceModalIsOpenToFalse = () => {
    setEvidenceModalIsOpen(false);
  };

  //   Accept Modal
  const [acceptModalIsOpen, setAcceptModalIsOpen] = useState(false);
  const setAcceptModalIsOpenToFalse = () => {
    setAcceptModalIsOpen(false);
  };

  //   Dispute Modal
  const [disputeModalIsOpen, setDisputeModalIsOpen] = useState(false);
  const setDisputeModalIsOpenToFalse = () => {
    setDisputeModalIsOpen(false);
  };
  const [chatModalIsOpen, setChatModalIsOpen] = useState(false);

  const setChatModalIsOpenToFalse = () => {
    setChatModalIsOpen(false);
  };

  // handle chat modal
  const handleChatModal = async () => {
    setChatModalIsOpen(true);
    let accesstoken = getToken();
    const response = await fetch(
      `${process.env.REACT_APP_LINK}` + "read-notifications-chat/",
      {
        method: "POST",
        body: JSON.stringify({
          user: getUsername(),
          tileref: message.PuntvilleBetRef,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + accesstoken,
        },
      }
    );
    const json = await response.json();
    if (json) {
      notsocket.send(
        JSON.stringify({
          user: getUsername(),
        })
      );
      setChatRef(chatRef.filter((item) => item !== message.PuntvilleBetRef));
    }
  };
  return (
    <div className=" relative pt-1">
      <div className="absolute text-xs -top-5 left-2     ">
        <p className="text-xs text-gray-600 ">
          {" "}
          Expired At: {message.active_period.slice(0, 10)}
        </p>
      </div>
      <p className="absolute text-xs -top-5 right-2 flex-row items-center text-gray-600 ">
        {message.PuntvilleBetRef}
      </p>
      <div
        id="containerbox"
        className="grid grid-cols-2 w-full m-1 content-evenly justify-items-stretch text-center "
      >
        {" "}
        <div id="Icon" className="text-center w-full flex text-xs ">
          <div id="logo " className=" mx-auto">
            <IconComponent subject={message.topic} />
          </div>
        </div>
        <div id="Details" className="text-center flex flex-col p-2 text-xs ">
          <div id="right box" className="flex-shrink flex flex-col      ">
            <div
              id="top col for name"
              className=" flex-shrink py-2 text-lg border-b border-gray-400"
            >
              {" "}
              {message.author}{" "}
            </div>
            <div
              id="middle col for price "
              className=" flex items-end justify-center text-gray-700 pt-2  text-2xl  "
            >
              <span className="text-xs pb-1 ">bets &nbsp;</span>{" "}
              <img src={beticon} alt="" className="w-4 h-8" />
              <span className="text-2xl">{message.body}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center  bg-gradient-to-b from-[#feffad] to-[#ffeb9a]  rounded-md shadow-md  p-1 mb-2">
        <div
          id="col for per month"
          className="text-green-700 text-2xl   break-normal "
        >
          {message.bet}
        </div>
      </div>
      <p className="flex flex-grow items-center justify-center text-sm mb-2 rounded-lg">
        {message.user === curUser
          ? "You have bet against it!🤞🤞"
          : `${message.user} has bet against it!🤞🤞`}
      </p>
      <div className="">
        {/* winner === pending */}
        <div className="flex flex-wrap gap-2">
          {message.winner === "Pending" && (
            <button
              onClick={() => setClaimModalIsOpen(true)}
              className="lockedCardButton"
            >
              Claim Win
            </button>
          )}
          {message.winner === "Pending" && (
            <button
              className="lockedCardButton"
              onClick={() => setConcedeModalIsOpen(true)}
            >
              Concede
            </button>
          )}
          {message.winner === "Pending" && (
            <div className="relative">
              {chatRef.includes(message.PuntvilleBetRef) && (
                <div className="bg-red-400 p-2  rounded-full absolute -top-1 -right-1 z-10"></div>
              )}
              <button className="lockedCardButton" onClick={handleChatModal}>
                Chat
              </button>
            </div>
          )}
        </div>
        {/* winner !== Pending */}
        {message.winner !== "Pending" && (
          <>
            {message.winner === message.author ? (
              /* If bet-creator Claims first */
              message.author === curUser ? (
                // what bet creator will see
                <div className="flex flex-wrap gap-2">
                  <button
                    className="lockedCardButton"
                    onClick={() => setEvidenceModalIsOpen(true)}
                  >
                    Evidence
                  </button>
                  <button
                    className="lockedCardButton"
                    onClick={() => setConcedeModalIsOpen(true)}
                  >
                    Concede
                  </button>
                  <div className="relative">
                    {chatRef.includes(message.PuntvilleBetRef) && (
                      <div className="bg-red-400 p-2  rounded-full absolute -top-1 -right-1 z-10"></div>
                    )}
                    <button
                      className="lockedCardButton"
                      onClick={handleChatModal}
                    >
                      Chat
                    </button>
                  </div>
                </div>
              ) : (
                // what bet taker will see
                <div className="flex flex-wrap gap-2">
                  <button
                    className="lockedCardButton"
                    onClick={() => setEvidenceModalIsOpen(true)}
                  >
                    Evidence
                  </button>
                  <button
                    className="lockedCardButton"
                    onClick={() => setConcedeModalIsOpen(true)}
                  >
                    Concede
                  </button>
                  {message.Isdispute === "Dispute" && (
                    <button
                      onClick={() => setDisputeModalIsOpen(true)}
                      className="lockedCardButton "
                    >
                      Dispute
                    </button>
                  )}
                  <div className="relative">
                    {chatRef.includes(message.PuntvilleBetRef) && (
                      <div className="bg-red-400 p-2  rounded-full absolute -top-1 -right-1 z-10"></div>
                    )}
                    <button
                      className="lockedCardButton"
                      onClick={handleChatModal}
                    >
                      Chat
                    </button>
                  </div>
                </div>
              )
            ) : /* If bet-taker Claims first */
            message.user === curUser ? (
              // bet taker will see
              <div className="flex flex-wrap gap-2">
                <button
                  className="lockedCardButton"
                  onClick={() => setEvidenceModalIsOpen(true)}
                >
                  {" "}
                  Evidence
                </button>
                <button
                  className="lockedCardButton"
                  onClick={() => setConcedeModalIsOpen(true)}
                >
                  Concede
                </button>
                <div className="relative">
                  {chatRef.includes(message.PuntvilleBetRef) && (
                    <div className="bg-red-400 p-2  rounded-full shadow-md absolute -top-1 -right-1 z-10"></div>
                  )}
                  <button
                    className="lockedCardButton"
                    onClick={handleChatModal}
                  >
                    Chat
                  </button>
                </div>
              </div>
            ) : (
              // bet creator will see
              <div className="flex flex-wrap gap-2">
                <button
                  className="lockedCardButton"
                  onClick={() => setEvidenceModalIsOpen(true)}
                >
                  Evidence
                </button>
                <button
                  onClick={() => setAcceptModalIsOpen(true)}
                  className="lockedCardButton"
                >
                  Accept
                </button>

                {message.Isdispute !== "Disputed" && (
                  <button
                    onClick={() => setDisputeModalIsOpen(true)}
                    className="lockedCardButton "
                  >
                    Dispute
                  </button>
                )}
                <div className="relative">
                  {chatRef.includes(message.PuntvilleBetRef) && (
                    <div className="bg-red-400 p-2  rounded-full absolute top-0 -right-1 z-10"></div>
                  )}
                  <button
                    className="lockedCardButton"
                    onClick={handleChatModal}
                  >
                    Chat
                  </button>
                </div>
              </div>
            )}
          </>
        )}

        {message.winner === "Pending" && (
          <div className="   mt-4  ">
            <p className="">Status: Bet taken by {message.user}</p>
          </div>
        )}

        {message.winner !== "Pending" && (
          <div className="flex mt-4  w-full font-fredoka  ">
            {message.Isdispute === "Disputed" && (
              <p className="  ">Status: Disputed</p>
            )}
            {message.Isdispute !== "Disputed" && (
              <p className="  ">Status: Bet Claimed</p>
            )}
          </div>
        )}
        {message.winner !== "Pending" && message.Isdispute === "Dispute" && (
          <div className="   ">
            <p className="text-xs md:ml-2 mt-2">
              {" "}
              This bet was claimed at{" "}
              {dayjs
                .utc(message.claimed_at)
                .tz(timeZone)
                .format("YYYY-MM-DD HH:mm:ss A")}{" "}
              and will settle automatically within 48 hours if there is no
              response.
            </p>
          </div>
        )}
        {message.winner !== "Pending" && message.Isdispute === "Disputed" && (
          <div className="   ">
            <p className="text-xs md:ml-2 mt-2">
              {" "}
              This bet is in disputed status. We will assess claims from both
              parties and make a decision shortly.
            </p>
          </div>
        )}
      </div>

      {/* chatmodal */}

      <Modal
        isOpen={chatModalIsOpen}
        className=" max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center"
        overlayClassName="bg-black z-50 bg-opacity-30 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setChatModalIsOpen(false)}
      >
        <button
          onClick={setChatModalIsOpenToFalse}
          className="flex justify-start  mb-1 px-2 button shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm  flex-shrink"
        >
          x
        </button>
        <AddChatMessagePopup
          tileData={message}
          closeOnClick={setChatModalIsOpenToFalse}
        />
      </Modal>

      {/* Claim win Modal */}
      <Modal
        isOpen={claimModalIsOpen}
        className=" max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center"
        overlayClassName="bg-black z-50 bg-opacity-30 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setClaimModalIsOpen(false)}
      >
        <button
          onClick={setClaimModalIsOpenToFalse}
          className="flex justify-start  mb-1 px-2 button shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm  flex-shrink"
        >
          x
        </button>
        <AddDetailMessagePopup
          betData={message}
          Action={"Claim"}
          Result={"winning the bet"}
          closeOnClick={setClaimModalIsOpenToFalse}
        />
      </Modal>

      {/* Evidence Modal */}

      <Modal
        isOpen={evidenceModalIsOpen}
        className=" max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center"
        overlayClassName="bg-black z-50 bg-opacity-30 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setEvidenceModalIsOpen(false)}
      >
        <button
          onClick={setEvidenceModalIsOpenToFalse}
          className="flex justify-start  mb-1 px-2 button shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm  flex-shrink"
        >
          x
        </button>
        <AddDetailMessagePopup
          betData={message}
          Action={"Evidence"}
          closeOnClick={setEvidenceModalIsOpenToFalse}
        />
      </Modal>

      {/* Dispute Modal */}
      <Modal
        isOpen={disputeModalIsOpen}
        className=" max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center"
        overlayClassName="bg-black z-50 bg-opacity-30 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setDisputeModalIsOpen(false)}
      >
        <button
          onClick={setDisputeModalIsOpenToFalse}
          className="flex justify-start  mb-1 px-2 button shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm  flex-shrink"
        >
          x
        </button>
        <AddDetailMessagePopup
          betData={message}
          Action={"Dispute"}
          Result={"disputing the bet"}
          closeOnClick={setDisputeModalIsOpenToFalse}
        />
      </Modal>

      {/* Concede Modal */}
      <Modal
        isOpen={concedeModalIsOpen}
        className=" max-w-xl z-50 bg-white p-5 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center"
        overlayClassName="bg-black z-50 bg-opacity-30 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setConcedeModalIsOpen(false)}
      >
        <button
          onClick={setConcedeModalIsOpenToFalse}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm  flex-shrink"
        >
          x
        </button>
        <DialoguePopup
          betData={message}
          Event={"Concede"}
          textmessage={"Are you sure you wish to concede this bet?"}
          closeOnClick={setConcedeModalIsOpenToFalse}
        />
      </Modal>

      {/* Accept Modal */}
      <Modal
        isOpen={acceptModalIsOpen}
        className=" max-w-xl z-50 bg-white p-5  mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center"
        overlayClassName="bg-black z-50 bg-opacity-30 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setAcceptModalIsOpen(false)}
      >
        <button
          onClick={setAcceptModalIsOpenToFalse}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm  flex-shrink"
        >
          x
        </button>
        <DialoguePopup
          betData={message}
          Event={"Accept"}
          textmessage={"Are you sure you wish to Accept this bet?"}
          closeOnClick={setAcceptModalIsOpenToFalse}
        />
      </Modal>
    </div>
  );
};

export default LockedCards;
