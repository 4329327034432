import React from "react";

import { useState, useCallback } from "react";
import { Link } from "react-router-dom";

const VerifyMessagePopup2 = (props) => {
  var link = `${process.env.REACT_APP_LINK}`;
  //var link = "http://localhost:8000/api/";

  var ReferralCode = "";

  const [posting, setPosting] = useState(false);

  const handleSumbit = (e) => {
    e.preventDefault(); //prevents refreshing page
    props.onClick3();
  };

  //need to make onclickfunction to update message board feed when submit in messageboard component

  return (
    <div className="bg-white rounded-md py-5">
      <p className=" text-center">Welcome to Puntville!</p>
      <p className=" text-center">Want to Know how it works?</p>
      <div className="grid grid-cols-2 flex sm:flex-row flex-col text-center pt-2">
        <p></p>
        <div className="col-span-2 flex-1 pl-2 text-center pt-5">
          <button
            className="col-span-2 focus:outline-none text-center"
            onClick={handleSumbit}
          >
            <div className="focus:border-0 flex-3 ">
              <div className=" sm:w-20 h-8 px-5 sm:mr-2 button  text-center shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-full hover:bg-red-400 hover:text-gray-100 text-xl ">
                <Link to="/how-it-works">Yes</Link>{" "}
              </div>
            </div>
          </button>
          <span> </span>
          <button
            className="col-span-2 focus:outline-none text-center sm:ml-4"
            onClick={handleSumbit}
          >
            <div className="focus:border-0 flex-3 ">
              <div className=" sm:w-40  h-8 px-5 sm:mr-2 button  text-center shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-full hover:bg-red-400 hover:text-gray-100 text-xl ">
                <Link to="/">No thanks</Link>{" "}
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyMessagePopup2;
