import React, { useContext, useEffect, useState, useRef } from "react";
import fernet from "fernet";
import getToken from "../Token";
import getUsername from "../Account_Infomation/Username";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { GlobalContext } from "../GlobalContext";

const AddChatMessagePopup = ({ tileData, closeOnClick }) => {
  var link = `${process.env.REACT_APP_LINK}`;

  const accesstoken = getToken();
  const { notsocket } = useContext(GlobalContext);
  const username = getUsername();
  const [data, setData] = useState([]);
  const [sendmessage, setSendMessage] = useState("");
  const [socket, setSocket] = useState([]);
  const [empty, setEmpty] = useState("yes");
  const FetchChat = async () => {
    const response = await fetch(link + "fetch-chat/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify({
        user: username,
        tileref: tileData.PuntvilleBetRef,
      }),
    });
    const json = await response.json();

    if (json) {
      if (json["Notification-text"] === "Failed.") {
        setEmpty("yes");
        // setData((data) => [
        //   ...data,
        //   {
        //     id: 0,
        //     sender: "",
        //     message: "chat history not available",
        //   },
        // ]);
      } else {
        var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
        var token = new fernet.Token({
          secret: secret,
        });
        setData(JSON.parse(token.decode(json)));
      }
    }
  };

  const handleUpdateInfo = (e) => {
    e.preventDefault();
    var secret = new fernet.Secret(process.env.REACT_APP_FERNET_KEY);
    var token = new fernet.Token({
      secret: secret,
    });
    fetch(link + "chat-create/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify({
        user: token.encode(username),
        tileref: token.encode(tileData.PuntvilleBetRef),
        message: token.encode(sendmessage),
      }),
    })
      .then((response) => response.json())
      .then(async (json) => {
        // console.log(json)
        // toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
        setSendMessage("");
        setEmpty("no");
        socket.send(
          JSON.stringify({
            tileref: tileData.PuntvilleBetRef,
          })
        );

        notsocket.send(
          JSON.stringify({
            user: username,
          })
        );
      });
  };

  socket.onopen = () => {
    // console.log("chat WebSocket Client Connected");
    console.log();
  };

  socket.onmessage = (event) => {
    setEmpty("no");
    setData((data) => [
      ...data,
      {
        id: JSON.parse(event.data)["id"],
        sender: JSON.parse(event.data)["sender"],
        message: JSON.parse(event.data)["chat"],
      },
    ]);
  };
  socket.onclose = (event) => {
    // console.log("Client notified socket has closed");
    console.log();
  };

  const messageEl = useRef(null);
  useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      var ws_scheme =
        window.location.protocol == "https:"
          ? "wss://omnistack.org"
          : "wss://omnistack.org";
      const sock = new W3CWebSocket(
        ws_scheme +
          "/ws2/fetch-chat-puntville/" +
          tileData.PuntvilleBetRef +
          "/"
      );
      FetchChat();
      setSocket(sock);
    })();
  }, []);

  // console.log("fetched", data);

  return (
    <div className="bg-white rounded-lg p-2">
      <ToastContainer autoClose={2500} />
      <div className=" font-fredoka bg-gradient-to-t from-blue-50 to-blue-40 p-2 m-2 rounded-lg shadow-sm justify-items-center ">
        <div
          id="right box container"
          className=" flex flex-col item-center max-w-xl justify-start h-80 overflow-y-auto "
          ref={messageEl}
        >
          {data != null &&
            data.map((mes, id) => (
              <div key={mes.id} className="w-full">
                {mes.sender !== "" && mes.id !== "0" && (
                  <div
                    className={`flex ml-2 mr-2  ${
                      mes.sender === username
                        ? "justify-end  text-green-600"
                        : "justify-start  text-blue-600"
                    }`}
                  >
                    <p
                      className={`flex  ${
                        mes.sender === username
                          ? "pl-16"
                          : "flex-row-reverse pr-16 "
                      }`}
                    >
                      <span className="text-xs md:text-sm  max-w-prose text-justify">
                        {mes.message}
                      </span>
                      <span className="flex items-end text-xxs  text-gray-400 px-2">
                        {mes.sender}
                      </span>
                    </p>
                  </div>
                )}
                {empty === "yes" && <p>chat history not available</p>}

                <br></br>
              </div>
            ))}
        </div>
      </div>

      <form onSubmit={(e) => handleUpdateInfo(e)} className=" bg-gray-100 p-2">
        <div class="flex flex-col p-1">
          <textarea
            id="tileusername"
            class="flex items-center w-full h-16 p-2 resize-nonep-2 rounded-md font-fredoka bg-gradient-to-t from-blue-200 to-blue-100  shadow-sm text-xs  "
            value={sendmessage}
            required
            onChange={(e) => setSendMessage(e.target.value)}
            type="text"
            placeholder="max 300 characters allowed"
            rows="1"
          />
          <button
            type="submit"
            className="flex items-center justify-center py-0.5 px-4 mt-1 shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-lg "
          >
            Send
          </button>
        </div>
      </form>
      <p className="text-xs font-fredoka ">
        We save messages from the last 30 days only.
      </p>
    </div>
  );
};

export default AddChatMessagePopup;
