import React, { useContext, useEffect, useState } from "react";

import ArchivedCards from "./ArchivedCards";
import { useQueries, useQuery, useQueryClient } from "react-query";
import Endpoints from "../../../../api/endpoints";
import { GlobalContext } from "../../../GlobalContext";
import ReactPaginate from "react-paginate";

function ArchivedBets() {
  const { archivedPages, setArchivedPages } = useContext(GlobalContext);
  // fetch archivedbets
  // const queryClient = useQueryClient();
  // const archivedBetsData = queryClient.getQueryData("archivedBets");
  // const queryKeys = ["openBets", "lockedBets", "archivedBets"];
  // const queries = useQueries(queryKeys);

  // const isArchivedBetsDataLoading = queries[2].isArchivedBetsDataLoading;

  // fetch archived bets
  const [page, setPage] = useState(0);

  const {
    isLoading: isArchivedBetsDataLoading,
    data: archivedBetsData,
    isError: isArchivedBetsError,
    error: archivedBetsErr,
    isFetching: isArchivedBetsFetching,
    isPreviousData: isPreviousArchivedBets,
  } = useQuery(
    ["archivedBets", { page }],
    () => Endpoints.GetArchivedBets(page),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const handlePageChange = (e) => {
    setPage(e.selected);
  };

  // if (isArchivedBetsDataLoading) {
  //   return <p className="text-center p-2">Loading ...</p>;
  // }

  if (isArchivedBetsError) {
    return (
      <p className="text-center p-2">
        Something went wrong! Please try again later..
      </p>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center max-w-4xl mx-auto">
      <div className=" py-3 px-2 w-full ">
        <ReactPaginate
          previousLabel={
            <span className="flex items-center justify-center w-6 h-6 border rounded-md shadow-md  border-blue-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="w-4"
              >
                <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
              </svg>
            </span>
          }
          nextLabel={
            <span className="flex items-center justify-center w-6 h-6 border rounded-md shadow-md  border-blue-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="w-4"
              >
                <path d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z"></path>
              </svg>
            </span>
          }
          pageClassName=" flex items-center justify-center w-6 h-6 text-xs border rounded shadow-md  border-blue-300 md:hover:bg-blue-100 mx-1"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageRangeDisplayed={3}
          pageCount={
            archivedBetsData?.find((item) => item.hasOwnProperty("pages")).pages
          }
          marginPagesDisplayed={1}
          onPageChange={handlePageChange}
          containerClassName="flex items-center justify-center mt-2"
          activeClassName="bg-blue-400 text-white"
          forcePage={page}
        />
      </div>
      {isArchivedBetsFetching && <span className="loading">Loading...</span>}
      <div className="grid md:grid-cols-2">
        {archivedBetsData
          ?.filter((item) => !item.hasOwnProperty("pages"))
          .map((message) => (
            <div
              key={message.id}
              className=" px-4 py-6 mx-2 my-5  max-w-md border-t border-gray-50  rounded-lg shadow-md justify-items-center"
            >
              <ArchivedCards message={message} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default ArchivedBets;
