import React, { useEffect, useRef, useState } from "react";

import "../styles/output.css";
import { Auth, Hub } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import getUsername from "./Account_Infomation/Username";
import { useQueryClient } from "react-query";

const Card_popout = (props) => {
  const queryClient = useQueryClient();
  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();
  const wrapperRef = useRef(null);

  const handleSignOutButtonClick = async () => {
    try {
      await Auth.signOut();
      Hub.dispatch("UI Auth", {
        // channel must be 'UI Auth'
        event: "AuthStateChange", // event must be 'AuthStateChange'
        message: "signedout", // message must be 'signedout'
      });
      queryClient.clear();
      navigate("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const toggleBox = () => {
    setOpened(!opened);
  };

  const handlePaymentMethodsButtonClick = () => {
    setOpened(!opened);
  };

  const handleSupportButtonClick = () => {
    // this.props.clickpayments("supportpage");
    setOpened(!opened);
  };

  const handleAccountButtonClick = () => {
    setOpened(!opened);
  };

  const handleCashoutButtonClick = () => {
    setOpened(!opened);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        opened &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setOpened(false);
      }
    }

    function handleScroll() {
      if (opened) {
        setOpened(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [opened]);

  return (
    <div ref={wrapperRef}>
      <button className="focus:outline-none" onClick={toggleBox}>
        {props.children}
      </button>

      {opened && (
        <div className="z-50 absolute  w-40 border border-red-100 rounded-lg shadow-md bg-white p-1 max-w-md flex-col flex-shrink">
          <p className="hover:bg-gradient-to-t from-blue-200 to-blue-100 mb-1 hover:text-red-600 rounded-sm text-left text-sm text-bold pl-2 pt-1 pb-1">
            User: {getUsername()}{" "}
          </p>
          <p
            onClick={handleAccountButtonClick}
            className="hover:bg-gradient-to-t from-blue-200 to-blue-100 mb-1 hover:text-red-600 rounded-sm text-left text-sm text-bold pl-2 pt-1 pb-1"
          >
            <Link to="/account">Your Account</Link>{" "}
          </p>
          {/* 
            <p className="hover:bg-blue-900 hover:text-white  -sm text-left text-sm pl-2 pt-1 pb-1">
              {" "}
              Order History{" "}
            </p> */}

          <p
            onClick={handlePaymentMethodsButtonClick}
            className="hover:bg-gradient-to-t from-blue-200 to-blue-100 mb-1 hover:text-red-600 rounded-sm text-left text-sm text-bold pl-2 pt-1 pb-1"
          >
            <Link to="/payments">Wallet</Link>{" "}
          </p>

          <p
            onClick={handleSupportButtonClick}
            className="hover:bg-gradient-to-t from-blue-200 to-blue-100 mb-1 hover:text-red-600 rounded-sm text-left text-sm text-bold pl-2 pt-1 pb-1"
          >
            {" "}
            <Link to="/support">Support</Link>{" "}
          </p>

          {/* <p
            onClick={handleCashoutButtonClick}
            className="hover:bg-gradient-to-t from-blue-200 to-blue-100 mb-1 hover:text-red-600 rounded-sm text-left text-sm text-bold pl-2 pt-1 pb-1"
          >
            {" "}
            <Link to="/cashout">Cash out</Link>{" "}
          </p> */}

          <p
            onClick={handleCashoutButtonClick}
            className="hover:bg-gradient-to-t from-blue-200 to-blue-100 mb-1 hover:text-red-600 rounded-sm text-left text-sm text-bold pl-2 pt-1 pb-1"
          >
            {" "}
            <Link to="/how-it-works">How It Works</Link>{" "}
          </p>
          <p
            onClick={handleCashoutButtonClick}
            className="hover:bg-gradient-to-t from-blue-200 to-blue-100 mb-1 hover:text-red-600 rounded-sm text-left text-sm text-bold pl-2 pt-1 pb-1"
          >
            {" "}
            <Link to="/redeem-coupon">Reedem Coupon</Link>{" "}
          </p>
          <p
            onClick={handleSignOutButtonClick}
            className="hover:bg-gradient-to-t from-blue-200 to-blue-100 mb-1 hover:text-red-600 rounded-sm text-left text-sm text-bold pl-2 pt-1 pb-1"
          >
            Sign Out{" "}
          </p>
        </div>
      )}
    </div>
  );
};

export default Card_popout;
