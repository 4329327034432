import React, { useState, useContext, useEffect } from "react";

import LockedCards from "./LockedCards";
import { useQueries, useQuery, useQueryClient } from "react-query";
import Endpoints from "../../../../api/endpoints";
import LoadingModal from "../../../Popups/LoadingModal";

function LockedBets() {
  const queryClient = useQueryClient();
  // const lockedBetsData = queryClient.getQueryData("lockedBets");
  // const loadingstate = queryClient.getQueryState("lockedBets");
  // console.log(loadingstate.isFetching);
  // const queryKeys = ["openBets", "lockedBets", "archivedBets"];
  // const queries = useQueries(queryKeys);

  // const isLockedBetsDataLoading = queries[1].isLoading;

  // console.log(isLockedBetsDataLoading);

  // if (isLockedBetsDataLoading) {
  //   return <p className="text-center p-2">Loading ...</p>;
  // }

  // fetch locked bets
  const {
    isLoading: isLockedBetsDataLoading,
    isFetching: isLockedBetsDataFetching,
    data: lockedBetsData,
    isError: isLockedBetsError,
    error: lockedBetsErr,
  } = useQuery("lockedBets", Endpoints.GetLockedBets, {
    refetchOnWindowFocus: false,
  });

  if (isLockedBetsDataFetching) {
    return <p className="text-center p-2">Loading ...</p>;
  }

  if (isLockedBetsError) {
    return (
      <p className="text-center p-2">
        Something went wrong! Please try again later..
      </p>
    );
  }

  return (
    <div className="flex items-center justify-center  max-w-4xl mx-auto ">
      <div className="grid md:grid-cols-2">
        {lockedBetsData?.map((message, index) => (
          <div
            key={message.id}
            className="px-4 py-6 mx-2 my-5 border-t border-gray-50 max-w-md   rounded-lg shadow-md justify-items-center"
          >
            <LockedCards message={message} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default LockedBets;
