import React, { useEffect } from "react";

import getUsername from "../../../Account_Infomation/Username";
import OpenCards from "./OpenCards";
import { useQueries, useQuery, useQueryClient } from "react-query";
import Endpoints from "../../../../api/endpoints";

function OpenBets() {
  const curUser = getUsername();
  // const queryClient = useQueryClient();
  // const openBetsData = queryClient.getQueryData("openBets");
  // const queryKeys = ["openBets", "lockedBets", "archivedBets"];
  // const queries = useQueries(queryKeys);

  // const isOpenBetsDataLoading = queries[0].isLoading;

  // fetch openbets
  const {
    isLoading: isOpenBetsDataLoading,
    data: openBetsData,
    isError: isOpenBetsError,
    error: openBetsErr,
  } = useQuery("openBets", Endpoints.GetOpenBets, {
    refetchOnWindowFocus: false,
  });

  if (isOpenBetsDataLoading) {
    return <p className="text-center p-2">Loading ...</p>;
  }

  if (isOpenBetsError) {
    return (
      <p className="text-center p-2">
        Something went wrong! Please try again later..
      </p>
    );
  }

  return (
    <div className="flex items-center justify-center max-w-2xl mx-auto">
      <div className="grid md:grid-cols-2 w-full">
        {openBetsData
          ?.filter((el) => {
            return el.author === curUser;
          })
          .map((message) => (
            <div
              key={message.id}
              className=" px-4 py-6 mx-2 my-5  shadow-md rounded-lg justify-items-center overflow-x-hidden"
            >
              <OpenCards message={message} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default OpenBets;
