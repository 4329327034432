import React, { createContext, useReducer, useContext } from "react";

import getUsername from "./components/Account_Infomation/Username.js";
import getReferralCode from "./components/Account_Infomation/referralCode.js";

import Cashout_Page from "./components/Pages/Cashout_Page.js";
import Support_Page from "./components/Pages/Support_Page.js";

import Your_Account from "./components/Pages/Your_Account.js";
import UserMessages from "./components/Pages/UserMessages.js";
import getIsVerified from "./components/Account_Infomation/isVerified.js";
import Modal from "react-modal";
import VerifyMessagePopup from "./components/Popups/VerifyMessagePopup.js";
import VerifyMessagePopup2 from "./components/Popups/VerifyMessagePopup2.js";
import "./styles/output.css";
import { Auth } from "aws-amplify";

import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MessageBoard from "./components/Pages/MessageBoard.js";

import How_It_Works from "./components/Pages/How_It_Works.js";
import OpenBets from "./components/Pages/orders/open/OpenBets.js";
import LockedBets from "./components/Pages/orders/locked/LockedBets.js";
import ArchivedBets from "./components/Pages/orders/archived/ArchivedBets.js";
import FilterData from "./components/Pages/orders/FilterData.js";
import ReddemCoupon from "./components/Pages/ReddemCoupon.js";
import Payment from "./components/Pages/wallet/Payment.js";

const Test = () => {
  const [feedelement, setFeedelement] = useState("messageboard");
  const [filterlist, setFilterlist] = useState(null);
  const [VerifyCode, setVerifyCode] = useState("");
  const [postedmessage, setPostedmessage] = useState([]);
  const [message, setmessage] = useState("");
  const verification = getIsVerified();
  const referralCode = getReferralCode();
  const [Verified, setVerified] = useState(getIsVerified());

  const [verifiedStatus, setVerifiedStatus] = useState(verification);

  global.username = Auth.currentUserInfo().username;
  var styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      left: "36px",
      top: "36px",
      display: "block",
    },
    focus: {
      outline: "none",
    },
  };

  const customStyles = {
    overlay: {
      background: "rgba(52, 52, 52, 0.9)",
    },

    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFFFFF",
    },
  };

  document.title = "Puntville";
  const [menuclick, setMenuclick] = useState(false);
  function handleClick() {
    setMenuclick(!menuclick);
  }
  /*in sidebar overflow-y-scroll*/

  const [modalIsOpen, setModalIsOpen] = useState(!Verified);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const setModalIsOpenToTrue2 = () => {
    setModalIsOpen2(true);
  };
  const setModalIsOpenToFalse2 = () => {
    setModalIsOpen2(false);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modalIsOpen}
        className="font-fredoka  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md focus:outline-none shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
      >
        <VerifyMessagePopup
          onClick={setModalIsOpenToFalse}
          onClick2={setModalIsOpenToTrue2}
        />
      </Modal>
      {modalIsOpen2 && (
        <Modal
          isOpen={modalIsOpen2}
          className="font-fredoka  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md focus:outline-none shadow-lg text-center"
          overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        >
          <VerifyMessagePopup2 onClick3={setModalIsOpenToFalse2} />
        </Modal>
      )}
      <Routes>
        <Route
          path="cashout"
          element={<Cashout_Page currentusername={getUsername()} />}
        />

        <Route
          path="support"
          element={<Support_Page currentusername={getUsername()} />}
        />

        <Route
          path="payments"
          element={<Payment currentusername={getUsername()} />}
        />

        <Route path="how-it-works" element={<How_It_Works />} />

        <Route
          path="account"
          element={<Your_Account currentusername={getUsername()} />}
        />

        <Route path="how-it-works" element={<How_It_Works />} />

        <Route path="redeem-coupon" element={<ReddemCoupon />} />

        <Route
          path="my-posts"
          element={<UserMessages currentusername={getUsername()} />}
        >
          <Route path="open" element={<OpenBets />} />
          <Route path="locked" element={<LockedBets />} />
          <Route path="archived" element={<ArchivedBets />} />
          <Route path="filter" element={<FilterData />} />
        </Route>

        <Route
          path="home"
          element={
            <MessageBoard
              postedmessage={postedmessage}
              currentusername={getUsername()}
              filterlist={filterlist}
            />
          }
        />
        <Route path="*" element={<Navigate to="home" />} />
      </Routes>
    </React.Fragment>
  );
};

export default Test;
