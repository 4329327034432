import React, { useContext, useEffect, useState } from "react";
import getToken from "../../../Token";
import getUsername from "../../../Account_Infomation/Username";
import IconComponent from "../../../Utility/IconComponent";

import { useQueryClient } from "react-query";
import { GlobalContext } from "../../../GlobalContext";
import beticon from "../../../../assets/beticon.svg";
import Modal from "react-modal";
import LoadingModal from "../../../Popups/LoadingModal";
import { toast } from "react-toastify";
import QRCode from "qrcode";
const OpenCards = ({ message }) => {
  const queryClient = useQueryClient();
  const { feedsocket } = useContext(GlobalContext);
  var link = `${process.env.REACT_APP_LINK}`;
  //var link = 'http://localhost:8000/api/'

  const curUser = getUsername();

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  // Link share - qr code Func
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [sharebet, setSharebet] = useState("");
  const [linkModalIsOpen, setLinkModalIsOpen] = useState(false);

  const setLinkModalIsOpenToTrue = () => {
    setLinkModalIsOpen(true);
  };
  const setLinkModalIsOpenToFalse = () => {
    setLinkModalIsOpen(false);
  };

  const handleShareCardClick = (tileref) => {
    QRCode.toDataURL("https://puntville.com/share/" + tileref)
      .then((url) => {
        setQrCodeUrl(url);
      })
      .catch((err) => console.error(err));
  };

  const handleDownloadQrCode = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = qrCodeUrl;
    downloadLink.download = "qr-code.png";
    downloadLink.click();
  };

  const handleDeleteglobal = async (e) => {
    setIsLoading(true);
    let accesstoken = getToken();
    feedsocket.onopen = () => {
      // console.log("feed WebSocket Client Connected");
      feedsocket.send(
        JSON.stringify({
          user: getUsername(),
        })
      );
    };
    const savedcontent = {
      id: e.target.dataset.id,
      user: curUser,
      subject: e.target.dataset.subject,
      body: e.target.dataset.body,
      topic: e.target.dataset.topic,
      bet: e.target.dataset.bet,
      author: e.target.dataset.author,
      PuntvilleBetRef: e.target.dataset.puntvillebetref,
      created_at: e.target.dataset.created_at,
    };
    const response = await fetch(link + "puntville-delete/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify(savedcontent),
    });
    const json = await response.json();
    if (json) {
      await queryClient.invalidateQueries("openBets");
      await queryClient.invalidateQueries("puntvilleBets");
      queryClient.invalidateQueries("puntvilleUserCredits");
    }
    setIsLoading(false);
  };

  const updateBet = async (savedcontent) => {
    const accesstoken = getToken();
    try {
      const response = await fetch(
        "https://omnistack.org/puntville/bet-update/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accesstoken,
          },
          body: JSON.stringify(savedcontent),
        }
      );
      const json = await response.json();

      if (json) {
        await queryClient.invalidateQueries("openBets");
        await queryClient.invalidateQueries("puntvilleBets");
        queryClient.invalidateQueries("puntvilleUserCredits");
        toast(json.status);
      }
    } catch (error) {
      toast(error.message);
    }
  };

  const handleUpdateBet = async (e) => {
    setIsLoading(true);
    feedsocket.onopen = () => {
      // console.log("feed WebSocket Client Connected on open page");
      feedsocket.send(
        JSON.stringify({
          user: getUsername(),
        })
      );
    };
    // console.log(message);
    const savedcontent = {
      PuntvilleBetRef: message.PuntvilleBetRef,
      isPrivate: !message.isPrivate,
    };

    await updateBet(savedcontent);
    setIsUpdateModalOpen(false);

    setIsLoading(false);
  };
  return (
    <>
      <div id="column layout" className=" ">
        <div className="flex items-center justify-between">
          <button
            className="px-4 py-0.5 mb-2 focus:outline-none cursor-pointer cardBtn text-sm  "
            data-id={message.id}
            data-author={message.author}
            data-topic={message.topic}
            data-bet={message.bet}
            data-body={message.body}
            data-created_at={message.created_at}
            data-currency={message.currency}
            data-PuntvilleBetRef={message.PuntvilleBetRef}
            onClick={handleDeleteglobal}
          >
            Disable
          </button>
          <button
            className="px-4 py-0.5 mb-2 focus:outline-none cursor-pointer cardBtn text-sm  "
            data-id={message.id}
            data-author={message.author}
            data-topic={message.topic}
            data-bet={message.bet}
            data-body={message.body}
            data-created_at={message.created_at}
            data-currency={message.currency}
            data-PuntvilleBetRef={message.PuntvilleBetRef}
            onClick={() => setIsUpdateModalOpen(true)}
          >
            {message.isPrivate ? "Private" : "Public"}
          </button>
        </div>
        <div className="w-full ">
          <div className="">
            <div
              id="containerbox"
              className="grid grid-cols-2 w-full content-evenly justify-items-stretch text-center "
            >
              {" "}
              <div id="Icon" className="text-center w-full flex text-xs ">
                <div id="logo " className=" mx-auto">
                  <IconComponent subject={message.topic} />
                </div>
              </div>
              <div
                id="Details"
                className="text-center flex flex-col p-2 text-xs "
              >
                <div id="right box" className="flex-shrink flex flex-col      ">
                  <div
                    id="top col for name"
                    className=" flex-shrink py-2 text-lg border-b border-gray-400"
                  >
                    {" "}
                    {message.author}{" "}
                  </div>
                  <div
                    id="middle col for price "
                    className=" flex items-end justify-center text-gray-700 pt-2  text-2xl  "
                  >
                    <span className="text-xs pb-1 ">bets &nbsp;</span>{" "}
                    <img src={beticon} alt="" className="w-4 h-8" />
                    <span className="text-2xl">{message.body}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center  bg-gradient-to-b from-[#feffad] to-[#ffeb9a]  rounded-md shadow-md  p-1 my-2">
              <div
                id="col for per month"
                className="text-green-700 text-2xl   break-normal "
              >
                {message.bet}
              </div>
            </div>
            <button
              className=" button w-full mr-4 p-2  shadow-sm focus:outline-none cursor-pointer cardBtn text-xs  "
              onClick={() => {
                setLinkModalIsOpenToTrue();
                setSharebet(message.PuntvilleBetRef);
                handleShareCardClick(message.PuntvilleBetRef);
              }}
            >
              Share Bet
            </button>
          </div>
        </div>
      </div>

      {/* loading modal */}
      <Modal
        isOpen={isLoading}
        className="font-fredoka  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md focus:outline-none shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-10 fixed inset-0 overflow-y-auto"
      >
        <LoadingModal />
      </Modal>

      {/* update modal */}

      <Modal
        isOpen={isUpdateModalOpen}
        className="font-fredoka  max-w-xl z-40 mx-5 sm:mx-auto my-10 md:my-24 rounded-md focus:outline-none shadow-lg text-center"
        overlayClassName="bg-black z-40 bg-opacity-10 fixed inset-0 overflow-y-auto"
      >
        <div className="p-5 bg-white rounded-md">
          <span className="flex justify-center"> Proceed ?</span>
          <p className="py-2">
            Bet will Turn {message.isPrivate ? "Public" : "Private"} and will
            {message.isPrivate ? "" : "not"} be visible to all users!
          </p>
          <div className="flex gap-10 items-center justify-center">
            <button
              className="px-4 py-0.5 mb-2 focus:outline-none cursor-pointer cardBtn text-sm  "
              onClick={() => setIsUpdateModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="px-4 py-0.5 mb-2 focus:outline-none cursor-pointer cardBtn text-sm  "
              onClick={handleUpdateBet}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>

      {/* share modal */}
      {/* share link modal */}
      <Modal
        isOpen={linkModalIsOpen}
        className="font-fredoka  max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-md shadow-lg text-center"
        overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
        onRequestClose={() => setLinkModalIsOpen(false)}
      >
        <button
          onClick={setLinkModalIsOpenToFalse}
          className="flex justify-start mb-1 px-2 pb-1 button shadow-md focus:outline-none cursor-pointer bg-blue-400 rounded-md text-sm font-semibold flex-shrink"
        >
          x
        </button>
        <div className="bg-white  p-6 rounded-md flex flex-col items-center">
          <div className="relative">
            <img src={qrCodeUrl} alt="QR code" className="w-72 h-72" />
            <button
              title="Download QR"
              className="absolute bottom-5 -right-3 shadow-md bg-blue-300 rounded-b-md rounded-l-md"
              onClick={handleDownloadQrCode}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="h-8 w-8"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z" />
              </svg>
            </button>
          </div>
          <div className="w-full flex justify-center">
            <input
              type="text"
              className="border border-blue-300 rounded-l-md text-sm p-2 md:w-80 text-gray-500"
              value={`https://puntville.com/share/${sharebet}`}
              readOnly
            />
            <button
              className="bg-white px-2 border border-blue-300 rounded-r-md flex active:bg-blue-400 transition duration-200 ease-in items-center space-x-1 text-gray-700 "
              onClick={() => {
                navigator.clipboard.writeText(
                  "https://puntville.com/share/" + sharebet
                );
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
                className="w-4 h-4"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zM5.003 8L5 20h10V8H5.003zM9 6h8v10h2V4H9v2z" />
              </svg>
              <span className="text-xs">Copy</span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OpenCards;
