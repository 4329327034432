import React from "react";
import Modal from "react-modal";

import { useState } from "react";
import { useEffect, useContext } from "react";
import getToken from "../Token";
import getUsername from "../Account_Infomation/Username";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconComponent from "../Utility/IconComponent";
import convertToBase64 from "../Utility/convert";
import imageCompression from "browser-image-compression";
import ErrImage from "../../assets/imageErr.jpg";
import ImageModal from "./ImageModal";
import beticon from "../../assets/beticon.svg";
import { GlobalContext } from "../GlobalContext";
import Endpoints from "../../api/endpoints";
import { useMutation, useQueryClient } from "react-query";
import LoadingModal from "./LoadingModal";

const AddDetailMessagePopup = ({ betData, Action, Result, closeOnClick }) => {
  const queryClient = useQueryClient();
  var link = `${process.env.REACT_APP_LINK}`;
  // var link = "http://localhost:8000/api/";
  const accesstoken = getToken();
  const curUser = getUsername();
  const { notsocket } = useContext(GlobalContext);
  const username = getUsername();
  const [evidence, setevidence] = useState("");

  const [PuntvilleBetRef, setPuntvilleTileRef] = useState(
    betData.PuntvilleBetRef
  );

  const [ce, setCe] = useState("null");
  const [te, setTe] = useState("null");
  const [creatorImageEvidence, setCreatorImageEvidence] = useState();
  const [takerImageEvidence, setTakerImageEvidence] = useState();

  //image Modal creator
  const [modalIsOpen, setModalIsOpen] = useState(false);
  //image Modal taker
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);

  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  const setImageModalIsOpenToFalse = () => {
    setImageModalIsOpen(false);
  };

  //image Modal taker
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  const setEditModalIsOpenToFalse = () => {
    setEditModalIsOpen(false);
  };

  const handleModalClick = () => {
    setModalIsOpen(true);
  };
  const handleImageModalClick = () => {
    setImageModalIsOpen(true);
  };

  // Image upload for claiming bet
  const [imageEvidence, setImageEvidence] = useState();

  const handleImageUpload = async (e) => {
    // console.log(e.target.files);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    const compressedImg = await imageCompression(e.target.files[0], options);
    const base64 = await convertToBase64(compressedImg);
    setImageEvidence(base64);
  };

  const { setOpenPosts } = useContext(GlobalContext);
  const { setLockedPosts } = useContext(GlobalContext);
  const { setArchivedPosts } = useContext(GlobalContext);

  const updateData = async () => {
    // openposts
    const openData = await Endpoints.GetOpenBets();
    setOpenPosts(openData);
    // lockedPosts
    const lockedData = await Endpoints.GetLockedBets();
    setLockedPosts(lockedData);
    // archivedPosts
    const archivedData = await Endpoints.GetArchivedBets();
    setArchivedPosts(archivedData);
  };

  // fetching Evidence
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (ce === "null" && te === "null") {
      fetch(link + "evidences/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify({
          PuntvilleBetRef: PuntvilleBetRef,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          setTakerImageEvidence(json.TakerImage);
          setCreatorImageEvidence(json.CreatorImage);
          setCe(json["CreatorEvidence"]);
          setTe(json["TakerEvidence"]);
          setIsLoading(false);
        });
    }
  }, []);

  //claim mutation
  const claimHandler = async (content) => {
    const response = await fetch(link + "Win-Puntville-bet/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify(content),
    });
    const json = await response.json();
    if (json) {
      // toast(JSON.stringify(json["Notification-text"]).replace(/"|'/g, ""));
      return json;
    }
  };

  //claim mutation
  const claimMutation = useMutation(claimHandler, {
    onSuccess: () => {
      console.log("Claim Successful");
    },
    onError: (err) => {
      console.log("there was an error", err);
    },
    onSettled: () => {
      queryClient.invalidateQueries("lockedBets");
      queryClient.invalidateQueries("archivedBets");
      queryClient.invalidateQueries("puntvilleUserCredits");
      notsocket.send(
        JSON.stringify({
          user: username,
        })
      );
      closeOnClick();
    },
  });

  //dispute mutation

  const disputeHandler = async (content) => {
    // console.log("Dispute");

    const response = await fetch(link + "PuntvilleDisputeCreate/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify(content),
    });
    const json = await response.json();
    return json;
  };

  //dispute mutation
  const disputeMutation = useMutation(disputeHandler, {
    onSuccess: () => {
      console.log("Dispute Successful");
    },
    onError: (err) => {
      console.log("there was an error", err);
    },
    onSettled: () => {
      queryClient.invalidateQueries("lockedBets");
      queryClient.invalidateQueries("archivedBets");
      queryClient.invalidateQueries("puntvilleUserCredits");
      notsocket.send(
        JSON.stringify({
          user: username,
        })
      );
      closeOnClick();
    },
  });

  const handleEditEvidences = async (e) => {
    e.preventDefault();
    const content = {
      topic: betData.topic,
      body: betData.body,
      bet: betData.bet,
      author: betData.author,
      PuntvilleBetRef: betData.PuntvilleBetRef,
      user: curUser,
      CreatorImage: imageEvidence || "",
      CreatorEvidence: evidence,
    };
    claimMutation.mutate(content);
    setEditModalIsOpen(false);
  };

  const handleEditMessage = async (e) => {
    e.preventDefault(); //prevents refreshing page
    if (Action === "Claim") {
      const content = {
        topic: betData.topic,
        body: betData.body,
        bet: betData.bet,
        author: betData.author,
        PuntvilleBetRef: betData.PuntvilleBetRef,
        user: curUser,
        CreatorImage: imageEvidence || "",
        CreatorEvidence: evidence,
      };
      claimMutation.mutate(content);

      // console.log("Claim Accepted");
    } else {
      const content = {
        author: betData.author,
        PuntvilleBetRef: betData.PuntvilleBetRef,
        user: betData.user,
        TakerEvidence: evidence,
        CreatorImage: imageEvidence || "",
        CreatorEvidence: evidence,
      };
      disputeMutation.mutate(content);
      // console.log("dispute handler called");
    }
  };

  if (claimMutation.isLoading) return <LoadingModal />;
  if (disputeMutation.isLoading) return <LoadingModal />;

  return (
    <div className="flex flex-col items-center bg-white rounded-lg">
      <ToastContainer autoClose={2500} />
      <div className="px-2 py-3 mx-2 my-3 font-fredoka overflow-x-hidden w-3/4  md:w-full md:px-5 ">
        <div id="containerbox" className="flex  flex-row  text-center  ">
          <div id="left box" className="flex-1 px-4 py-6 mx-4 my-4 text-xs  ">
            <div id="logo " className="  ">
              <IconComponent subject={betData.topic} />
            </div>
          </div>

          <div id="right box container" className="flex-1 ">
            <div id="right box" className="flex-shrink flex flex-col ">
              <div
                id="top col for name"
                className=" flex-shrink  px-2 py-4 mx-2 my-2  text-center text-xl  md:text-3xl"
              >
                {" "}
                {betData.author}{" "}
              </div>
              <div
                id="middle col for price "
                className="flex items-end justify-center font-fredoka text-center text-xl "
              >
                <span className="text-xs pb-1 ">bets &nbsp;</span>{" "}
                <img src={beticon} alt="" className="w-4 h-8" />
                <span className="text-2xl">{betData.body}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex p-2 items-center justify-center shadow-md bg-gradient-to-r from-[#feffad] to-[#ffffdc]   rounded-md  text-md">
          {" "}
          {betData.bet}{" "}
        </div>
      </div>
      {Action === "Evidence" ? (
        <>
          {isLoading ? (
            <div className=" flex items-center font-fredoka justify-between pb-10 ">
              <p>Loading Evidence...</p>
            </div>
          ) : (
            <>
              <div className=" flex flex-col md:flex-row items-center font-fredoka justify-between gap-2 px-5 ">
                <div className="flex flex-col items-center border border-red-100  rounded-md">
                  <p>Creator Evidence:</p>{" "}
                  <div className="flex p-2 space-x-2">
                    <img
                      src={creatorImageEvidence || ErrImage}
                      alt="img"
                      className="w-20 h-20 bg-gray-100 rounded-md cursor-pointer"
                      onClick={handleModalClick}
                    />
                    <Modal
                      isOpen={modalIsOpen}
                      className=" max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center"
                      overlayClassName="bg-black z-50  fixed inset-0 overflow-y-auto"
                      onRequestClose={() => setModalIsOpen(false)}
                    >
                      <button
                        onClick={setModalIsOpenToFalse}
                        className="flex justify-start  mb-1 px-2 button shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm  flex-shrink"
                      >
                        x
                      </button>
                      <ImageModal
                        imageSrc={creatorImageEvidence || ErrImage}
                        onClick={setModalIsOpenToFalse}
                      />
                    </Modal>
                    <p className="w-40 text-xs ">{ce}</p>
                  </div>
                </div>
                <div className="flex flex-col items-center border border-red-100 rounded-md">
                  <p>Taker Evidence:</p>{" "}
                  <div className="flex p-2 space-x-2">
                    <img
                      src={takerImageEvidence || ErrImage}
                      alt="img"
                      className="w-20 h-20 bg-gray-100 rounded-md cursor-pointer"
                      onClick={handleImageModalClick}
                    />
                    <Modal
                      isOpen={imageModalIsOpen}
                      className=" max-w-xl z-50 mx-5 sm:mx-auto my-10 md:my-16 rounded-lg shadow-md text-center"
                      overlayClassName="bg-black z-50  fixed inset-0 overflow-y-auto"
                      onRequestClose={() => setImageModalIsOpen(false)}
                    >
                      <button
                        onClick={setImageModalIsOpenToFalse}
                        className="flex justify-start  mb-1 px-2 button shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm  flex-shrink"
                      >
                        x
                      </button>
                      <ImageModal
                        imageSrc={takerImageEvidence || ErrImage}
                        onClick={setImageModalIsOpenToFalse}
                      />
                    </Modal>
                    <p className="w-40 text-xs ">{te}</p>
                  </div>
                </div>
              </div>

              {/* Edit Evidence Button and Modal */}
              <button
                onClick={() => setEditModalIsOpen(true)}
                className="p-2 my-5 lockedCardButton"
              >
                Edit Evidence
              </button>

              <Modal
                isOpen={editModalIsOpen}
                className=" max-w-xl z-50 mx-5 sm:mx-auto my-20 md:my-16 rounded-lg shadow-md text-center"
                overlayClassName="bg-black  z-50 bg-opacity-50 fixed inset-0 overflow-y-auto"
                onRequestClose={() => setEditModalIsOpen(false)}
              >
                <button
                  onClick={setEditModalIsOpenToFalse}
                  className="flex justify-start  mb-1 px-2 button shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm  flex-shrink"
                >
                  x
                </button>
                <div className="rounded-md bg-white p-5 ">
                  <form className="font-fredoka">
                    <div className=" flex flex-col text-center ">
                      <div className="flex flex-col px-4  ">
                        <p className="text-sm md:text-lg py-2">
                          Please provide evidence: {Result}
                        </p>
                        <div className="grid grid-cols-4 gap-2 ">
                          {/* Conditional render - if image 
                 else image input field */}
                          {imageEvidence ? (
                            <>
                              <img
                                src={imageEvidence}
                                alt="img"
                                className="block col-span-1 h-16 w-16 md:h-24 md:w-24 rounded-md cursor-pointer"
                                onClick={handleImageModalClick}
                              />
                              <Modal
                                isOpen={imageModalIsOpen}
                                className=" max-w-xl z-50 mx-5 sm:mx-auto my-20 md:my-28 rounded-lg shadow-md text-center"
                                overlayClassName="bg-black  z-50 fixed inset-0 overflow-y-auto"
                                onRequestClose={() =>
                                  setImageModalIsOpen(false)
                                }
                              >
                                <button
                                  onClick={setImageModalIsOpenToFalse}
                                  className="flex justify-start  mb-1 px-2 button shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm  flex-shrink"
                                >
                                  x
                                </button>
                                <ImageModal
                                  imageSrc={imageEvidence}
                                  onClick={setImageModalIsOpenToFalse}
                                />
                              </Modal>
                            </>
                          ) : (
                            <>
                              <label className="relative col-span-1 block">
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleImageUpload}
                                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                />
                                <div className="border border-red-400 bg-gray-200 rounded-md p-2 flex flex-col items-center justify-center h-full w-full">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                                  </svg>
                                  <span className="md:ml-2 text-xs  ">
                                    Add Image
                                  </span>
                                </div>
                              </label>
                            </>
                          )}
                          <textarea
                            id={"evidence"}
                            className="focus:outline-none col-span-3 pl-2 border border-red-400 w-full rounded-lg bg-gray-300"
                            type="text"
                            value={evidence}
                            required
                            onChange={(e) => setevidence(e.target.value)}
                            placeholder=""
                            rows="4"
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-center">
                        <button
                          className=" focus:outline-none text-center"
                          onClick={handleEditEvidences}
                        >
                          <div className=" w-28 m-2 px-3 button cursor-pointer shadow-sm  bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-xl ">
                            save
                          </div>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal>
            </>
          )}
        </>
      ) : (
        <form className="font-fredoka">
          <div className=" flex flex-col text-center ">
            <div className="flex flex-col px-4  ">
              <p className="text-sm md:text-lg py-2">
                Please provide evidence: {Result}
              </p>
              <div className="grid grid-cols-4 gap-2 ">
                {/* Conditional render - if image 
                 else image input field */}
                {imageEvidence ? (
                  <>
                    <img
                      src={imageEvidence}
                      alt="img"
                      className="block col-span-1 h-16 w-16 md:h-24 md:w-24 rounded-md cursor-pointer"
                      onClick={handleImageModalClick}
                    />
                    <Modal
                      isOpen={imageModalIsOpen}
                      className=" max-w-xl z-50 mx-5 sm:mx-auto my-20 md:my-16 rounded-lg shadow-md text-center"
                      overlayClassName="bg-black  z-50 fixed inset-0 overflow-y-auto"
                      onRequestClose={() => setImageModalIsOpen(false)}
                    >
                      <button
                        onClick={setImageModalIsOpenToFalse}
                        className="flex justify-start  mb-1 px-2 button shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm  flex-shrink"
                      >
                        x
                      </button>
                      <ImageModal
                        imageSrc={imageEvidence}
                        onClick={setImageModalIsOpenToFalse}
                      />
                    </Modal>
                  </>
                ) : (
                  <>
                    <label className="relative col-span-1 block">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      />
                      <div className="border border-red-400 bg-gray-200 rounded-md p-2 flex flex-col items-center justify-center h-full w-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                        </svg>
                        <span className="md:ml-2 text-xs  ">Add Image</span>
                      </div>
                    </label>
                  </>
                )}
                <textarea
                  id={"evidence"}
                  className="focus:outline-none col-span-3 pl-2 border border-red-400 w-full rounded-lg bg-gray-300"
                  type="text"
                  value={evidence}
                  required
                  onChange={(e) => setevidence(e.target.value)}
                  placeholder=""
                  rows="4"
                />
              </div>
            </div>
            <div className="flex items-center justify-center">
              <button
                className=" focus:outline-none text-center"
                onClick={handleEditMessage}
              >
                <div className=" w-28 m-2 px-3 button cursor-pointer shadow-sm  bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-xl ">
                  {Action}
                </div>
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddDetailMessagePopup;
