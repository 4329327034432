import React from "react";
import ReactDOM from "react-dom";
import Feed_elements from "./Feed_elements.js";
import Source_tab from "./Source_tab.js";
import Select from "react-select";
import { Destination_tab, List } from "./Destination_tab.js";
import "../styles/output.css";

import getToken from "./Token";
import useFetch from "./useFetch";

/*
const Longlist = [
{source: "Delhi", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send"},
{source: "Mumbai", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send" },
{source: "Bengaluru", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send" },
{source: "Hong-Kong", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send" },
{source: "Shenzhen", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send" },
{source: "Singapore", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send" },
{source: "Delhi", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send" },
{source: "UAE", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send" },
{source: "Mumbai", destination: "Manchester", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send" },
{source: "Delhi", destination: "Liverpool", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send" },
{source: "Bengaluru", destination: "Liverpool", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send" },
{source: "Shenzhen", destination: "Liverpool", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send" },
{source: "Delhi", destination: "Birmingham", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send" },
{source: "Mumbai", destination: "Birmingham", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Send" },
{source: "Delhi", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry"},
{source: "Mumbai", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
{source: "Bengaluru", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
{source: "Hong-Kong", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
{source: "Shenzhen", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
{source: "Singapore", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
{source: "Delhi", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
{source: "UAE", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
{source: "Mumbai", destination: "Manchester", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
{source: "Delhi", destination: "Liverpool", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
{source: "Bengaluru", destination: "Liverpool", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
{source: "Shenzhen", destination: "Liverpool", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
{source: "Delhi", destination: "Birmingham", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
{source: "Mumbai", destination: "Birmingham", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
{source: "Finland(Test)", destination: "London", weight_kg: "2", Dimensions_cm: "10*10*5", item_types: "Edibles", req_type: "Carry" },
]
*/

const Longlist = global.messagelist;

const Selected_source = ["Delhi", "Mumbai"];
const Selected_destination = ["London", "Liverpool", "Birmingham"];

//test filter?
/*
const Shortlistx =  Longlist.filter(item => Selected_source.includes(item.source) && Selected_destination.includes(item.destination) );
console.log(Shortlistx);
*/
//removed Shortlistx,
export { Longlist, Selected_source, Selected_destination };
