import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Outlet } from "react-router-dom";
import { GlobalProvider } from "../components/GlobalContext";
import Top_bar from "../components/Top_bar";
import LandingPage from "../components/Pages/LandingPage";
import Modal from "react-modal";
import PostMessagePopup from "../components/Popups/PostMessagePopup";
function AuthenticatedRoutes() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => setUser(user))
      .catch(() => setUser(null))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div>...</div>;
  }
  return (
    <GlobalProvider>
      <React.Fragment>
        {user ? (
          <div className=" font-fredoka">
            <Top_bar />
            <div className="bg-[#34dd5a] rounded-full  w-10 h-10 fixed bottom-5 right-5 z-50 ">
              <button
                onClick={() => setModalIsOpen(true)}
                className=" w-10 h-10 flex items-center justify-center "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path
                    d="M11.9998 3C10.3429 3 8.99976 4.34315 8.99976 6V10C8.99976 11.6569 10.3429 13 11.9998 13C13.6566 13 14.9998 11.6569 14.9998 10V6C14.9998 4.34315 13.6566 3 11.9998 3ZM11.9998 1C14.7612 1 16.9998 3.23858 16.9998 6V10C16.9998 12.7614 14.7612 15 11.9998 15C9.23833 15 6.99976 12.7614 6.99976 10V6C6.99976 3.23858 9.23833 1 11.9998 1ZM3.05469 11H5.07065C5.55588 14.3923 8.47329 17 11.9998 17C15.5262 17 18.4436 14.3923 18.9289 11H20.9448C20.4837 15.1716 17.1714 18.4839 12.9998 18.9451V23H10.9998V18.9451C6.82814 18.4839 3.51584 15.1716 3.05469 11Z"
                    fill="rgba(255,255,255,1)"
                  ></path>
                </svg>
              </button>
            </div>

            <Modal
              isOpen={modalIsOpen}
              className=" max-w-xl z-50 mx-5 sm:mx-auto my-5 md:my-8 rounded-lg shadow-md text-center focus:outline-none"
              overlayClassName="bg-black z-50 bg-opacity-60 fixed inset-0 overflow-y-auto"
              onRequestClose={() => setModalIsOpen(false)}
            >
              <button
                onClick={() => setModalIsOpen(false)}
                className="flex justify-start  m-1 px-2 button shadow-sm focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-md  flex-shrink"
              >
                x
              </button>
              <PostMessagePopup
                onClick={() => setModalIsOpen(false)}
                isRcordingAudio={true}
              />
            </Modal>
            <Outlet />
          </div>
        ) : (
          <LandingPage />
        )}
      </React.Fragment>
    </GlobalProvider>
  );
}

export default AuthenticatedRoutes;
