import React, { useState, useEffect } from "react";

import "./styles/output.css";
import { Auth, Hub } from "aws-amplify";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Share from "./components/Pages/Share.js";
import Test from "./Test.js";
import LandingPage from "./components/Pages/LandingPage.js";
import { QueryClient, QueryClientProvider } from "react-query";
import Login from "./components/Pages/auth/Login.js";
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes";
import Register from "./components/Pages/auth/Register";
import ResetPassword from "./components/Pages/auth/ResetPassword";
import ConfirmOtp from "./components/Pages/auth/ConfirmOtp";
import NewPassword from "./components/Pages/auth/NewPassword";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_ID);
const queryClient = new QueryClient();
function App() {
  const location = useLocation();
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const checkUser = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setUser(authUser);
    } catch (error) {
      setUser(null);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    const listener = (data) => {
      if (data.payload.event === "signIn" || data.payload.event === "signOut") {
        checkUser();
      }
    };
    Hub.listen("auth", listener);
    return () => Hub.remove("auth", listener);
  }, []);

  useEffect(() => {
    if (user) {
      ReactGA.set({ userId: user?.username });
      window.dataLayer.push({
        userId: user?.username,
        event: "userIdSet",
      });
    } else {
      ReactGA.set({ userId: null });
    }
  }, [user]);
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
  }, [location]);

  if (user === undefined) {
    return <p>...</p>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/share/:slug" element={<Share />} />

        {user ? (
          <Route path="/" element={<AuthenticatedRoutes />}>
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/*" element={<Test />} />
          </Route>
        ) : (
          <>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/verifyemail" element={<ConfirmOtp />} />
            <Route path="/newpassword" element={<NewPassword />} />
          </>
        )}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </QueryClientProvider>
  );
}

// export default App;
export default App;
