import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import a from "./a.png";
import b from "./b.png";

import c from "./c.png";
import d from "./d.png";
import e from "./e.png";
import f from "./f.png";
import g from "./g.png";

const HowItWorks = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);

  return (
    <div className="pt-32 pb-40 p-2  ">
      <div className="flex flex-col items-start md:max-w-xl mx-auto gap-2 ">
        {/* top section */}
        <div className="flex flex-col items-center gap-2 mb-2">
          <h2 className="text-center">Frequently Answered Questions</h2>
          <p className="text-xs text-justify font-semibold  ">
            To help you get on with this app quickly, here are some FAQs we
            prepared. In case you don’t find the answer to a question you have,
            write to us using the <Link to="/support">support form.</Link>{" "}
          </p>
        </div>

        {/* first */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen1(!isOpen1)} className=" w-6 h-6">
              {isOpen1 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M5 11V13H19V11H5Z" fill="#00ffb3"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#00ffb3"
                  ></path>
                </svg>
              )}
            </button>
            <h3
              className={`  max-w-3/4 ${
                isOpen1 ? "text-gray-900" : "text-gray-500"
              }`}
            >
              What is the 'Home' section?
            </h3>
          </div>

          {isOpen1 && (
            <div className="flex flex-col items-start gap-3">
              <img
                alt=""
                className=" md:max-w-md  mx-auto  border border-blue-400 w-52 "
                src={a}
              ></img>
              <p className="text-xs  font-semibold  ">
                A feed of all the bets opened by our community that are
                available for uptake.{" "}
              </p>
            </div>
          )}
        </div>

        {/* second */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen2(!isOpen2)} className=" w-6 h-6">
              {isOpen2 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="#00ffb3"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#00ffb3"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen2 ? "text-gray-900" : "text-gray-500"}`}>
              How can I take a bet?
            </h3>
          </div>
          {isOpen2 && (
            <div>
              <p className="text-xs  ">
                {" "}
                Just scroll through our feed on the Home page and select the bet
                you want by clicking on ‘Take this bet’. This will lock the bet
                between you and the original author of the bet.{" "}
              </p>
              <p className="text-black-700 text-xs">
                Note that when you take a bet, you are betting against the event
                mentioned on the tile.{" "}
              </p>
              <img
                alt=""
                src={b}
                className=" md:max-w-md  mx-auto  border border-blue-400 w-52 "
              ></img>
              <p className="text-xs  ">
                For example, in the example above, taking a bet on the Arsenal
                match means you are betting against a draw.{" "}
              </p>
              <p className="text-xs  ">
                Once you have taken the bet, the tile will be added to the
                ‘Locked’ section on the ‘Manage’ page. You can go there to view
                the status of the bet, claim win or concede defeat.{" "}
              </p>
            </div>
          )}
        </div>

        {/* third */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen3(!isOpen3)} className=" w-6 h-6">
              {isOpen3 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="#00ffb3"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#00ffb3"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen3 ? "text-gray-900" : "text-gray-500"}`}>
              How can I create a bet for others?
            </h3>
          </div>
          {isOpen3 && (
            <div className="flex flex-col items-start gap-3">
              {" "}
              <p className="text-xs  ">
                {" "}
                Click on ‘Add New’ and provide the details of the bet you want
                to create. Once you ‘post’ it, a new tile will appear on the
                feed which will show your bet. The same can also be found in the
                ‘Manage’ area of your account.{" "}
              </p>
              <img
                alt=""
                className=" md:max-w-md  mx-auto  border border-blue-400 w-52 "
                src={c}
              ></img>
              <p className="text-xs  ">
                {" "}
                Once someone takes the bet, it will disappear from the main feed
                and you will receive an email. Note that you will need coins to
                create a bet. Upon winning, a coins will be added to your
                account which will be the amount of the bet less a small fee for
                our service.{" "}
              </p>
              <img
                alt=""
                className="md:max-w-md  mx-auto  border border-blue-400 w-52  "
                src={d}
              ></img>
              <p className="text-xs  ">
                You can go to the ‘Locked’ section of the ‘Manage’ page to claim
                win, concede or raise a dispute on the tile. This will then be
                shared with the other player and us. In case of disputes, we
                will assess the evidence provided by both players and determine
                the result.{" "}
              </p>
            </div>
          )}
        </div>

        {/* fourth */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen4(!isOpen4)} className=" w-6 h-6">
              {isOpen4 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="#00ffb3"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#00ffb3"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen4 ? "text-gray-900" : "text-gray-500"}`}>
              What is the 'Manage' page?{" "}
            </h3>
          </div>
          {isOpen4 && (
            <div className="flex flex-col items-start gap-3">
              <p className="text-xs  ">
                This page allows you to manage all your bets.{" "}
              </p>
              <img
                alt=""
                src={e}
                className="md:max-w-md  mx-auto  border border-blue-400 w-52  "
              ></img>
              <ul className="text-xs  ">
                <li>
                  ● ‘Open’ bets are the ones which you have created and are
                  currently visible to others on feed.{" "}
                </li>
                <li>
                  ● ‘Locked’ bets are the ones where you have been locked in a
                  bet with another player
                </li>
                <li>
                  ● ‘Archived’ are the historical bets which you created or
                  played.{" "}
                </li>
              </ul>
            </div>
          )}
        </div>

        {/* fifth */}

        <div>
          <div className="flex items-center gap-4">
            <button onClick={() => setIsOpen5(!isOpen5)} className=" w-6 h-6">
              {isOpen5 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="#00ffb3"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#00ffb3"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen5 ? "text-gray-900" : "text-gray-500"}`}>
              Why do I have ‘coins’?
            </h3>
          </div>
          {isOpen5 && (
            <div className="flex flex-col items-start gap-3">
              <p className="text-xs  ">
                All the bets are created using the coins instead of cash. These
                coins increase or decrease based on the results of the bets you
                played. You can recharge your coins using a payment method of
                your choice.{" "}
              </p>
            </div>
          )}
        </div>

        {/* sixth */}
        <div>
          <div className="flex items-center gap-4  ">
            <button onClick={() => setIsOpen6(!isOpen6)} className=" w-6 h-6">
              {isOpen6 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M5 11V13H19V11H5Z" fill="#00ffb3"></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#00ffb3"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`  ${isOpen6 ? "text-gray-900" : "text-gray-500"}`}>
              Where can I find a 'referral code' that I can share with my
              friends?{" "}
            </h3>
          </div>
          {isOpen6 && (
            <div className="flex flex-col items-start gap-3">
              <p className="text-xs  ">
                Our app is invitation only. This means that any new sign-ups
                only happen when an existing user will refer you. You can share
                your referral code with a friend who wants to sign up (they will
                need it).{" "}
              </p>
              <img
                alt=""
                src={f}
                className=" md:max-w-md  mx-auto  border border-blue-400 w-52 "
              ></img>
              <p className="text-xs  ">
                Your referral code can be found in the ‘My account’ section and
                it cannot be changed.{" "}
              </p>
            </div>
          )}
        </div>

        {/* seventh */}
        <div>
          <div className="flex items-start gap-4">
            <button onClick={() => setIsOpen7(!isOpen7)} className=" w-6 h-6">
              {isOpen7 ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M5 11V13H19V11H5Z" fill="#00ffb3"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                    fill="#00ffb3"
                  ></path>
                </svg>
              )}
            </button>
            <h3 className={`${isOpen7 ? "text-gray-900" : "text-gray-500"}`}>
              Can I share a tile with others?{" "}
            </h3>
          </div>
          {isOpen7 && (
            <div className="flex flex-col items-start gap-3">
              <p className="text-xs  ">
                Absolutely. Just click on ‘share’ and a link will be generated
                for the tile. You can easily share it with friends on whatsapp
                or email.{" "}
              </p>
              <p className="text-xs  ">
                Our app is invitation only. This means that any new sign-ups
                only happen when an existing user will refer you. You can share
                your referral code with a friend who wants to sign up (they will
                need it).{" "}
              </p>
              <img
                alt=""
                src={g}
                className=" md:max-w-md  mx-auto  border border-blue-400 w-52 "
              ></img>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
