import React, { useState, useContext } from "react";
// import getBalance from "../Account_Infomation/Balance";

import { Auth } from "aws-amplify";
import getToken from "../Token";
import getUsername from "../Account_Infomation/Username";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Endpoints from "../../api/endpoints";

import { GlobalContext } from "../GlobalContext";
import { useMutation, useQueryClient } from "react-query";
import LoadingModal from "./LoadingModal";

const DialoguePopup = ({ betData, Event, textmessage, closeOnClick }) => {
  const queryClient = useQueryClient();
  var link = `${process.env.REACT_APP_LINK}`;
  //var link = "http://localhost:8000/api/";
  const { notsocket } = useContext(GlobalContext);

  const accesstoken = getToken();
  const curUser = getUsername();
  //const [isPending, setIsPending] = useState(false);

  const [user, setUser] = useState(betData.user);
  const [PuntvilleBetRef, setPuntvilleBetRef] = useState(
    betData.PuntvilleBetRef
  );
  const [author, setAuthor] = useState(betData.author);
  const [bet, setBet] = useState(betData.bet);
  const [body, setBody] = useState(betData.body);
  const [topic, setTopic] = useState(betData.topic);
  const [winner, setWinner] = useState(betData.winner);
  const [created_at, setCreated] = useState(betData.created_at);
  const [active_period, setActive] = useState(betData.created_at);

  const { setOpenPosts } = useContext(GlobalContext);
  const { setLockedPosts } = useContext(GlobalContext);
  const { setArchivedPosts } = useContext(GlobalContext);

  const updateData = async () => {
    // openposts
    const openData = await Endpoints.GetOpenBets();
    setOpenPosts(openData);
    // lockedPosts
    const lockedData = await Endpoints.GetLockedBets();
    setLockedPosts(lockedData);
    // archivedPosts
    const archivedData = await Endpoints.GetArchivedBets();
    setArchivedPosts(archivedData);
  };

  const acceptHandler = async (content) => {
    try {
      const response = await fetch(link + "Lose-Puntville-bet/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify(content),
      });
      const json = await response.json();

      return json;
    } catch (error) {
      console.log("");
    }
  };

  //accept mutation
  const acceptMutation = useMutation(acceptHandler, {
    onSuccess: () => {
      console.log("Dispute Successful");
    },
    onError: (err) => {
      console.log("there was an error", err);
    },
    onSettled: () => {
      removeTile();
      queryClient.invalidateQueries("lockedBets");
      queryClient.invalidateQueries("archivedBets");
      queryClient.invalidateQueries("puntvilleUserCredits");
      closeOnClick();
    },
  });

  const concedeHandler = async (content) => {
    try {
      const response = await fetch(link + "Lose-Puntville-bet/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accesstoken,
        },
        body: JSON.stringify(content),
      });
      const json = await response.json();
      return json;
    } catch (error) {
      console.log("error,", error);
    }
  };

  //concede mutation
  const concedeMutation = useMutation(concedeHandler, {
    onSuccess: () => {
      console.log("Dispute Successful");
    },
    onError: (err) => {
      console.log("there was an error", err);
    },
    onSettled: () => {
      removeTile();
      queryClient.invalidateQueries("lockedBets");
      queryClient.invalidateQueries("archivedBets");
      queryClient.invalidateQueries("puntvilleUserCredits");
      closeOnClick();
    },
  });

  const removeTile = (e) => {
    if (curUser === betData.user) {
      var newUser = betData.author;
    } else {
      var newUser = betData.user;
    }
    fetch(link + "Puntville-archive/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accesstoken,
      },
      body: JSON.stringify({
        user: curUser,
        topic: topic,
        body: body,
        author: author,
        bet: bet,
        PuntvilleBetRef: PuntvilleBetRef,
        winner: newUser,
      }),
    });
  };

  const handleAccept = (e) => {
    e.preventDefault(); //prevents refreshing page
    if (curUser === betData.user) {
      var newUser = betData.author;
    } else {
      newUser = betData.user;
    }
    // console.log("Accept");
    if (Event === "Concede") {
      // console.log("Concede Accepted");

      const content = {
        user: newUser,
        topic: topic,
        body: body,
        author: author,
        bet: bet,
        PuntvilleBetRef: PuntvilleBetRef,
      };
      concedeMutation.mutate(content);
    } else {
      const content = {
        user: newUser,
        topic: topic,
        body: body,
        author: author,
        bet: bet,
        PuntvilleBetRef: PuntvilleBetRef,
      };
      acceptMutation.mutate(content);
    }
  };

  const handleDecline = (e) => {
    e.preventDefault(); //prevents refreshing page
    // console.log("Decline");

    closeOnClick();
  };

  if (concedeMutation.isLoading) return <LoadingModal />;
  if (acceptMutation.isLoading) return <LoadingModal />;

  return (
    <div>
      <div className=" px-8  mx-2 my-3 font-fredoka  justify-items-center  ">
        {textmessage}
      </div>

      <form id="messagesubject" className="">
        <div className="grid grid-cols-2  text-center ">
          <div className="col-span-2 flex-1 pl-2 text-center"></div>
        </div>
      </form>
      <div className="flex-row ">
        <button
          onClick={handleAccept}
          className=" mx-2 px-4 button shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm   flex-shrink"
        >
          Yes
        </button>

        <button
          onClick={handleDecline}
          className="mx-2  px-4 button shadow-md focus:outline-none cursor-pointer bg-gradient-to-t from-blue-200 to-blue-100 text-red-600 rounded-md hover:bg-red-400 hover:text-gray-100 text-sm "
        >
          No
        </button>
      </div>
    </div>
  );
};

export default DialoguePopup;
